import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './signup.css';
import './font.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import { APIURL } from '../../api/apiDefault';
import { useMediaQuery } from "react-responsive";

const SignUp = () => {
  const navigate = useNavigate();
  const signupHeight = useMediaQuery({
    query: "(max-height:850px)",
  });
  const [terms, setTerms] = useState({
    terms1: false,
    terms2: false,
  });
  const handleTerms = (e) => {
    setTerms({
      ...terms,
      [e.target.id]: e.target.checked,
    });
  };

  const [info, setInfo] = useState({
    email: '',
    password: '',
    re_password: '',
    nickname: '',
    tel: '',
    brand: '',
    team: '',
    favor: '',
  });
  const [alert, setAlert] = useState({
    email: false,
    password: false,
    re_password: false,
    nickname: false,
    tel: false,
    reTel: false,
    // favor: false,
  });
  const handleInfo = (e) => {
    console.log(e.target.name);
    setInfo({
      ...info,
      [e.target.name]: e.target.name === 'tel' ? formatPhoneNumber(e.target.value) : e.target.value,
    });
  };
  // 연락처 정규식2
  const formatPhoneNumber2 = () => {
    var regPhone = /^\d{2,3}-?\d{3,4}-?\d{4}$/;
    return regPhone.test(info.tel);
  }
  const handleSignup = (e) => {
    console.log(info);
    const { email, password, re_password, nickname, tel, favor } = info;
    let newAlert = {
      email: false,
      password: false,
      re_password: false,
      nickname: false,
      tel: false,
      reTel: false,
      favor: false,
    };
    if (!setEmailCheckForm) {
      setEmailCheckText('이메일 인증을 진행해 주세요.');
      newAlert.email = true;
    } else {
      if (emailCheckOrigin !== emailCheckValue) {
        setEmailCheckText('이메일 인증번호를 확인해 주세요.');
        newAlert.email = true;
      } else {
        newAlert.email = false;
      }
    }
    // if (!validateEmail(email)) {
    //   setEmailCheckText('올바른 이메일 형식이 아닙니다.');
    //   newAlert.email = true;
    // } else {
    //   newAlert.email = false;
    // }
    if (password.length > 20 || password.length < 8) {
      newAlert.password = true;
    } else {
      newAlert.password = false;
    }
    password !== re_password ? (newAlert.re_password = true) : (newAlert.re_password = false);
    !nickname ? (newAlert.nickname = true) : (newAlert.nickname = false);
    !tel ? (newAlert.tel = true) : (newAlert.tel = false);
    // !favor ? (newAlert.favor = true) : (newAlert.favor = false);
    !formatPhoneNumber2() ? (newAlert.reTel = true) : (newAlert.reTel = false);
    setAlert(newAlert);
    const isAllTrue = Object.values(newAlert).every((value) => value === false);

    if (isAllTrue) {
      if (!terms.terms1) {
        Swal.fire({
          icon: 'error',
          iconColor: '#971b4d',
          text: '이용약관 및 개인정보 처리방침에 동의해 주세요.',
          confirmButtonText: '확인',
          confirmButtonColor: '#971b4d',
        });
        return;
      }

      axios
        .get(`${APIURL}user/duplicate/email/${email}`)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (res.data.msg === 'duplicate') {
              Swal.fire({
                icon: 'error',
                iconColor: '#971b4d',
                text: '이미 가입된 이메일이 있습니다.',
                confirmButtonText: '확인',
                confirmButtonColor: '#971b4d',
              });
            } else {
              const _data = {
                email: email,
                name: nickname,
                password: password,
                role: 'USER',
                mobile: tel,
                company: info.brand,
                division: info.team,
                preference: favor,
                status: 1,
                agree_email: terms.terms2 ? 1 : 0,
              };
              axios
                .post(`${APIURL}user`, _data)
                .then((res) => {
                  if (res.status === 200) {
                    Swal.fire({
                      icon: 'success',
                      iconColor: '#971b4d',
                      text: '가입되었습니다.',
                      confirmButtonText: '확인',
                      confirmButtonColor: '#971b4d',
                    }).then(() => navigate('/login'));
                  }
                })
                .catch((err) => console.log(err));
            }
          }
        })
        .catch((err) => console.log(err));
      return;
    }
  };

  /** 이메일 정규식 */
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  /** 연락처 정규식 */
  const formatPhoneNumber = (value) => {
    // 숫자만 추출
    const numericValue = value.replace(/\D/g, '');

    return numericValue;
  };

  const [showPassword, setShowPassword] = useState({
    pass1: false,
    pass2: false,
  });
  const handlePasswordToggle = (_v) => {
    setShowPassword({
      ...showPassword,
      [_v]: !showPassword[_v],
    });
  };
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');
  const modalReset = () => {
    setModal(false);
  };
  const modalOpen = (_title) => {
    setModal(true);
    setModalTitle(_title);

    let _api;
    switch (_title) {
      case '이용약관':
        _api = `${APIURL}terms`
        break;
      default:
        _api = `${APIURL}privacy`
        break;
    }
    axios.get(_api)
      .then((res) => {
        console.log(res)
        if (res.status === 200) {
          switch (_title) {
            case '이용약관':
              setModalContent(res.data[0].terms)
              break;
            default:
              setModalContent(res.data[0].privacy)
              break;
          }
        }
      })
      .catch((err) => console.log(err))
  };


  const [emailCheckOrigin, setEmailCheckOrigin] = useState('');
  const [emailCheckText, setEmailCheckText] = useState('');
  const [emailCheckForm, setEmailCheckForm] = useState(false);
  const [emailCheckValue, setEmailCheckValue] = useState('')
  const emailCheck = () => {
    console.log('test')
    if (!info.email) {
      setAlert({ ...alert, email: true });
      setEmailCheckText('이메일을 입력해 주세요.');
      return;
    }

    if (!validateEmail(info.email)) {
      setAlert({ ...alert, email: true });
      console.log('test')
      setEmailCheckText('올바른 이메일 형식이 아닙니다.');
      return;
    }

    axios
      .get(`${APIURL}user/duplicate/email/${info.email}`)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.msg === 'duplicate') {
            setAlert({ ...alert, email: true });
            console.log('test')
            setEmailCheckText('이미 가입된 이메일이 있습니다.');

            return;
          } else {
            setEmailCheckForm(true)
            setAlert({ ...alert, email: false });
            const _data = {
              user_email: info.email,
              send_type: 'sign'
            }
            axios.post(`${APIURL}mail/`, _data)
              .then((res) => {
                if (res.status === 200) {
                  setEmailCheckOrigin(res.data.result)
                }
              })
              .catch((err) => console.log(err))
          }
        }
      })
      .catch((err) => console.log(err));



  }

  useEffect(() => {
    setScreenSize();
  }, [])
  function setScreenSize() {
    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  window.addEventListener('resize', () => setScreenSize());

  return (
    <div className="signup-container">
      <div className="signup-bg">
        <img src={`${process.env.PUBLIC_URL}image/icon/bg.png`} />
      </div>
      <div className="signup-box-bg">
        <div className="signup-box mx-auto">
          {!signupHeight && (
            <div className="text-center pointer" onClick={() => navigate('/')}>
              <img src={`${process.env.PUBLIC_URL}image/icon/login_logo.svg`} />
            </div>
          )}
          {signupHeight && (
            <div className="d-flex align-items-end justify-content-center">
              <img src={`${process.env.PUBLIC_URL}image/icon/logo_main.svg`} style={{ marginBottom: '4px' }} />
              <div className="login-fs-15px fw-400 ms-2">회원가입</div>
            </div>
          )}

          <div className={signupHeight ? 'mt-2' : 'mt-4'}>
            {!signupHeight && <div className="login-fs-15px fw-400 mb-3 text-center">리알토 회원가입</div>}
            <div className="floating-container login-fs-11px">
              <div className="d-flex align-items-center justify-content-between" style={{ gap: '0 5px' }}>
                <FloatingLabel className='w-100' controlId="floatingEmail" label="이메일">
                  <Form.Control
                    type="text"
                    className="login-fs-11px"
                    name="email"
                    value={info.email}
                    placeholder="이메일"
                    onChange={handleInfo}
                  />
                </FloatingLabel>
                <div className="email-check-btn text-white pointer text-nowrap" onClick={emailCheck}>인증</div>

              </div>
              {emailCheckForm && (
                <div className="mt-1">
                  <FloatingLabel className='w-100' controlId="floatingEmail" label="이메일 인증번호">
                    <Form.Control
                      type="text"
                      className="login-fs-11px"
                      name="email_check"
                      value={emailCheckValue}
                      placeholder="이메일 인증번호"
                      onChange={(e) => setEmailCheckValue(e.target.value)}
                    />
                  </FloatingLabel>
                </div>
              )}

              {alert.email && <div className="red login-fs-10px ">* {emailCheckText}</div>}
              <FloatingLabel controlId="floatingPassword " label="비밀번호" className={`${!alert.email && 'mt-3'}`}>
                <Form.Control
                  type={showPassword.pass1 ? 'text' : 'password'}
                  name="password"
                  className="login-fs-11px"
                  value={info.password}
                  onChange={handleInfo}
                  placeholder="비밀번호"
                />
                <img
                  src={`${process.env.PUBLIC_URL}image/icon/${showPassword.pass1 ? 'eye_slash' : 'eye'}.svg`}
                  onClick={() => handlePasswordToggle('pass1')}
                  className="floatingEye pointer"
                />
              </FloatingLabel>
              {alert.password && (
                <div className="red login-fs-10px ">* 비밀번호는 8자 이상 20자이하로 설정해주세요.</div>
              )}
              <FloatingLabel
                controlId="floatingRePassword"
                label="비밀번호 확인"
                className={`${!alert.password && 'mt-3'}`}>
                <Form.Control
                  type={showPassword.pass2 ? 'text' : 'password'}
                  name="re_password"
                  value={info.re_password}
                  onChange={handleInfo}
                  placeholder="비밀번호 확인"
                  className="login-fs-11px"
                />
                <img
                  src={`${process.env.PUBLIC_URL}image/icon/${showPassword.pass2 ? 'eye_slash' : 'eye'}.svg`}
                  onClick={() => handlePasswordToggle('pass2')}
                  className="floatingEye pointer"
                />
              </FloatingLabel>
              {alert.re_password && <div className="red login-fs-10px ">* 같은 비밀번호를 입력해주세요.</div>}
              <FloatingLabel controlId="floatingName" label="이름" className={`${!alert.re_password && 'mt-3'}`}>
                <Form.Control
                  type="text"
                  name="nickname"
                  onChange={handleInfo}
                  value={info.nickname}
                  placeholder="이름"
                  className="login-fs-11px"
                />
              </FloatingLabel>
              {alert.nickname && <div className="red login-fs-10px ">* 이름을 입력해주세요.</div>}
              <FloatingLabel controlId="floatingTel" label="연락처" className={`${!alert.nickname && 'mt-3'}`}>
                <Form.Control
                  type="text"
                  maxLength={11}
                  onChange={handleInfo}
                  name="tel"
                  value={info.tel}
                  placeholder="연락처"
                  className="login-fs-11px"
                />
              </FloatingLabel>
              {alert.tel && <div className="red login-fs-10px">* 연락처를 입력해주세요.</div>}
              {alert.reTel && <div className="red login-fs-10px">* 올바른 연락처를 입력해주세요.</div>}
              <FloatingLabel
                controlId="floatingBrand"
                label="소속 회사 / 담당 브랜드 (선택사항)"
                className={`${(!alert.tel && !alert.reTel) && 'mt-3'}`}>
                <Form.Control
                  type="text"
                  name="brand"
                  value={info.brand}
                  onChange={handleInfo}
                  placeholder="소속 회사 / 담당 브랜드 (선택사항)"
                  className="login-fs-11px"
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingTeam" label="소속 팀 / 직급 (선택사항)" className="mt-3">
                <Form.Control
                  type="text"
                  onChange={handleInfo}
                  name="team"
                  value={info.team}
                  placeholder="소속 팀 / 직급 (선택사항)"
                  className="login-fs-11px"
                />
              </FloatingLabel>
              <FloatingLabel className="mt-3 login-fs-11px" controlId="floatingSelect" label="선호하는 연락방식">
                <Form.Select
                  aria-label="선택해 주세요"
                  className="login-fs-11px"
                  onChange={(e) => setInfo({ ...info, favor: e.target.value })}>
                  <option>선택해 주세요</option>
                  <option value="리알토 문의란">리알토 문의란</option>
                  <option value="카톡">카톡</option>
                  <option value="이메일">이메일</option>
                  <option value="전화통화">전화통화</option>
                  <option value="화상미팅">화상미팅</option>
                  <option value="대면미팅">대면미팅</option>
                </Form.Select>
              </FloatingLabel>
              {/* {alert.favor && <div className="red login-fs-10px ">* 선호하는 연락방식을 선택해주세요.</div>} */}
            </div>
            <div className="mt-3">
              <div className="d-flex align-items-center mb-1">
                <input type="checkbox" id="terms1" className="sign-checkbox d-none" onChange={handleTerms} />
                <label htmlFor="terms1" className="sign-label">
                  {terms.terms1 && <img src={`${process.env.PUBLIC_URL}image/icon/terms_check.svg`} />}
                </label>
                <label
                  className="login-fs-11px fw-400 ms-2 terms-gray pointer"
                >
                  <span className="main" onClick={() => modalOpen('이용약관')}>이용약관</span> 및 <span className="main" onClick={() => modalOpen('개인정보 처리방침')}>개인정보 처리방침</span>에
                  동의합니다.(필수)
                </label>
              </div>
              <div className="d-flex align-items-center">
                <input type="checkbox" id="terms2" className="sign-checkbox d-none" onChange={handleTerms} />
                <label htmlFor="terms2" className="sign-label">
                  {terms.terms2 && <img src={`${process.env.PUBLIC_URL}image/icon/terms_check.svg`} />}
                </label>
                <label
                  className="login-fs-11px fw-400 ms-2 terms-gray pointer"
                  onClick={() => modalOpen('리알토 소식 이메일 수신')}>
                  리알토 소식(기능 업데이트/신규매체 등) 이메일 수신(선택)
                </label>
              </div>
              <div className="signup-btn pointer mt-3 text-center text-white login-fs-15px" onClick={handleSignup}>
                회원가입
              </div>
              <div className="mt-4 text-center fw-400 login-fs-15px pointer" onClick={() => navigate('/login')}>
                이미 회원이신가요? <span className="main">로그인</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={modal} onHide={modalReset} centered>
        <Modal.Header closeButton className="border-bottom-0 pb-0">
          <div className="fs-18px fw-800 main">{modalTitle}</div>
        </Modal.Header>
        <Modal.Body>
          <div className="scroll" style={{ maxHeight: '60vh', overflowY: 'auto' }} dangerouslySetInnerHTML={{ __html: modalContent }}></div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default SignUp;
