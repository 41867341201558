import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import './signup.css';
import './font.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';

import { loginUser } from '../../api/Users';
import { setRefreshToken, removeSessionToken } from '../../storage/Cookie';
import { SET_TOKEN } from '../../store/Auth';
import Modal from 'react-bootstrap/Modal';
import { IoMdReturnLeft } from 'react-icons/io';
import { APIURL } from '../../api/apiDefault';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const [info, setInfo] = useState({
    email: '',
    password: '',
  });

  const [auto, setAuto] = useState(false);

  const handleInfo = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };
  const handleEnterPress = (e) => {
    e.key === 'Enter' && handleLogin();
  };

  const handleLogin = () => {
    if (!info.email || !info.password) {
      Swal.fire({
        icon: 'error',
        iconColor: '#971b4d',
        text: '필수 정보를 모두 입력해 주세요.',
        confirmButtonText: '확인',
        confirmButtonColor: '#971b4d',
      });
      return;
    }

    const _data = {
      email: info.email,
      password: info.password,
    };
    axios
      .post(`${APIURL}user/login`, _data)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.msg === 'Fail') {
            Swal.fire({
              icon: 'error',
              iconColor: '#971b4d',
              text: '이메일 또는 비밀번호를 다시 입력해 주세요.',
              confirmButtonText: '확인',
              confirmButtonColor: '#971b4d',
            });
          } else {
            onValid(_data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const onValid = async ({ email, password }) => {
    // 백으로부터 받은 응답
    const response = await loginUser({ email, password });

    if (response.status) {
      // 쿠키에 Refresh Token, store에 Access Token 저장
      console.log(response.json);
      const autoLogin = auto;
      // if (autoLogin) {
      //   window.localStorage.setItem('auto', true)
      // } else {
      //   window.localStorage.setItem('auto', false)
      // }

      // setRefreshToken(response.json.access_token, autoLogin);
      setRefreshToken(response.json.access_token, autoLogin);
      dispatch(SET_TOKEN(response.json.access_token));

      return navigate('/');
    } else {
      console.log(response.json);
    }
  };

  const pwChange = () => {
    setEditModal(true);
    // Swal.fire({
    //   icon: 'success',
    //   iconColor: '#971b4d',
    //   text: '임시 비밀번호가 발급되었습니다.',
    //   confirmButtonText: '확인',
    //   confirmButtonColor: '#971b4d',
    // }).then((res) => {
    // });
  };

  const [pwEmail, setPwEmail] = useState('')
  const [editModal, setEditModal] = useState(false);
  const editReset = () => {
    setEditModal(false);
    setPwEmail('');
  };
  const editSubmit = () => {
    if (!pwEmail) {
      Swal.fire({
        icon: 'error',
        iconColor: '#971b4d',
        text: '이메일을 입력해 주세요.',
        confirmButtonText: '확인',
        confirmButtonColor: '#971b4d',
      })
      return;
    }


    const _data = {
      user_email: pwEmail,
      send_type: 'password',
    }
    axios.post(`${APIURL}mail/`, _data)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.msg === "duplicate") {
            Swal.fire({
              icon: 'error',
              iconColor: '#971b4d',
              text: '존재하지 않는 이메일입니다.',
              confirmButtonText: '확인',
              confirmButtonColor: '#971b4d',
            })
          } else {
            Swal.fire({
              icon: 'success',
              iconColor: '#971b4d',
              text: '임시 비밀번호가 발급되어 메일로 보내졌습니다.',
              confirmButtonText: '확인',
              confirmButtonColor: '#971b4d',
            })
          }
        }
      })
      .catch((err) => console.log(err))

  };

  useEffect(() => {
    setScreenSize();
  }, [])
  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    console.log(vh)

    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // document.querySelector('.signup-container').style.height = window.innerHeight + 'px'
    // document.querySelector('.login-content-container').style.height = window.innerHeight + 'px'
  }
  window.addEventListener('resize', () => setScreenSize());

  return (
    <div className="signup-container">
      <div className="signup-bg">
        <img src={`${process.env.PUBLIC_URL}image/icon/bg.png`} />
      </div>
      <div className="login-box">
        <div className="login-content-container scroll">
          <div className="text-center pointer" onClick={() => navigate('/')}>
            <img src={`${process.env.PUBLIC_URL}image/icon/login_logo.svg`} />
          </div>
          <div className="mt-4 w-100">
            <div className="login-fs-15px mb-3 text-center fw-700">로그인</div>
            <div className="floating-container login-fs-11px">
              <FloatingLabel controlId="floatingEmail" label="이메일">
                <Form.Control
                  type="text"
                  className="login-fs-11px"
                  name="email"
                  value={info.email}
                  onChange={handleInfo}
                  placeholder="이메일"
                  onKeyDown={handleEnterPress}
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingPassword" label="비밀번호" className={`${!alert.email && 'mt-3'}`}>
                <Form.Control
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  className="login-fs-11px"
                  value={info.password}
                  onChange={handleInfo}
                  placeholder="비밀번호"
                  onKeyDown={handleEnterPress}
                />
                <img
                  src={`${process.env.PUBLIC_URL}image/icon/${showPassword ? 'eye_slash' : 'eye'}.svg`}
                  onClick={handlePasswordToggle}
                  className="floatingEye pointer"
                />
              </FloatingLabel>
            </div>
            <div className="mt-3">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center mb-1">
                  <input
                    type="checkbox"
                    id="auto"
                    onChange={(e) => setAuto(e.target.checked)}
                    className="sign-checkbox d-none"
                  />
                  <label htmlFor="auto" className="sign-label">
                    {auto && <img src={`${process.env.PUBLIC_URL}image/icon/terms_check.svg`} />}
                  </label>
                  <label htmlFor="auto" className="login-fs-11px ms-2 fw-700">
                    로그인 유지
                  </label>
                </div>
                <div className="login-fs-11px main fw-700 pointer" onClick={pwChange}>
                  비밀번호 찾기
                </div>
              </div>
              <div
                className="signup-btn pointer text-center text-white login-fs-15px fw-700"
                style={{ marginTop: '30px' }}
                onClick={handleLogin}>
                로그인
              </div>
              <div
                className="mt-4 text-center fw-700 login-fs-15px pointer text-nowrap"
                onClick={() => navigate('/signUp')}>
                아직 회원이 아니신가요? <span className="main">회원가입</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 회원정보수정 */}
      <Modal show={editModal} onHide={editReset} centered className="password-modal">
        <Modal.Header closeButton className="border-bottom-0 pb-0">
          <div className="fs-18px fw-800 main">비밀번호 찾기</div>
        </Modal.Header>
        <Modal.Body>
          <div className="floating-container login-fs-11px">
            <FloatingLabel controlId="floatingEmail" label="이메일">
              <Form.Control
                type="text"
                className="login-fs-11px"
                name="email"
                value={pwEmail}
                onChange={(e) => setPwEmail(e.target.value)}
                placeholder="이메일"
              />
            </FloatingLabel>
          </div>

          <div className="mt-4 d-flex justify-content-center">
            <div className="submit--btn text-white fs-12px px-3 py-2 pointer me-2" onClick={editSubmit}>
              임시 비밀번호 발급
            </div>
            <div className="cancel--btn text-black fs-12px px-3 py-2 pointer" onClick={editReset}>
              취소
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Login;
