import React, { useEffect, useMemo, useRef, useState } from 'react';
import billboardStyles from '../../css/billboard.module.css';
import trafficStyles from '../../css/traffic.module.css';
import styles from '../../css/mediaCart.module.css';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import Select from 'react-select';
import { IoMdClose } from 'react-icons/io';
import { FaRegFileExcel } from 'react-icons/fa';
import { RiEdit2Fill } from 'react-icons/ri';
import Swal from 'sweetalert2';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';
import { getCookieToken } from '../../storage/Cookie';
import BasicModal from '../../component/BasicModal';
import moment, { locale } from 'moment';
import { getDay, getDate, isSameMonth, getMonth } from 'date-fns';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useMediaQuery } from 'react-responsive';
import Modal from 'react-bootstrap/Modal';
import { MediaRangeComponent, RangeText } from './MediaRangeComponent';
import { APIURL, DEFAULTURL } from '../../api/apiDefault';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { Loading } from '../../component/loading';

function MediaCart(props) {
  const { userInfo, number, setNumber } = props;
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const planId = searchParams.get('plan');
  const [mediaCartData, setMediaCartData] = useState({});
  const config = {
    headers: {
      Authorization: getCookieToken(),
    },
  };

  const isMobile = useMediaQuery({ query: '(max-width: 1500px)' });
  const tableFooterQuery = useMediaQuery({ query: '(max-width: 768px)' });

  const plan_type_list = ['self_list', 'req_list', 'rialto_list'];

  const getData = () => {
    axios
      .get(`${APIURL}plan`, config)
      .then((res) => {
        let _data = res.data;
        console.log(_data);

        const compareCategoryIds = (a, b) => {
          return a.category_id - b.category_id;
        };

        for (const list of plan_type_list) {
          for (let i = 0; i < _data[list].length; i++) {
            _data[list][i] = {
              ..._data[list][i],
              show: true,
              request: {
                show: false,
                requestFiles: [],
                requestFileString: [],
                requestTitle: '',
                requestContent: '',
              },
            };
            const _item_data = _data[list][i].plan_data;
            for (let j = 0; j < _item_data.length; j++) {
              const sort_data = _item_data[j].data;
              for (let k = 0; k < sort_data.length; k++) {
                const final_data = sort_data[k].data;
                if (final_data.length > 0) {
                  for (let o = 0; o < final_data.length; o++) {
                    final_data[o] = {
                      ...final_data[o],
                      check: false,
                    };
                  }
                }
              }

              _item_data.sort(compareCategoryIds);
            }
          }
        }
        setMediaCartData(_data);

        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const [rangeData, setRangeDate] = useState({});

  useEffect(() => {
    if (mediaCartData) {
      let _list = {};

      for (const list of plan_type_list) {
        if (mediaCartData[list]) {
          for (let i = 0; i < mediaCartData[list].length; i++) {
            const _category1 = mediaCartData[list][i]['plan_data'];
            // console.log('category1: ', _category1);

            _list[mediaCartData[list][i]['id']] = [null, null];

            for (let j = 0; j < _category1.length; j++) {
              const _category3 = _category1[j]['data'];
              // console.log('category3: ', _category3);

              for (let k = 0; k < _category3.length; k++) {
                const _mediaData = _category3[k]['data'];
                // console.log('mediaData: ', _mediaData);

                for (let l = 0; l < _mediaData.length; l++) {
                  const startDate = _mediaData[l]['selected_option']['start_date'];
                  const selectedPeriod = _mediaData[l]['selected_option']['selected_period'];
                  // console.log('startDate: ', startDate);
                  // console.log('selectedPeriod: ', selectedPeriod);

                  const _startDate = new Date(startDate);
                  if (
                    _list[mediaCartData[list][i]['id']][0] === null ||
                    new Date(_list[mediaCartData[list][i]['id']][0]) > _startDate
                  ) {
                    _list[mediaCartData[list][i]['id']][0] = startDate;
                  }

                  const periodTimes = selectedPeriod.replace(/[^\d]/g, '');
                  const periodUnit = selectedPeriod.replace(/[\d]/g, '');
                  // console.log('periodTiems: ', periodTimes);
                  // console.log('periodUnit: ', periodUnit);

                  if (periodUnit !== '회') {
                    let _rangeDate = _startDate;

                    if (periodUnit === '개월') {
                      for (let i = 0; i < periodTimes; i++) {
                        const _days = moment(_rangeDate).daysInMonth();
                        _rangeDate = moment(_rangeDate).add(_days, 'd');
                      }
                      _rangeDate = moment(_rangeDate).add(-1, 'd');
                    } else if (periodUnit === '주') {
                      _rangeDate = moment(_rangeDate)
                        .add(periodTimes * 7, 'd')
                        .add(-1, 'd');
                    } else if (periodUnit === '일') {
                      _rangeDate = moment(_rangeDate).add(periodTimes, 'd').add(-1, 'd');
                    }

                    if (
                      _list[mediaCartData[list][i]['id']][1] === null ||
                      new Date(_list[mediaCartData[list][i]['id']][1]) < _rangeDate
                    ) {
                      _list[mediaCartData[list][i]['id']][1] = moment(_rangeDate).format('YYYY-MM-DD');
                    }
                  }
                }
              }
            }
          }
        }
      }

      setRangeDate({ ..._list });
    }
  }, [mediaCartData]);

  const showPlan = async (e, seq, type) => {
    e.stopPropagation();
    e.preventDefault();
    setMediaCartData((preData) => {
      const updatedData = preData[type].map((_item) => {
        if (_item.id === seq) {
          return {
            ..._item,
            show: true,
            is_open: 1,
          };
        }

        return _item;
      });
      return { ...preData, [type]: updatedData };
    });

    //플랜 open하고 해당 플랜으로 스트롤
    const updateData = await axios
      .put(`${APIURL}plan/open/${seq}?is_open=1`, config)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    const targetElement = document.getElementById(`plan_${seq}`);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [newPlanNumber, setNewPlanNumber] = useState(null);
  useEffect(() => {
    if (newPlanNumber) {
      const targetElement = document.getElementById(`plan_${newPlanNumber}`);
      console.log(targetElement);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [newPlanNumber, mediaCartData]);
  const newPlan = () => {
    const _list = mediaCartData.self_list;
    let name_arr = [];

    for (const item of _list) {
      if (item.name.includes('새플랜')) {
        const updatedName = item.name.replace('새플랜', '').replace(/\s+/g, '');
        name_arr.push(updatedName);
      }
    }

    function findLargestNumber(arr) {
      if (!Array.isArray(arr) || arr.length === 0) {
        return undefined;
      }

      let largestNumber = arr[0];

      for (let i = 1; i < arr.length; i++) {
        const currentNumber = parseFloat(arr[i]);

        if (!isNaN(currentNumber) && currentNumber > largestNumber) {
          largestNumber = currentNumber;
        }
      }

      return largestNumber;
    }

    const largest = findLargestNumber(name_arr);

    const newData = {
      user_id: userInfo.id,
      plan_type: 'SELF',
      name: `새플랜${largest ? Number(largest) + 1 : 1}`,
      plan_data: '[]',
      status: 0,
      is_show: 1,
      is_open: 1,
    };
    if (mediaCartData.self_count === 10) {
      Swal.fire({
        icon: 'error',
        iconColor: '#971b4d',
        text: '나의 플랜은 10개까지 생성 가능합니다.',
        confirmButtonText: '확인',
        confirmButtonColor: '#971b4d',
      });
      return;
    }
    axios
      .post(`${APIURL}plan`, newData, config)
      .then((res) => {
        setNewPlanNumber(res.data.id);
        getData();
        setNumber({ ...number, cart: number.cart + 1 });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removePlan = (id, type) => {
    Swal.fire({
      icon: 'question',
      iconColor: '#971b4d',
      text: '이 플랜을 삭제하시겠습니까?',
      showCancelButton: true,
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      confirmButtonColor: '#971b4d',
    }).then((result) => {
      if (result.isConfirmed) {
        if (type === 'self_list') {
          axios
            .delete(`${APIURL}plan/${id}`, config)
            .then((res) => {
              const updatedData = mediaCartData[type].filter((item) => item.id !== id);

              // Swal.fire({
              //   icon: "success",
              //   iconColor: "#971b4d",
              //   title: "삭제되었습니다!",
              //   confirmButtonText: "확인",
              //   confirmButtonColor: "#971b4d",
              // }).then((result2) => {
              //   if (result2.isConfirmed) {
              setMediaCartData({
                ...mediaCartData,
                [type]: updatedData,
              });
              setNumber({ ...number, cart: number.cart - 1 });
              getData();
              // }
              // });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          axios
            .put(`${APIURL}plan/show/update/${id}?is_show=0`, '', config)
            .then((res) => {
              console.log(res);
              const updatedData = mediaCartData[type].filter((item) => item.id !== id);
              // Swal.fire({
              //   icon: "success",
              //   iconColor: "#971b4d",
              //   title: "삭제되었습니다!",
              //   confirmButtonText: "확인",
              //   confirmButtonColor: "#971b4d",
              //   timer: 1500,
              // }).then((result2) => {
              //   if (result2.isConfirmed) {
              setMediaCartData({
                ...mediaCartData,
                [type]: updatedData,
              });
              setNumber({ ...number, cart: number.cart - 1 });
              //   }
              // });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    });
  };

  const initModal = {
    show: false,
    title: '',
    id: '',
  };

  const [modal, setModal] = useState(initModal);

  const showModal = (text, id) => {
    setModal({
      ...modal,
      show: true,
      title: text,
      id: id,
    });
  };

  const handlePlanTitle = (e) => {
    setModal({
      ...modal,
      title: e.target.value,
    });
  };

  const titleEditSubmit = () => {
    axios
      .put(`${APIURL}plan/name/${modal.id}?name=${modal.title}`, '', config)
      .then((res) => {
        console.log(res);
        Swal.fire({
          icon: 'success',
          iconColor: '#971b4d',
          text: '변경되었습니다',
          confirmButtonColor: '#971b4d',
          confirmButtonText: '확인',
        }).then((result) => {
          if (result.isConfirmed) {
            setModal(initModal);
            getData();
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const targetContainer = document.getElementById('plan_' + planId);
  useEffect(() => {
    if (planId) {
      axios
        .put(`${APIURL}plan/open/${planId}?is_open=1`, config)
        .then((res) => {
          if (res.status === 200) {
            if (targetContainer) {
              targetContainer.scrollIntoView({ behavior: 'smooth' });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [targetContainer]);
  return (
    <div className={`my-container bg-white ${styles.main_container}`}>
      <div className={`${trafficStyles.main_container}`}>
        <div className={`scroll ${styles.cartWrapper}`}>
          <div className={`${styles.cartTitle}`}>
            <div className={`d-flex align-items-center`}>
              <img src={`${process.env.PUBLIC_URL}image/icon/cartDefault.svg`} className={`mypage-title-icon`} />
            </div>
            <div className={`fw-800 ${styles.pfs_27px}`}>미디어 카트</div>
          </div>
          {loading ? <></> : <Loading />}
          <div className={`${styles.cartTableWrapper} d-flex flex-column`}>
            {plan_type_list.map((list) =>
              mediaCartData[list]?.map((item) => {
                const showData = JSON.parse(localStorage.getItem('showData'));
                if (item.is_show === 1 && item.show && item.is_open === 1) {
                  return (
                    <MediaCartTable
                      key={`cartTable_${item.id}`}
                      mediaCartData={item}
                      setMediaCartData={setMediaCartData}
                      removePlan={removePlan}
                      id={item.id}
                      config={config}
                      getData={getData}
                      showModal={showModal}
                      userInfo={userInfo}
                      number={number}
                      setNumber={setNumber}
                      isMobile={isMobile}
                      tableFooterQuery={tableFooterQuery}
                      rangeData={rangeData}
                      type={list}
                      self_count={mediaCartData.self_count}
                    />
                  );
                }
              }),
            )}
          </div>
          <div className="d-flex flex-column gap-2">
            <div className={`bd-dashed text-center fw-800 py-3 pointer ${trafficStyles.fs_17px}`} onClick={newPlan}>
              +빈 새 플랜
            </div>
            <div className={`bd-dashed text-center py-3`}>
              <div className={`d-flex flex-column justify-content-center ${styles.plan_gap}`}>
                <div className={`${trafficStyles.fs_17px} fw-800`}>
                  플랜 보관함 (
                  {(mediaCartData.rialto_count || 0) + (mediaCartData.req_count || 0) + (mediaCartData.self_count || 0)}
                  )
                </div>
                <div
                  className={`${trafficStyles.fs_12px} d-flex flex-column ${isMobile ? 'gap-3' : 'gap-1'
                    } fw-700 fs-9px`}>
                  <div className={`${styles.plan_list_wrapper}`}>
                    <span>나의 플랜 : </span>
                    <div className={`${styles.plan_list}`}>
                      {mediaCartData.self_count === 0 ? (
                        <span>생성된 플랜이 없습니다.</span>
                      ) : (
                        mediaCartData.self_list?.map((item) => (
                          <div
                            key={`plan_name_${item.id}`}
                            onClick={() => {
                              removePlan(item.id, 'self_list');
                            }}>
                            <span
                              className="pointer"
                              onClick={(e) => {
                                showPlan(e, item.id, 'self_list');
                              }}>
                              {item.name}
                            </span>
                            <IoMdClose className="fs-6 pointer" />
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                  <div className={`${styles.plan_list_wrapper} text-light-gray`}>
                    <span>요청된 플랜 : </span>
                    <div className={`${styles.plan_list}`}>
                      {mediaCartData.req_count === 0 ? (
                        <span>생성된 플랜이 없습니다.</span>
                      ) : (
                        mediaCartData.req_list?.map(
                          (item) =>
                            item.is_show === 1 && (
                              <div
                                key={`plan_name_${item.id}`}
                                onClick={() => {
                                  removePlan(item.id, 'req_list');
                                }}>
                                <span
                                  className="pointer"
                                  onClick={(e) => {
                                    showPlan(e, item.id, 'req_list');
                                  }}>
                                  {item.name}
                                </span>
                                <IoMdClose className="fs-6 pointer" />
                              </div>
                            ),
                        )
                      )}
                    </div>
                  </div>
                  <div className={`${styles.plan_list_wrapper} main`}>
                    <span>리알토 플랜 : </span>
                    <div className={`${styles.plan_list}`}>
                      {mediaCartData.rialto_count === 0 ? (
                        <span>생성된 플랜이 없습니다.</span>
                      ) : (
                        mediaCartData.rialto_list?.map(
                          (item) =>
                            item.is_show === 1 && (
                              <div
                                key={`plan_name_${item.id}`}
                              // onClick={() => {
                              //   removePlan(item.id, 'rialto_list');
                              // }}
                              >
                                <span
                                  className="pointer"
                                  onClick={(e) => {
                                    showPlan(e, item.id, 'rialto_list');
                                  }}>
                                  {item.name}
                                </span>
                                {/* <IoMdClose className="fs-6 pointer" /> */}
                              </div>
                            ),
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BasicModal
        header={'플랜 이름 변경'}
        show={modal.show}
        size={'500px'}
        onHide={() => {
          setModal(initModal);
        }}
        showcancel="true"
        onSubmit={titleEditSubmit}>
        <div className="bg-light-gray py-1 px-2">
          <input type="text" value={modal.title} onChange={handlePlanTitle} className={`${styles.text_input}`} />
        </div>
      </BasicModal>
    </div>
  );
}

function MediaCartTable({ ...props }) {
  const {
    mediaCartData,
    setMediaCartData,
    removePlan,
    id,
    config,
    getData,
    showModal,
    userInfo,
    number,
    setNumber,
    isMobile,
    rangeData,
    type,
    self_count,
    tableFooterQuery,
  } = props;

  const quillRef = useRef();

  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.addEventListener('change', async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('files', file);

      try {
        axios.post(`${APIURL}upload/origin`, formData, config).then((result) => {
          console.log('res', result.data);
          const IMG_URL = result.data[0].upload;

          const editor = quillRef.current.getEditor();
          const range = editor.getSelection();
          editor.insertEmbed(range.index, 'image', `${DEFAULTURL}image/${IMG_URL}`);
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const quillModules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['image'],
          ['clean'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    [],
  );

  const [allCheck, setAllCheck] = useState(false);
  const handleChecks = (seq, idx, categoryIdx, innerIdx) => {
    setMediaCartData((preData) => {
      const updatedData = preData[type].map((dataItem) => {
        if (dataItem.id !== seq) {
          return dataItem;
        }
        const updatedPlanData = dataItem.plan_data.map((listItem, index) => {
          if (index === idx) {
            const updatedData = listItem.data.map((_category, _categoryIndex) => {
              if (_categoryIndex === categoryIdx) {
                const updatedCheck = _category.data.map((_item, _itemIndex) => {
                  if (_itemIndex === innerIdx) {
                    return { ..._item, check: !_item.check };
                  }
                  return _item;
                });

                return { ..._category, data: updatedCheck };
              }
              return _category;
            });

            return { ...listItem, data: updatedData };
          }
          return listItem;
        });

        return { ...dataItem, plan_data: updatedPlanData };
      });

      return { ...preData, [type]: updatedData };
    });
  };

  useEffect(() => {
    let whole_length = 0;
    let checked_length = 0;

    for (const item of mediaCartData.plan_data) {
      for (const category of item.data) {
        whole_length += category.data.length;
        for (const list of category.data) {
          if (list.check) {
            checked_length++;
          }
        }
      }
    }

    setAllCheck(checked_length > 0 && whole_length === checked_length);
  }, [mediaCartData]);

  const categoryCounts = mediaCartData.plan_data.map((category_item) =>
    category_item.data.reduce((total, category_data) => total + category_data.data.length, 0),
  );

  const handleAllCheck = (seq) => {
    setAllCheck(!allCheck);
    setMediaCartData((preData) => {
      const updatedTableData = preData[type].map((dataItem) => {
        if (dataItem.id === seq) {
          const updatedPlanData = dataItem.plan_data.map((listItem) => {
            const updatedCategories = listItem.data.map((_category) => {
              const updatedItems = _category.data.map((_item) => ({
                ..._item,
                check: !allCheck,
              }));
              return { ..._category, data: updatedItems };
            });
            return { ...listItem, data: updatedCategories };
          });
          return { ...dataItem, plan_data: updatedPlanData };
        }
        return dataItem;
      });
      return { ...preData, [type]: updatedTableData };
    });
  };

  const contemporaryClose = () => {
    Swal.fire({
      icon: 'warning',
      iconColor: '#971b4d',
      text: '이 플랜을 닫고 보관함에 보관합니다',
      showCancelButton: true,
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      confirmButtonColor: '#971b4d',
    }).then((result) => {
      if (result.isConfirmed) {
        setMediaCartData((preData) => {
          const updatedData = preData[type].map((dataItem) => {
            if (dataItem.id === id) {
              const { request, show, plan_data, ...data } = dataItem;

              const updatedData = {
                user_id: mediaCartData.user_id,
                plan_type: data.plan_type,
                name: data.name,
                plan_data: JSON.stringify(plan_data),
                status: 0,
                is_show: 1,
                is_open: 0,
              };

              console.log(updatedData);

              axios
                .put(`${APIURL}plan/open/${id}?is_open=0`, config)
                .then((res) => {
                  console.log(res);
                })
                .catch((err) => {
                  console.log(err);
                });

              return {
                ...dataItem,
                show: false,
                is_open: 0,
              };
            }
            return dataItem;
          });
          // let arr = JSON.parse(localStorage.getItem("showData")) || [];

          // if (!arr.includes(id)) {
          //   arr.push(id);
          // }

          // localStorage.setItem("showData", JSON.stringify(arr));

          return { ...preData, [type]: updatedData };
        });
      }
    });
  };

  const showRequestForm = async () => {
    const showData = await setMediaCartData((preData) => {
      const updatedData = preData[type].map((item) => {
        if (item.id === id) {
          return {
            ...item,
            request: {
              ...item.request,
              show: true,
            },
          };
        }
        return item;
      });
      return { ...preData, [type]: updatedData };
    });

    const targetElement = document.getElementById(`requestForm${id}`);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const cancelRequestForm = () => {
    setMediaCartData((preData) => {
      const updatedData = preData[type].map((item) => {
        if (item.id === id) {
          return {
            ...item,
            request: {
              ...item.request,
              show: false,
              requestTitle: '',
              requestContent: '',
            },
          };
        }
        return item;
      });
      return { ...preData, [type]: updatedData };
    });
  };

  const textareaRef = useRef();

  useEffect(() => {
    const textarea = textareaRef.current;

    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;

      const updateHeight = () => {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      };

      textarea.addEventListener('input', updateHeight);

      return () => {
        textarea.removeEventListener('input', updateHeight);
      };
    }
  }, [mediaCartData.request]);

  const deleteChecks = () => {
    let _arr = [];
    const _plan_items = mediaCartData.plan_data;
    for (let i = 0; i < _plan_items.length; i++) {
      const large_category = _plan_items[i].data;
      for (let j = 0; j < large_category.length; j++) {
        const category = large_category[j].data;
        for (let k = 0; k < category.length; k++) {
          if (category[k].check) {
            _arr.push(category[k]);
          }
        }
      }
    }

    if (_arr.length < 1) {
      Swal.fire({
        icon: 'error',
        iconColor: '#971b4d',
        text: '삭제할 항목을 선택하세요',
        confirmButtonText: '확인',
        confirmButtonColor: '#971b4d',
      });
      return;
    }
    Swal.fire({
      icon: 'question',
      iconColor: '#971b4d',
      title: mediaCartData.title,
      text: '선택한 항목을 삭제하시겠습니까?',
      showCancelButton: true,
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      confirmButtonColor: '#971b4d',
    }).then((result) => {
      if (result.isConfirmed) {
        setMediaCartData((preData) => {
          const updatedTableData = preData[type].map((dataItem) => {
            if (dataItem.id !== id) {
              return dataItem;
            }
            const updatedPlanData = dataItem.plan_data
              .map((listItem) => {
                const updatedCategories = listItem.data
                  .map((_category) => {
                    const newItems = _category.data.filter((_item) => !_item.check);
                    return newItems.length > 0 ? { ..._category, data: newItems } : undefined;
                  })
                  .filter(Boolean);

                return updatedCategories.length > 0 ? { ...listItem, data: updatedCategories } : undefined;
              })
              .filter(Boolean);
            const newPlanData = updatedPlanData.map((listItem) => ({
              ...listItem,
              data: listItem.data.map((_category) => ({
                ..._category,
                data: _category.data.map((_item) => {
                  const { check, ...rest } = _item;
                  return rest;
                }),
              })),
            }));
            axios
              .delete(`${APIURL}plan/media/${id}`, {
                headers: {
                  Authorization: getCookieToken(),
                },
                data: {
                  plan_data: JSON.stringify(newPlanData),
                },
              })
              .then((res) => {
                console.log(res);

                // Swal.fire({
                //   icon: "success",
                //   iconColor: "#971b4d",
                //   title: "삭제되었습니다!",
                //   confirmButtonText: "확인",
                //   confirmButtonColor: "#971b4d",
                //   timer: 1500,
                // });

                setAllCheck(false);
              })
              .catch((err) => {
                console.log(err);
              });
            return { ...dataItem, plan_data: updatedPlanData };
          });
          return { ...preData, [type]: updatedTableData };
        });
      }
    });
  };

  const handleWriting = (e) => {
    setMediaCartData((preData) => {
      const updatedData = preData[type].map((item) => {
        if (item.id === id) {
          return {
            ...item,
            request: {
              ...item.request,
              [e.target.name]: e.target.value,
            },
          };
        }
        return item;
      });
      return { ...preData, [type]: updatedData };
    });
  };

  const handleContent = () => {
    console.log(quillRef.current.value);
    setMediaCartData((preData) => {
      const updatedData = preData[type].map((item) => {
        if (item.id === id) {
          return {
            ...item,
            request: {
              ...item.request,
              requestContent: quillRef.current.value,
            },
          };
        }
        return item;
      });
      return { ...preData, [type]: updatedData };
    });
  };

  const requestFileInput = useRef();

  const handleFiles = (e) => {
    const files = e.target.files;
    if (files[0].size > 30000000) {
      Swal.fire({
        icon: 'error',
        iconColor: '#971b4d',
        text: `첨부파일은 30MB 이하까지 가능합니다.`,
        confirmButtonText: '확인',
        confirmButtonColor: '#971b4d',
      });
      return;
    }

    const _fileArr = mediaCartData.request.requestFiles;
    for (let i = 0; i < _fileArr.length; i++) {
      if (_fileArr[i].name === files[0]?.name) return;
    }

    if (files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setMediaCartData((preData) => {
          const updatedData = preData[type].map((item) => {
            if (item.id === id) {
              return {
                ...item,
                request: {
                  ...item.request,
                  requestFiles: [...item.request.requestFiles, files[0]],
                  requestFileString: [...item.request.requestFileString, event.target.result],
                },
              };
            }
            return item;
          });
          return { preData, [type]: updatedData };
        });
      };

      reader.readAsDataURL(files[0]);
      reader.onloadend = () => {
        reader.abort();
      };
    }
  };

  const handleRemoveFile = (item, idx) => {
    const newFileString = mediaCartData.request.requestFileString.filter((_item) => _item !== item);
    const newFiles = mediaCartData.request.requestFiles.filter((item, index) => index !== idx);

    setMediaCartData((preData) => {
      const updatedData = preData[type].map((item) => {
        if (item.id === id) {
          return {
            ...item,
            request: {
              ...item.request,
              requestFiles: newFiles,
              requestFileString: newFileString,
            },
          };
        }
        return item;
      });
      return { preData, [type]: updatedData };
    });
  };

  const copyPlan = (seq) => {
    if (self_count === 10) {
      Swal.fire({
        icon: 'error',
        iconColor: '#971b4d',
        text: '나의 플랜은 10개까지 생성 가능합니다.',
        confirmButtonText: '확인',
        confirmButtonColor: '#971b4d',
      });
      return;
    }
    Swal.fire({
      icon: 'warning',
      iconColor: '#971b4d',
      text: '이 플랜의 복사본을 생성합니다.',
      showCancelButton: true,
      cancelButtonText: '취소',
      confirmButtonColor: '#971b4d',
      confirmButtonText: '확인',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${APIURL}plan/copy/${seq}`, '', config)
          .then((res) => {
            console.log(res);
            getData();
            setNumber({ ...number, cart: number.cart + 1 });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const titleArea = useRef();

  const table_total_price = mediaCartData.plan_data
    .map((categoryItem) =>
      categoryItem.data.map((categoryData) =>
        categoryData.data.reduce((acc, media) => {
          const selectedPrice = parseInt(media.selected_option.selected_price);
          if (!isNaN(selectedPrice)) {
            return acc + selectedPrice;
          }
          return acc;
        }, 0),
      ),
    )
    .flat()
    .reduce((acc, price) => acc + price, 0);

  const table_install_price = mediaCartData.plan_data
    .map((categoryItem) =>
      categoryItem.data.map((categoryData) =>
        categoryData.data.reduce((acc, media) => {
          const selectedInstallPrice = parseInt(media.selected_option.selected_install_price);
          if (!isNaN(selectedInstallPrice)) {
            return acc + selectedInstallPrice;
          }
          return acc;
        }, 0),
      ),
    )
    .flat()
    .reduce((acc, price) => acc + price, 0);

  const table_real_price = table_total_price + table_install_price;

  //부킹
  const submitBooking = async () => {
    if (mediaCartData.request.requestTitle === '') {
      Swal.fire({
        icon: 'info',
        iconColor: '#971b4d',
        text: '제목을 입력하세요.',
        confirmButtonText: '확인',
        confirmButtonColor: '#971b4d',
      }).then((result) => {
        if (result.isConfirmed) {
          titleArea.current.focus();
        }
      });
      return;
    }

    const { request, show, plan_data, ...data } = mediaCartData;

    const bookingData = () => {
      const _plan_data = plan_data.map((large) => ({
        ...large,
        data: large.data.map((category) => ({
          ...category,
          data: category.data.map((item) => {
            const { check, ...rest } = item;
            return rest;
          }),
        })),
      }));

      return {
        ...data,
        plan_data: _plan_data,
        real_price: table_real_price,
        total_install_price: table_install_price,
        total_price: table_total_price,
        plan_type: 'REQ',
      };
    };

    const uploadFiles = async () => {
      const _files = mediaCartData.request.requestFiles;

      let _files_arr = [];

      if (_files.length > 0) {
        const uploadPromises = [];
        const origins = [];

        for (let i = 0; i < _files.length; i++) {
          const formData = new FormData();
          formData.append('files', _files[i]);

          const origin = axios.post(`${APIURL}upload/origin`, formData, config);
          origins.push(origin);
        }

        try {
          const originResponses = await Promise.all(origins);
          originResponses.forEach((res) => {
            console.log('???', res);
            _files_arr.push(res.data[0]);
          });
          return _files_arr;
        } catch (uploadError) {
          console.log(uploadError);
        }
      }
    };

    try {
      const uploadedFiles = await uploadFiles();

      const _plan_id = data.id;

      const plan = await axios.get(`${APIURL}plan/${_plan_id}`);
      console.log(plan);

      const copyData = {
        user_id: mediaCartData.user_id,
        plan_type: 'REQ',
        name: `${mediaCartData.name}(요청됨)`,
        plan_data: JSON.stringify(bookingData().plan_data),
        status: 0,
        is_show: 1,
        is_open: 0,
      };

      const copyPost = await axios.post(`${APIURL}plan`, copyData, config);

      if (copyPost.status === 200) {
        console.log('ok');
        const dataReserve = await axios.get(`${APIURL}plan/${copyPost.data.id}`);
        if (dataReserve.status === 200) {
          console.log('reserveOK', uploadedFiles);
          const submitData = {
            user_id: mediaCartData.user_id,
            parent_id: 0,
            title: mediaCartData.request.requestTitle,
            content: mediaCartData.request.requestContent,
            reserve_data: JSON.stringify([dataReserve.data]),
            parent_name: '',
            files: JSON.stringify(uploadedFiles),
            type: 'RESERVE',
            status: 0,
            plan_ids: `${copyPost.data.id}`,
          };

          const res = await axios.post(`${APIURL}reservation`, submitData, config);

          if (res.status === 200) {
            console.log(res.data);
            Swal.fire({
              icon: 'success',
              iconColor: '#971b4d',
              text: '요청되었습니다.',
              confirmButtonText: '확인',
              confirmButtonColor: '#971b4d',
            }).then((res) => {
              // setNumber({ ...number, mypage: number.mypage + 1 });
              cancelRequestForm();
              getData();
            });
          }
        }
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        iconColor: '#971b4d',
        text: '삭제된 매체입니다.',
        confirmButtonText: '확인',
        confirmButtonColor: '#971b4d',
      });
    }

    // const copyData = {
    //   user_id: mediaCartData.user_id,
    //   plan_type: "REQ",
    //   name: `${mediaCartData.name}(요청됨)`,
    //   plan_data: JSON.stringify(bookingData().plan_data),
    //   status: 0,
    //   is_show: 1,
    // };

    // const submitData = {
    //   user_id: mediaCartData.user_id,
    //   parent_id: 0,
    //   title: mediaCartData.request.requestTitle,
    //   content: mediaCartData.request.requestContent,
    //   reserve_data: JSON.stringify([bookingData()]),
    //   parent_name: "",
    //   // files: JSON.stringify(_files_arr),
    //   type: "RESERVE",
    //   status: 0,
    //   plan_ids: `${mediaCartData.id}`,
    // };

    // try {
    //   axios.post(`${APIURL}plan`, copyData, config).then((res) => {
    //     console.log(res);
    //     setNumber({ ...number, cart: number.cart + 1 });
    //   });
    // } catch (err) {
    //   console.log(err);
    // }

    // try {
    //   const submissionResponse = await axios.post(
    //     `${APIURL}reservation`,
    //     submitData,
    //     config
    //   );
    //   console.log(submissionResponse);
    //   Swal.fire({
    //     icon: "success",
    //     iconColor: "#971b4d",
    //     text: "요청되었습니다.",
    //     confirmButtonText: "확인",
    //     confirmButtonColor: "#971b4d",
    //   }).then((res) => {
    //     setNumber({ ...number, mypage: number.mypage + 1 });
    //     cancelRequestForm();
    //     getData();
    //   });
    // } catch (submissionError) {
    //   console.log(submissionError);
    // }
  };
  const handleDate = (date, itemIdx, cateIdx, idx) => {
    console.log(date);
    const moment_date = moment(date).format('YYYY-MM-DD');
    setMediaCartData((preData) => {
      const updatedData = preData[type].map((dataItem) => {
        if (dataItem.id !== id) {
          return dataItem;
        }
        const updatedPlanData = dataItem.plan_data.map((listItem, listIndex) => {
          const updatedCategories = listItem.data.map((categoryItem, categoryIndex) => {
            const updatedItems = categoryItem.data.map((item, itemIndex) => {
              //limit_start_date에 따른 날짜 맞추기
              const dateDefault = (limitDate) => {
                const _today = new Date();
                switch (Number(limitDate)) {
                  case 1: //매 월 1일
                    let date1;

                    let preDate1 = new Date(date.getFullYear(), date.getMonth(), 1);

                    let nextDate1 = new Date(date.getFullYear(), date.getMonth() + 1, 1);

                    if (preDate1 > _today) {
                      if (date - preDate1 > nextDate1 - date) {
                        date1 = nextDate1;
                      } else {
                        date1 = preDate1;
                      }
                    } else if (preDate1 === _today) {
                      date1 = preDate1;
                    } else {
                      date1 = nextDate1;
                    }

                    return moment(date1).format('YYYY-MM-DD');
                  case 2: // 매 월 1일, 16일
                    let date2;
                    let preDate2_1 = new Date(date.getFullYear(), date.getMonth(), 1);

                    let preDate2_16 = new Date(date.getFullYear(), date.getMonth(), 16);

                    let nextDate2_1 = new Date(date.getFullYear(), date.getMonth() + 1, 1);

                    if (preDate2_1 > _today) {
                      if (date.getDate() >= 1 && date.getDate() <= 16) {
                        if (date - preDate2_1 > preDate2_16 - date) {
                          date2 = preDate2_16;
                        } else {
                          date2 = preDate2_1;
                        }
                      } else {
                        if (nextDate2_1 - date > date - preDate2_16) {
                          date2 = preDate2_16;
                        } else {
                          date2 = nextDate2_1;
                        }
                      }
                    } else if (preDate2_16 > _today.getDate() - 1) {
                      if (nextDate2_1 - date > date - preDate2_16) {
                        date2 = preDate2_16;
                      } else {
                        date2 = nextDate2_1;
                      }
                    } else {
                      date2 = preDate2_16;
                    }

                    return moment(date2).format('YYYY-MM-DD');
                  case 3: // 매 년 1월 1일
                    let date3;

                    const predate3 = new Date(date.getFullYear(), 0, 1);
                    const nextDate3 = new Date(date.getFullYear() + 1, 0, 1);

                    if (moment(date).format('YYYY-MM-DD') === moment(predate3).format('YYYY-MM-DD')) {
                      date3 = predate3;
                    } else {
                      if (date - predate3 < nextDate3 - date) {
                        date3 = predate3;
                      } else {
                        date3 = nextDate3;
                      }
                    }

                    return moment(date3).format('YYYY-MM-DD');
                  case 4: // 매 주 월요일
                    return getClosestDayOfWeek(date, 1);
                  case 5: // 매 주 화요일
                    return getClosestDayOfWeek(date, 2);
                  case 6: // 매 주 수요일
                    return getClosestDayOfWeek(date, 3);
                  case 7: // 매 주 목요일
                    return getClosestDayOfWeek(date, 4);
                  case 8: // 매 주 금요일
                    return getClosestDayOfWeek(date, 5);
                  case 9: // 매 주 토요일
                    return getClosestDayOfWeek(date, 6);
                  case 10: // 매 주 일요일
                    return getClosestDayOfWeek(date, 7);
                  case 11: // 매 월 1, 10, 20일
                    let date11;

                    const preDate11_1 = new Date(date.getFullYear(), date.getMonth(), 1);
                    const preDate11_10 = new Date(date.getFullYear(), date.getMonth(), 10);
                    const preDate11_20 = new Date(date.getFullYear(), date.getMonth(), 20);

                    const nextDate11_1 = new Date(date.getFullYear(), date.getMonth() + 1, 1);

                    if (date.getDate() >= 1 && date.getDate() < 10) {
                      if (date - preDate11_1 < preDate11_10 - date) {
                        date11 = preDate11_1;
                      } else {
                        date11 = preDate11_10;
                      }

                      if (date11 - _today < 0) {
                        date11 = preDate11_10;
                      }
                    } else if (date.getDate() >= 10 && date.getDate() < 20) {
                      if (date - preDate11_10 < preDate11_20 - date) {
                        date11 = preDate11_10;
                      } else {
                        date11 = preDate11_20;
                      }

                      if (date11 - _today < 0) {
                        date11 = preDate11_20;
                      }
                    } else {
                      if (date - preDate11_20 < nextDate11_1 - date) {
                        date11 = preDate11_20;
                        if (date11 - _today < 0) {
                          date11 = nextDate11_1;
                        }
                      } else {
                        date11 = nextDate11_1;
                      }
                    }
                    return moment(date11).format('YYYY-MM-DD');
                  default:
                    return moment_date;
                }
              };

              function getClosestDayOfWeek(date, dayOfWeek) {
                const resultDate = new Date(date);
                const currentDayOfWeek = resultDate.getDay();
                const today = new Date();

                let dayDiff = dayOfWeek - currentDayOfWeek;

                // if (dayDiff <= 0) {
                //   dayDiff += 7;
                // }

                resultDate.setDate(date.getDate() + dayDiff);

                if (resultDate < today) {
                  resultDate.setDate(resultDate.getDate() + 7);
                }

                return moment(resultDate).format('YYYY-MM-DD');
              }

              if (item.check) {
                item.selected_option.start_date = dateDefault(item.select_option.limit_start_date);
              }
              if (listIndex === itemIdx && categoryIndex === cateIdx && itemIndex === idx) {
                item.selected_option.start_date = moment_date;
                // item.selected_option.start_date = _date;
              }
              return item;
            });
            return { ...categoryItem, data: updatedItems };
          });
          return { ...listItem, data: updatedCategories };
        });
        if (dataItem.id === id) {
          const { request, show, plan_data, ...data } = dataItem;

          const _plan_data = plan_data.map((large) => ({
            ...large,
            data: large.data.map((category) => ({
              ...category,
              data: category.data.map((item) => {
                const { check, ...rest } = item;
                return rest;
              }),
            })),
          }));

          const _updated_plan = {
            plan_data: JSON.stringify(_plan_data),
          };

          axios
            .put(`${APIURL}plan/media/${id}`, _updated_plan, config)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        return { ...dataItem, plan_data: updatedPlanData };
      });

      return { ...preData, [type]: updatedData };
    });
  };

  const info_message = mediaCartData.plan_data.some((large) =>
    large.data.some((category) =>
      category.data.some((item) => {
        return (
          item.selected_option.selected_price === '별도안내' ||
          item.selected_option.selected_install_price === '별도안내'
        );
      }),
    ),
  );

  const numberWithCommas = (number) => {
    return number.toLocaleString();
  };

  const tableRef = useRef(null);

  return (
    <div id={`plan_${id}`}>
      <div className={`d-flex align-items-end pb-1 flex-wrap`}>
        <div
          className={`fw-800 d-flex align-items-center ps-1 gap-1 pointer ${styles.pfs_22px}
          `}
          onClick={() => {
            if (type === 'self_list') {
              showModal(mediaCartData.name, id);
            }
          }}>
          <span className={`${type === 'req_list' && 'text-light-gray'} ${type === 'rialto_list' && 'main'}`}>
            {mediaCartData.name}
          </span>
          {type === 'self_list' && <RiEdit2Fill />}
        </div>
        <div className={`ms-auto d-flex fw-800 ${styles.fs_13px} ${styles.gap_22px}`}>
          <div className={styles.w_66px}>
            <DownloadTableExcel filename={mediaCartData.name} sheet={'sheet'} currentTableRef={tableRef.current}>
              <span className="pointer nav-text-hover">
                엑셀 출력
                <FaRegFileExcel />
              </span>
            </DownloadTableExcel>
          </div>
          <div className={styles.w_66px}>
            <span
              className="pointer nav-text-hover"
              onClick={() => {
                copyPlan(id);
              }}>
              플랜 복사
              <img src={`${process.env.PUBLIC_URL}image/icon/copy_icon.svg`} alt="" />
            </span>
          </div>
          {mediaCartData.plan_type !== 'RIALTO' && (
            <div className={styles.w_66px}>
              <span
                className="pointer nav-text-hover"
                onClick={() => {
                  removePlan(id, type);
                }}>
                플랜 삭제
                <img src={`${process.env.PUBLIC_URL}image/icon/trash_icon.svg`} alt="" />
              </span>
            </div>
          )}
          <div className={styles.w_104px}>
            <span className="pointer nav-text-hover" onClick={contemporaryClose}>
              플랜 닫아두기 X
            </span>
          </div>
        </div>
      </div>
      <div className={`${styles.tableResponsive} scroll`}>
        <table className={`table table-borderless ${styles.table}`}>
          <thead>
            <tr>
              <th
                className={`${styles.bg_dark_gray} table-bd-bottom-white fw-800 ${mediaCartData.plan_data.length === 0 ? 'table-bd-right-white' : ''
                  }`}
                colSpan={2}
                style={{
                  width: mediaCartData.plan_data.length === 0 ? '15%' : '13%',
                }}>
                카테고리
              </th>
              {mediaCartData.plan_data.length !== 0 && (
                <th
                  style={{ width: '10px' }}
                  className={`bg-dark-gray table-bd-right-white ps-1 table-bd-bottom-white ${styles.tableSticky}`}></th>
              )}
              <th
                className={`${styles.table_th} ${styles.bg_dark_gray} table-bd-bottom-white fw-700`}
                style={{ width: '1%' }}>
                <div className={`${styles.check_group}`}>
                  <AllCheck
                    name={`cart_${mediaCartData.id}`}
                    onChange={() => {
                      handleAllCheck(id);
                    }}
                    check={allCheck}
                    label={
                      <span className={`${styles.fs_9px} d-block lh-1`}>
                        전체
                        <br />
                        선택
                      </span>
                    }
                    type={type}
                  />
                  {type === 'self_list' && (
                    <span
                      className={`${styles.fs_9px} d-flex align-items-center pointer gap-1 lh-1`}
                      onClick={deleteChecks}>
                      <img src={`${process.env.PUBLIC_URL}image/icon/trashBin.svg`} />
                      <span>
                        선택
                        <br />
                        삭제
                      </span>
                    </span>
                  )}
                </div>
              </th>
              <th
                className={`${styles.bg_dark_gray} fw-800 table-bd-bottom-white`}
                style={{
                  width: mediaCartData.plan_data.length === 0 ? '18%' : '23%',
                }}>
                미디어 옵션
              </th>
              <th
                className={`${styles.bg_dark_gray} fw-800 table-bd-bottom-white`}
                style={{
                  width: mediaCartData.plan_data.length === 0 ? '6%' : '8%',
                }}>
                광고료
              </th>
              <th
                className={`${styles.bg_dark_gray} fw-800 table-bd-bottom-white table-bd-right-white pe-3`}
                style={{
                  width: '8%',
                }}>
                출력/설치비
              </th>
              <th
                className={`${styles.bg_dark_gray} fw-800 table-bd-bottom-white`}
                style={{
                  width: mediaCartData.plan_data.length === 0 ? '6%' : '4%',
                }}>
                광고 시작일
              </th>
              <th className={`${styles.bg_dark_gray} fw-800 table-bd-bottom-white`} style={{ width: '25%' }}>
                광고 기간
              </th>
            </tr>
          </thead>
          <tbody>
            {mediaCartData.plan_data?.map((category_item, idx) =>
              category_item.data?.map((category_data, category_index) => {
                const categoryItemCount = categoryCounts[idx];
                return category_data.data?.map((_item, innerIdx) => {
                  let _last = false;
                  if (category_index + 1 === category_item.data.length && innerIdx + 1 === category_data.data.length) {
                    _last = true;
                  }

                  return (
                    <tr key={`item${id}_${idx}_${category_index}_${innerIdx}`}>
                      {category_index === 0 && innerIdx === 0 && (
                        <td
                          className={`bg-light-gray table-bd-bottom-white text-center ${trafficStyles.fs_12px} fw-700 px-2`}
                          rowSpan={categoryItemCount}
                          style={{ letterSpacing: '-0.09rem' }}>
                          <div style={{ width: '65px' }}>{category_item.category_name}</div>
                        </td>
                      )}
                      {innerIdx === 0 && _item && (
                        <td
                          className={`${category_item.data.length === category_index + 1
                              ? 'table-bd-bottom-white'
                              : 'table-bd-bottom-white-1'
                            } bg-light-gray text-center ${trafficStyles.fs_12px} fw-700`}
                          rowSpan={category_data.data.length}
                          style={{ letterSpacing: '-0.09rem' }}>
                          <div style={{ width: '100px' }}>{category_data.category_name}</div>
                        </td>
                      )}
                      <td
                        style={{ width: '10px' }}
                        className={`${_last && 'table-bd-bottom-white'} bg-light-gray table-bd-right-white ps-1 ${styles.tableSticky
                          }`}></td>
                      <td
                        className={`${_last && 'table-bd-bottom-white'} bg-light-gray text-center ${styles.tableSticky
                          }`}>
                        <div className="ps-2">
                          <div
                            className={`${!_last && 'table-bd-bottom-white-1'} d-flex align-items-center gap-2 ${styles.new_line_pt2
                              } ${styles.py_11px}`}>
                            <Checkbox
                              name={`media_${mediaCartData.id}_${idx}_${category_index}_${innerIdx}`}
                              check={_item.check}
                              onChange={() => {
                                handleChecks(mediaCartData.id, idx, category_index, innerIdx);
                              }}
                              label={_item.media_name}
                              id={_item.media}
                              categoryId={category_data.category_id}
                              largeCategoryId={category_item.category_id}
                              type={type}
                            />
                          </div>
                        </div>
                      </td>
                      <td className={`bg-light-gray ${_last && 'table-bd-bottom-white'}`}>
                        <div
                          className={`${!_last && 'table-bd-bottom-white-1'} d-flex gap-1 w-100 ${styles.ppy_11_25px}`}>
                          <SelectboxWrapper
                            item={_item}
                            categoryIndex={category_index}
                            itemIndex={idx}
                            setMediaCartData={setMediaCartData}
                            type={type}
                            planId={mediaCartData.id}
                            isMobile={isMobile}
                            innerIdx={innerIdx}
                            config={config}
                            mediaCartData={mediaCartData}
                          />
                        </div>
                      </td>
                      <td
                        className={`${_last && 'table-bd-bottom-white'} ${styles.fs_9px} text-center bg-light-gray`}
                      // style={{ minWidth: "94px", maxWidth: "94px" }}
                      >
                        <div
                          className={`${!_last && 'table-bd-bottom-white-1'} ${styles.ppy_12_5px} ${styles.new_line_pt1
                            } d-flex w-100 justify-content-end align-items-center`}>
                          {isNaN(Number(_item.selected_option.selected_price)) ? (
                            <span className="table_text_height">별도안내</span>
                          ) : (
                            <NumericFormat
                              value={_item.selected_option.selected_price}
                              thousandSeparator={true}
                              className={`${trafficStyles.numericFormat} text-end p-0`}
                              suffix="원"
                              style={{ minWidth: '100px' }}
                            />
                          )}
                        </div>
                      </td>
                      <td
                        className={`${_last && 'table-bd-bottom-white'} ${styles.fs_9px
                          } text-center bg-light-gray table-bd-right-white`}
                      // style={{ minWidth: "94px", maxWidth: "94px" }}
                      >
                        <div className="pe-3">
                          <div
                            className={`${!_last && 'table-bd-bottom-white-1'} ${styles.ppy_12_5px} ${styles.new_line_pt1
                              } d-flex w-100 justify-content-end align-items-center`}>
                            {isNaN(Number(_item.selected_option.selected_install_price)) ? (
                              <span className="table_text_height">별도안내</span>
                            ) : (
                              <NumericFormat
                                value={_item.selected_option.selected_install_price || 0}
                                thousandSeparator={true}
                                className={`${trafficStyles.numericFormat} text-end p-0`}
                                suffix="원"
                                style={{ minWidth: '100px' }}
                              />
                            )}
                          </div>
                        </div>
                      </td>
                      <td
                        className={`bg-light-gray ${styles.date_picker_wrapper} ${_last && 'table-bd-bottom-white'}`}
                        style={{ width: '4%' }}>
                        <DateHolder
                          defaultDate={_item.selected_option.start_date}
                          onChange={handleDate}
                          itemIndex={idx}
                          categoryIndex={category_index}
                          index={innerIdx}
                          selectSet={_item.selected_option.selected_limit_Date}
                          isMobile={isMobile}
                          type={type}
                        />
                      </td>
                      <td className={`bg-light-gray pe-3 ${_last && 'table-bd-bottom-white'}`} style={{ width: '30%' }}>
                        <div className="w-100">
                          <MediaRangeComponent itemData={_item} mediaCartData={mediaCartData} rangeData={rangeData} />
                        </div>
                      </td>
                    </tr>
                  );
                });
              }),
            )}
            {mediaCartData.plan_data.length !== 0 && !tableFooterQuery && (
              <>
                <tr>
                  <td colSpan={3} rowSpan={2} className="bg-light-gray table-bd-right-white"></td>
                  <td colSpan={2} rowSpan={2} className="bg-light-gray table-bd-top-white">
                    <div className="d-flex w-100 align-items-center">
                      {type === 'self_list' && (
                        <div
                          className={`${styles.requestButton} bd-radius-5px text-white
                        ${mediaCartData.request.show ? 'bg-secondary' : 'bg-purple pointer'}
                        `}
                          onClick={showRequestForm}>
                          <div className={`text-center ${styles.w_114px}`}>부킹 요청하기</div>
                        </div>
                      )}
                      <div className="ms-auto d-flex flex-column pe-3">
                        <div className={`${styles.fs_12px} fw-800`}>소　계</div>
                        <div className={`${styles.fs_12px} ${styles.ppt_13px} pb-3 fw-800`}>총금액</div>
                      </div>
                    </div>
                  </td>
                  <td
                    className="text-center bg-light-gray table-bd-top-white"
                  // style={{ minWidth: "94px", maxWidth: "94px" }}
                  >
                    <div className={`${styles.ppt_9px} d-flex w-100 justify-content-center align-items-center`}>
                      <NumericFormat
                        value={table_total_price}
                        thousandSeparator={true}
                        className={`${trafficStyles.numericFormat} ${styles.fs_10px} text-end p-0`}
                        suffix="원"
                      />
                    </div>
                  </td>
                  <td
                    className="text-center bg-light-gray table-bd-top-white table-bd-right-white"
                  // style={{ minWidth: "94px", maxWidth: "94px" }}
                  >
                    <div className={`${styles.ppt_9px} d-flex w-100 justify-content-center align-items-center pe-3`}>
                      <NumericFormat
                        value={table_install_price}
                        thousandSeparator={true}
                        className={`${trafficStyles.numericFormat} ${styles.fs_10px} text-end p-0`}
                        suffix="원"
                      />
                    </div>
                  </td>
                  <td colSpan={2} rowSpan={info_message ? 3 : 2} className="bg-light-gray table-bd-top-white">
                    <div className="d-flex w-100 justify-content-center">
                      <div className={`${styles.fs_10px} ${styles.lh_170} fw-700 text-gray`}>
                        여러매체를선택한 상태에서 광고 시작일을 변경하면 일괄변경됩니다. <br />
                        광고시작일이 지정된 매체는 요청하신 일자에 가장 가까운 지정일로변경됩니다.
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="text-center bg-light-gray table-bd-right-white">
                    <div className={`${styles.ppt_9px} text-purple fw-800`}>
                      <NumericFormat
                        value={table_real_price}
                        suffix="원"
                        className={`${trafficStyles.numericFormat} ${styles.fs_17px} main fw-800 text-center`}
                        thousandSeparator={true}
                      />
                    </div>
                    <div className="pb-2 position-relative">
                      <div className={`${styles.fs_10px} fs-7px text-gray`}>부가세(VAT)별도</div>
                    </div>
                  </td>
                </tr>

                {info_message && (
                  <tr>
                    <td colSpan={3} className="pb-2 text-center bg-light-gray table-bd-right-white"></td>
                    <td colSpan={4} className="pb-2 bg-light-gray table-bd-right-white">
                      <div className={`${styles.fs_10px} fw-700 ${styles.text_gray} text-end pe-3`}>
                        * 별도 안내 항목이 존재합니다. 총 금액이 변경될 수 있습니다.
                      </div>
                    </td>
                    <td colSpan={2} className="bg-light-gray pb-2"></td>
                  </tr>
                )}
              </>
            )}
            {mediaCartData.plan_data.length === 0 && (
              <tr>
                <td colSpan={9} className="text-center fs-9px fw-800" style={{ backgroundColor: '#F2F2F2' }}>
                  빈 플랜
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <table className="d-none" ref={tableRef}>
          <thead>
            <tr>
              <th colSpan={2}>카테고리</th>
              <th>미디어</th>
              <th>미디어 옵션</th>
              <th>광고료</th>
              <th>출력/설치비</th>
              <th>광고 시작일</th>
              <th>광고 기간</th>
            </tr>
          </thead>
          <tbody>
            {mediaCartData.plan_data?.map((category_item, idx) =>
              category_item.data?.map((category_data, category_index) => {
                const categoryItemCount = categoryCounts[idx];
                return category_data.data?.map((_item, innerIdx) => {
                  let _last = false;
                  if (category_index + 1 === category_item.data.length && innerIdx + 1 === category_data.data.length) {
                    _last = true;
                  }

                  return (
                    <tr key={`item${id}_${idx}_${category_index}_${innerIdx}`}>
                      {category_index === 0 && innerIdx === 0 && (
                        <td rowSpan={categoryItemCount}>{category_item.category_name}</td>
                      )}
                      {innerIdx === 0 && _item && (
                        <td rowSpan={category_data.data.length}>{category_data.category_name}</td>
                      )}
                      <td>{_item.media_name}</td>
                      <td>
                        {_item.select_option?.name} / {_item.selected_option.selected_period} /{' '}
                        {_item.selected_option.selected_amount}
                      </td>
                      <td>
                        {isNaN(Number(_item.selected_option.selected_price)) ? (
                          <span className="table_text_height">별도안내</span>
                        ) : (
                          <>{_item.selected_option.selected_price}원</>
                        )}
                      </td>
                      <td>
                        {isNaN(Number(_item.selected_option.selected_install_price)) ? (
                          <span className="table_text_height">별도안내</span>
                        ) : (
                          <>{_item.selected_option.selected_install_price}원</>
                        )}
                      </td>
                      <td>{_item.selected_option.start_date}</td>
                      <td>
                        <RangeText mediaCartData={mediaCartData} itemData={_item} rangeData={rangeData} />
                      </td>
                    </tr>
                  );
                });
              }),
            )}
            {mediaCartData.plan_data.length !== 0 && !tableFooterQuery && (
              <>
                <tr>
                  <td colSpan={2} rowSpan={2}>
                    합계
                  </td>
                  <td rowSpan={2}></td>
                  <td colSpan={1} rowSpan={2}></td>
                  <td>
                    <div>{table_total_price}원</div>
                  </td>
                  <td>{table_install_price}원</td>
                  <td colSpan={2} rowSpan={info_message ? 3 : 2}></td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className={`${styles.ppt_9px} text-purple fw-800`}>총 {table_real_price}원</div>
                    <div className="pb-2 position-relative">
                      <div className={`${styles.fs_10px} fs-7px text-gray`}>부가세(VAT)별도</div>
                    </div>
                  </td>
                </tr>

                {info_message && (
                  <tr>
                    <td colSpan={3}></td>
                    <td colSpan={3}>* 별도 안내 항목이 존재합니다. 총 금액이 변경될 수 있습니다.</td>
                    <td colSpan={2}></td>
                  </tr>
                )}
              </>
            )}
            {/* {mediaCartData.plan_data.length === 0 && (
              <tr>
                <td
                  colSpan={9}
                  className="text-center fs-9px fw-800"
                  style={{ backgroundColor: "#F2F2F2" }}
                >
                  빈 플랜
                </td>
              </tr>
            )} */}
          </tbody>
        </table>
      </div>
      {mediaCartData.plan_data.length !== 0 && tableFooterQuery && (
        <div className={`${styles.mobileBookingWrapper}`}>
          <div className="bg-light-gray d-flex align-items-center py-3">
            <div className={`${styles.mobileBooking}`}>
              {type === 'self_list' && (
                <div
                  className={`${styles.mobileRequestButton} bd-radius-5px text-white
                        ${mediaCartData.request.show ? 'bg-secondary' : 'bg-purple pointer'}
                        `}
                  onClick={showRequestForm}>
                  <div className={styles.mobileBookingButton}>부킹 요청하기</div>
                </div>
              )}
            </div>
            <div className="ms-auto pe-4">
              <div className="d-flex align-items-center justify-content-end gap-2">
                <div className="white-nowrap fs-12px fw-800">총 금액</div>
                <div className={`${styles.numericFormat} ${styles.fs_17px} main fw-800 text-end`}>
                  {numberWithCommas(table_real_price)}
                </div>
                {/* <div>
                    <NumericFormat
                      value={table_real_price}
                      suffix="원"
                      className={`${styles.numericFormat} ${styles.fs_17px} main fw-800 text-end`}
                      thousandSeparator={true}
                      style={{ width: "auto" }}
                    />
                  </div> */}
              </div>
              <div className={`${styles.fs_10px} fw-700 ${styles.text_gray} text-end`}>부가세(VAT) 별도</div>
              {info_message && (
                <div className={`${styles.fs_10px} fw-700 ${styles.text_gray} text-end`}>
                  * 별도 안내 항목이 존재합니다. 총 금액이 변경될 수 있습니다.
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {mediaCartData.request.show && (
        <>
          <div className="d-flex align-items-end" id={`requestForm${id}`}>
            <div className={`${styles.booking_text}`}>부킹 요청하기</div>
            <span className={`ms-auto bd-radius-5px text-white pointer mb-1`} onClick={cancelRequestForm}>
              <img src={`${process.env.PUBLIC_URL}image/icon/close_button.svg`} />
            </span>
          </div>
          <div className={styles.booking_info}>
            부킹 요청을 하시면 플래너의 확인을 거쳐 구매 가능한 플랜으로 조정하여 제안드립니다.
            <br />
            실시간 재고확인 및 바로구매가 가능한 미디어 플랫폼을 목표로 열심히 하겠습니다.
          </div>
          <div className={styles.request_wrapper_pt}>
            <div className={`${styles.request_wrapper} scroll`}>
              <div className={styles.requst_title}>
                제목<span className="text-danger">*</span>
              </div>
              <input
                type="text"
                className={`${styles.text_input}`}
                placeholder="제목을 입력하세요"
                onBlur={handleWriting}
                name={'requestTitle'}
                ref={titleArea}
              />
              <div className={`${styles.requst_title} pt-3`}>내용</div>
              {/* <ReactQuill
                ref={quillRef}
                modules={quillModules}
                placeholder="캠페인의 특성 : 2023 겨울신상품 홍보용으로 20~39 여성 타겟 캠페인을 준비하고 있습니다. &#13;&#10;캠페인 기간과 옥외광고 예산 : 9.10.11월 3개월 총 예산 00억정도이며&#13;&#10;선호하는 매체 : 홍대, 강남 전광판들과 복합쇼핑몰 위주로 플랜 부탁드려요.&#13;&#10;매진된 매체는 같은 기간 유사한 매체로 추천해주세요.&#13;&#10;기타 자유롭게 문의사항을 남겨주세요."
                className={"mediaQuill"}
                onBlur={handleContent}
              /> */}
              <textarea
                ref={textareaRef}
                rows={1}
                cols="50"
                className={`${styles.textarea} w-100`}
                placeholder="캠페인의 특성 : 2023 겨울신상품 홍보용으로 20~39 여성 타겟 캠페인을 준비하고 있습니다. &#13;&#10;캠페인 기간과 옥외광고 예산 : 9.10.11월 3개월 총 예산 00억정도이며&#13;&#10;선호하는 매체 : 홍대, 강남 전광판들과 복합쇼핑몰 위주로 플랜 부탁드려요.&#13;&#10;매진된 매체는 같은 기간 유사한 매체로 추천해주세요.&#13;&#10;기타 자유롭게 문의사항을 남겨주세요."
                // value={mediaCartData.request.requestContent}
                // onChange={handleWriting}
                onBlur={handleWriting}
                name={'requestContent'}></textarea>
            </div>
          </div>
          <div className="pt-1">
            <div className={`${styles.attached_plan_wrapper}`}>
              <div className={`${styles.requst_title} pb-1`}>첨부된 플랜</div>
              <div className="fs-11px text-gray">{mediaCartData.name}</div>
            </div>
          </div>
          <div className={`${styles.booking_button_wrapper}`}>
            <div className="d-flex gap-2">
              <div
                className="bg-main px-4 py-2 text-white fw-700 fs-12px bd-radius-5px pointer"
                onClick={submitBooking}>
                <div className={`text-center ${styles.w_114px}`}>부킹 요청하기</div>
              </div>
              <label className="bg-sub px-4 py-2 fw-700 fs-12px bd-radius-5px pointer" htmlFor={`requsetFiles_${id}`}>
                <div className={`text-center ${styles.w_114px}`}>파일 첨부하기</div>
              </label>
              <input
                type="file"
                name="requestFiles"
                id={`requsetFiles_${id}`}
                className="d-none"
                onChange={handleFiles}
                ref={requestFileInput}
              />
            </div>
            <div className={`${styles.attached_file_list}`}>
              {mediaCartData.request.requestFiles.map((item, idx) => (
                <div
                  className="pointer"
                  key={`requestFile_${id}_${idx}`}
                  onClick={() => {
                    handleRemoveFile(item, idx);
                  }}>
                  {item.name}
                  <IoMdClose className="fs-6" />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function DateHolder(props) {
  const { defaultDate, onChange, index, selectSet, isMobile, itemIndex, categoryIndex, type } = props;

  const filterDate = (date) => {
    const condition = Number(selectSet); // 원하는 조건 번호를 선택

    switch (condition) {
      case 1: // 매 월 1일
        return getDate(date) === 1;
      case 2: // 매 월 1일, 16일
        return getDate(date) === 1 || getDate(date) === 16;
      case 3: // 매 년 1월 1일
        return getMonth(date) === 0 && getDate(date) === 1;
      case 4: // 매 주 월요일
        return getDay(date) === 1;
      case 5: // 매 주 화요일
        return getDay(date) === 2;
      case 6: // 매 주 수요일
        return getDay(date) === 3;
      case 7: // 매 주 목요일
        return getDay(date) === 4;
      case 8: // 매 주 금요일
        return getDay(date) === 5;
      case 9: // 매 주 토요일
        return getDay(date) === 6;
      case 10: // 매 주 일요일
        return getDay(date) === 0;
      case 11: // 매 월 1일, 10일, 20일
        return [1, 10, 20].includes(getDate(date));
      default: // 제한 없음
        return 'false';
    }
  };

  const conditionText = (() => {
    switch (Number(selectSet)) {
      case 1:
        return '매 월 1일';
      case 2:
        return '매 월 1일, 16일';
      case 3:
        return '매 년 1월 1일';
      case 4:
        return '매 주 월요일';
      case 5:
        return '매 주 화요일';
      case 6:
        return '매 주 수요일';
      case 7:
        return '매 주 목요일';
      case 8:
        return '매 주 금요일';
      case 9:
        return '매 주 토요일';
      case 10:
        return '매 주 일요일';
      case 11:
        return '매 월 1일, 10일, 20일';
      default:
        return '';
    }
  })();

  const [startDate, setStartDate] = useState(new Date(defaultDate));
  useEffect(() => {
    setStartDate(new Date(defaultDate));
  }, [defaultDate]);
  const initModal = {
    show: false,
  };
  const [modal, setModal] = useState(initModal);
  return (
    <div className={`position-relative ${styles.w_77px} mx-auto`}>
      <DatePicker
        selected={startDate}
        onChange={(date) => {
          setStartDate(date);
          onChange(date, itemIndex, categoryIndex, index);
        }}
        dateFormat="yy-MM-dd"
        dateFormatCalendar={"yyyy년 MMMM"}
        className={`datePicker pe-3 ${styles.date_picker} `}
        locale={ko}
        filterDate={filterDate}
        minDate={new Date()}
        onInputClick={() => {
          if (isMobile) {
            setModal({
              ...modal,
              show: true,
            });
          }
        }}
        readOnly={isMobile || type !== 'self_list'}
        closeOnScroll={true}>
        {selectSet !== '제한 없음' && selectSet !== '0' && <div className="main fs-7px text-end">*{conditionText}</div>}
      </DatePicker>
      {/* <div className={`${styles.custom_date_arrow} position-absolute`}>
        <img src={process.env.PUBLIC_URL + "image/icon/selectArrow.svg"} />
      </div> */}
      <Modal
        show={modal.show}
        size={'media-cart-modal'}
        className={styles.select_modal}
        onHide={() => {
          setModal(initModal);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={true}>
        <Modal.Body className="position-relative p-0">
          <div>
            <div
              className="position-absolute right-0 pe-2 top-0"
              onClick={() => {
                setModal(initModal);
              }}>
              <img src={`${process.env.PUBLIC_URL}image/icon/close_button.svg`} />
            </div>
            <div className="cart_date_picker">
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  onChange(date, itemIndex, categoryIndex, index);
                  setModal(initModal);
                }}
                dateFormatCalendar={"yyyy년 MMMM"}
                locale={ko}
                minDate={new Date()}
                filterDate={filterDate}
                inline
              />

              {selectSet !== '제한 없음' && selectSet !== '0' && (
                <div className="main fs-7px text-end pe-3">*{conditionText}</div>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-center pb-2">
            <div
              className={`${trafficStyles.fs_12px} bd-radius-5px py-1 px-3 bg-dark-gray text-white`}
              onClick={() => {
                setModal(initModal);
              }}>
              취소
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

function AllCheck(props) {
  const { name, onChange, check, label, type } = props;
  return (
    <>
      <input
        type="checkbox"
        className={`${styles.checkbox} d-none`}
        id={name}
        name={name}
        onChange={onChange}
        checked={check}
        disabled={type !== 'self_list'}
      />
      <label htmlFor={name} className="d-flex align-items-center gap-2">
        <span className={`${styles.checkbox_label} d-inline-block`}></span>
        <span>{label}</span>
      </label>
    </>
  );
}

function Checkbox(props) {
  const { name, check, onChange, label, id, categoryId, largeCategoryId, type } = props;

  const navigate = useNavigate();
  const location = useLocation();

  let _URL;

  switch (largeCategoryId) {
    case 1:
      _URL = '/billboard';
      break;
    case 2:
      _URL = '/traffic';
      break;
    case 3:
      _URL = '/shopping';
      break;
    case 4:
      _URL = '/life';
      break;
    case 5:
      _URL = '/sport';
      break;
    case 6:
      _URL = '/etc';
      break;
    default:
      _URL = '';
      break;
  }

  const viewDetail = () => {
    console.log('viewDetail');
    axios
      .get(`${APIURL}category/one/${categoryId}`)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.depth === 3) {
            navigate(`${_URL}Detail?idx=${id}&id=${res.data.id}`, {
              state: { idx: id },
            });
          } else {
            axios
              .get(`${APIURL}category/one/${res.data.parent_id}`)
              .then((res2) => {
                console.log(res2);
                if (res2.status === 200) {
                  // navigate(`${_path}Detail?idx=${id}&id=${res2.data.id}`, {
                  //   state: { idx: id },
                  // });
                }
              })
              .catch((err2) => console.log(err2));
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <input
        type="checkbox"
        className={`${styles.checkbox} d-none`}
        id={name}
        name={name}
        checked={check}
        onChange={onChange}
        disabled={type !== 'self_list'}
      />
      <label htmlFor={name} className="d-flex align-items-center gap-2">
        <span className={`${styles.checkbox_label} d-inline-block`}></span>
      </label>
      <span onClick={viewDetail} className={`fw-800 pointer ${styles.fs_12px} ${styles.hover_underline}`}>
        {label}
      </span>
    </>
  );
}

function Selectbox(props) {
  const { options, setSelectSet, selectSet, name, defaultValue, onChange, value, className, isMobile, type } = props;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#F2F2F2' : state.isFocused ? '#F2F2F2' : 'white',
      color: 'black',
      cursor: 'pointer',
      // width: "300px"
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '100%',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      height: '100%',
    }),
  };
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const initModal = {
    show: false,
    content: [],
  };
  const [modal, setModal] = useState(initModal);

  const handleMenuOpen = () => {
    if (isMobile) {
      setMenuIsOpen(false);
      setModal({
        ...modal,
        show: true,
        content: options,
      });
    } else {
      setMenuIsOpen(true);
    }
  };

  useEffect(() => {
    setMenuIsOpen(false);
  }, [onChange]);

  return (
    <>
      <Select
        name={name}
        className={`${styles.fs_9px} ${className} fw-700`}
        isSearchable={false}
        styles={{
          ...customStyles,
          control: (provide) => ({
            ...provide,
            minHeight: '19px',
            height: '19px',
            cursor: 'pointer',
            border: 'none',
            boxShadow: 'none',
            display: 'flex',
            alignItems: 'center',
          }),
        }}
        options={options}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: 'inherit',
          },
        })}
        menuIsOpen={menuIsOpen}
        onBlur={() => {
          setMenuIsOpen(false);
        }}
        onMenuOpen={type === 'self_list' && handleMenuOpen}
        defaultValue={options.find((item) => item.label === defaultValue)}
        value={options.find((item) => item.label === value)}
        onChange={onChange}
        components={{
          DropdownIndicator: () => (
            <div className={`${styles.custom_select_arrow}`}>
              <img src={process.env.PUBLIC_URL + 'image/icon/selectArrow.svg'} />
            </div>
          ),
        }}
      />
      <Modal
        show={modal.show}
        size={'cart-modal'}
        className={styles.select_modal}
        onHide={() => {
          setModal(initModal);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={true}>
        <Modal.Body>
          <div className="position-relative">
            <div
              className="position-absolute right-0 pe-2 pt-1"
              onClick={() => {
                setModal(initModal);
              }}>
              <img src={`${process.env.PUBLIC_URL}image/icon/close_button.svg`} />
            </div>

            {modal.content?.map((item, index) => (
              <div
                key={`modal_item_${index}`}
                className={styles.modal_list}
                onClick={() => {
                  onChange(item);
                  setModal(initModal);
                }}>
                {item.label}
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

function SelectboxWrapper(props) {
  const { item, setMediaCartData, planId, itemIndex, isMobile, categoryIndex, innerIdx, type, config, mediaCartData } =
    props;

  const [optionDefault, setOptionDefault] = useState({
    period: item.selected_option.selected_period,
    periodIndex: 0,
    amount: item.selected_option.selected_amount,
    amountIndex: 0,
  });

  const selectedOption = item.option_item.find((option) => option.id === item.selected_option.selected_option_item);

  const sortOptionItems = item.option_item.sort((a, b) => a.sort - b.sort);

  const periods = selectedOption ? selectedOption.period.split(',') : optionDefault.period.split(',');
  const amounts = selectedOption ? selectedOption.amount.split(',') : optionDefault.amount.split(',');

  const periodOptions = periods.map((period, index) => ({
    value: index,
    label: period,
  }));

  const amountOptions = amounts.map((amount, index) => ({
    value: index,
    label: amount,
  }));

  const [currentSelect, setCurrentSelect] = useState({
    option: selectedOption ? selectedOption.name : sortOptionItems[0].name,
    period: periodOptions,
    amount: amountOptions,
  });

  const handleOptionChange = (selectedOption) => {
    setMediaCartData((preData) => {
      const updatedData = preData[type].map((dataItem) => {
        if (dataItem.id !== planId) {
          return dataItem;
        }

        const updatedPlanData = dataItem.plan_data.map((listItem, index) => {
          if (index === itemIndex) {
            const updatedCategoryData = listItem.data.map((categoryItem, categoryItemIndex) => {
              if (categoryItemIndex === categoryIndex) {
                const updatedDetailData = categoryItem.data.map((detailItem, detailItemIndex) => {
                  const _selected_option = detailItem.option_item.find((_item) => _item.id === selectedOption.value);
                  const newSelectedValue = selectedOption.value;

                  if (detailItemIndex === innerIdx) {
                    setOptionDefault({
                      period: detailItem.option_item
                        .find((option) => option.id === selectedOption.value)
                        .period.split(',')[0],
                      periodIndex: 0,
                      amount: detailItem.option_item
                        .find((option) => option.id === selectedOption.value)
                        .amount.split(',')[0],
                      amountIndex: 0,
                    });
                    return {
                      ...detailItem,
                      selected_option: {
                        ...detailItem.selected_option,
                        selected_option_item: newSelectedValue,
                        selected_amount: _selected_option?.amount.split(',')[0],
                        selected_install_price: _selected_option?.install_price,
                        selected_limit_Date: _selected_option?.limit_start_date,
                        selected_period: _selected_option?.period.split(',')[0],
                        selected_price: _selected_option?.price,
                      },
                      select_option: _selected_option,
                    };
                  }
                  return detailItem;
                });
                return { ...categoryItem, data: updatedDetailData };
              }
              return categoryItem;
            });
            return { ...listItem, data: updatedCategoryData };
          }
          return listItem;
        });

        if (dataItem.id === planId) {
          const _updated_plan = updatedPlanData.map((large) => ({
            ...large,
            data: large.data.map((category) => ({
              ...category,
              data: category.data.map((item) => {
                const { check, ...rest } = item;
                return rest;
              }),
            })),
          }));

          axios
            .put(`${APIURL}plan/media/${planId}`, { plan_data: JSON.stringify(_updated_plan) }, config)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }

        return { ...dataItem, plan_data: updatedPlanData };
      });
      return { ...preData, [type]: updatedData };
    });

    const newSelectedOption = item.option_item.find((option) => option.id === selectedOption.value);

    const newPeriods = newSelectedOption.period.split(',');
    const newAmounts = newSelectedOption.amount.split(',');

    setCurrentSelect({
      option: selectedOption.label,
      period: newPeriods.map((period, index) => ({
        value: index,
        label: period,
      })),
      amount: newAmounts.map((amount, index) => ({
        value: index,
        label: amount,
      })),
    });
  };

  const handleOption = (selectedOption, target) => {
    setOptionDefault({
      ...optionDefault,
      [target]: selectedOption.label,
      [target + `Index`]: selectedOption.value,
    });

    setMediaCartData((preData) => {
      const updatedData = preData[type].map((dataItem) => {
        if (dataItem.id !== planId) {
          return dataItem;
        }

        const updatedPlanData = dataItem.plan_data.map((listItem, index) => {
          if (index === itemIndex) {
            const updatedCategoryData = listItem.data.map((categoryItem, categoryItemIndex) => {
              if (categoryItemIndex === categoryIndex) {
                const updatedDetailData = categoryItem.data.map((detailItem, detailItemIndex) => {
                  if (detailItemIndex === innerIdx) {
                    const currentOptionItem = detailItem.option_item.find(
                      (optionList) => optionList.name === currentSelect.option,
                    );

                    if (currentOptionItem) {
                      const selectedPrice = parseInt(currentOptionItem.price);
                      const selectedInstallPrice = parseInt(currentOptionItem.install_price);

                      return {
                        ...detailItem,
                        selected_option: {
                          ...detailItem.selected_option,
                          [`selected_${target}`]: selectedOption.label,
                          selected_price: isNaN(selectedPrice)
                            ? '별도안내'
                            : selectedPrice *
                            (target === 'period' ? optionDefault.amountIndex + 1 : optionDefault.periodIndex + 1) *
                            (selectedOption.value + 1),
                          selected_install_price:
                            target === 'amount'
                              ? selectedInstallPrice * (selectedOption.value + 1)
                              : detailItem.selected_option.selected_install_price,
                        },
                      };
                    }
                  }

                  return detailItem;
                });
                return { ...categoryItem, data: updatedDetailData };
              }
              return categoryItem;
            });
            return { ...listItem, data: updatedCategoryData };
          }
          return listItem;
        });
        if (dataItem.id === planId) {
          const _updated_plan = updatedPlanData.map((large) => ({
            ...large,
            data: large.data.map((category) => ({
              ...category,
              data: category.data.map((item) => {
                const { check, ...rest } = item;
                return rest;
              }),
            })),
          }));

          axios
            .put(`${APIURL}plan/media/${planId}`, { plan_data: JSON.stringify(_updated_plan) }, config)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        return { ...dataItem, plan_data: updatedPlanData };
      });
      return { ...preData, [type]: updatedData };
    });
  };

  return (
    <>
      <div className="w-50">
        <Selectbox
          options={sortOptionItems.map((option) => ({
            value: option.id,
            label: option.name,
          }))}
          value={currentSelect.option}
          // defaultValue={currentSelect.option}
          onChange={handleOptionChange}
          isMobile={isMobile}
          type={type}
        />
      </div>
      <div className={`flex-grow-1 d-flex gap-1 ${styles.selected_new_width}`}>
        <Selectbox
          className="w-50"
          options={currentSelect.period}
          value={optionDefault.period}
          onChange={(option) => {
            handleOption(option, 'period');
          }}
          isMobile={isMobile}
          type={type}
        />
        <Selectbox
          className="w-50"
          options={currentSelect.amount}
          value={optionDefault.amount}
          onChange={(option) => {
            handleOption(option, 'amount');
          }}
          isMobile={isMobile}
          type={type}
        />
      </div>
    </>
  );
}

export default MediaCart;
