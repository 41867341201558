import axios from 'axios';
import { APIURL } from './apiDefault';

export const GetBillboard = async (id) => {
  try {
    return await axios.get(`${APIURL}media/billboard/${id}`);
  } catch (error) {
    return console.log(error);
  }
};

export const GetMarker = async (id) => {
  try {
    return await axios.get(`${APIURL}media/category/default/${id}`);
  } catch (error) {
    return console.log(error);
  }
};

export const GetWishList = (accessToken) => {
  return axios.get(`${APIURL}favorite/my/id`, {
    headers: {
      Authorization: accessToken,
    },
  });
};

export const SetWishList = ({ accessToken, mediaId }) => {
  axios
    .post(`${APIURL}user/me`, '', {
      headers: {
        Authorization: accessToken,
      },
    })
    .then((res) => {
      const userInfo = res.data;

      axios
        .post(
          `${APIURL}favorite`,
          {
            user_id: userInfo.id,
            media_id: mediaId,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          },
        )
        .catch((error) => {
          console.log(error);
        });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const DelWishList = ({ accessToken, mediaId }) => {
  axios.delete(`${APIURL}favorite/${mediaId}`, {
    headers: {
      Authorization: accessToken,
    },
  });
};

export const GetPlan = async (accessToken) => {
  try {
    return await axios.get(`${APIURL}plan`, {
      headers: {
        Authorization: accessToken,
      },
    });
  } catch (error) {
    return console.log(error);
  }
};

export const GetCategoryInfo = async ({ categoryId }) => {
  try {
    return await axios.get(`${APIURL}category/one/${categoryId}`);
  } catch (error) {
    return console.log(error);
  }
};
