import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function BasicModal({ children, ...props }) {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header className="fw-800 fs-14px">{props.header}</Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button
          onClick={props.onSubmit}
          style={{
            backgroundColor: "#971b4d",
            borderColor: "#971b4d"
          }}
        >
          확인
        </Button>
        {props.showcancel && (
          <Button variant="secondary" onClick={props.onHide}>
            취소
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default BasicModal;
