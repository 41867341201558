import '../../css/MediaRangeComponent.css';
import moment from 'moment';

export const MediaRangeComponent = ({ ...props }) => {
  const mediaCartData = props.mediaCartData;
  const itemData = props.itemData;
  const _itemStartDate = itemData.selected_option.start_date;
  const rangeData = props.rangeData[mediaCartData.id];

  if (rangeData) {
    const _startDate = moment(rangeData[0]);
    const _endDate = moment(rangeData[1]);

    const selectedPeriod = itemData.selected_option.selected_period;
    const periodTimes = selectedPeriod.replace(/[^\d]/g, '');
    const periodUnit = selectedPeriod.replace(/[\d]/g, '');

    const totalCount = moment.duration(_endDate.diff(_startDate)).asDays();
    const startCount = moment.duration(moment(_itemStartDate).diff(_startDate)).asDays();

    let _rangeDate = moment(_startDate).add(startCount, 'd');
    if (periodUnit !== '회') {
      if (periodUnit === '개월') {
        for (let i = 0; i < periodTimes; i++) {
          const _days = moment(_rangeDate).daysInMonth();
          _rangeDate = moment(_rangeDate).add(_days, 'd');
        }
        _rangeDate = _rangeDate.add(-1, 'd');
      } else if (periodUnit === '주') {
        _rangeDate = moment(_rangeDate)
          .add(periodTimes * 7, 'd')
          .add(-1, 'd');
      } else if (periodUnit === '일') {
        _rangeDate = moment(_rangeDate).add(periodTimes, 'd').add(-1, 'd');
      }
    }

    const endCount = moment.duration(moment(_rangeDate).diff(_startDate)).asDays();
    const monthCount = parseInt(rangeData[1].split('-')[1]) - parseInt(rangeData[0].split('-')[1]);

    let monthStartCount = [];
    for (let i = 0; i < Math.abs(monthCount); i++) {
      const _targetDate = moment(_startDate)
        .add(i + 1, 'M')
        .set('date', 1)
        .format('YYYY-MM-DD');
      // console.log(_targetDate);
      monthStartCount.push(moment.duration(moment(_targetDate).diff(_startDate)).asDays());
    }

    let _dateText = '';
    const rangeDaysComponent = () => {
      let _component = [];

      for (let i = 0; i <= totalCount; i++) {
        let _start = false;
        if (i === startCount) {
          _start = true;
        }

        let _end = false;
        if (i === endCount) {
          _end = true;
        }

        let _active = '';
        if (i >= startCount && i <= endCount) {
          _active = ' active';
        }

        let _border = '';
        if (monthStartCount.includes(i)) {
          _border = ' startMonth';
        }

        const _sdList = _itemStartDate.split('-');
        const _sd = `${_sdList[1]}/${_sdList[2]}`;
        const _edList = _rangeDate.format('YYYY-MM-DD').split('-');
        const _ed = `${_edList[1]}/${_edList[2]}`;
        _component.push(
          <div key={'rangeDaysComponent_' + i} className={'rangeStack' + _active + _border}>
            {_start && <div className="startDate">{_sd}</div>}
            {_end && <div className="endDate">{_ed}</div>}
          </div>,
        );
        _dateText = (
          <span>
            {_sd} ~ {_ed}
          </span>
        );
      }

      return _component;
    };

    return (
      <>
        <div className="rangeOuter">{rangeDaysComponent()}</div>
        <span className="d-none">{_dateText}</span>
      </>
    );
  } else {
    return <div></div>;
  }
};

//시작-종료일 text
export const RangeText = ({ ...props }) => {
  const mediaCartData = props.mediaCartData;
  const itemData = props.itemData;
  const _itemStartDate = itemData.selected_option.start_date;
  const rangeData = props.rangeData[mediaCartData.id];

  if (rangeData) {
    const _startDate = moment(rangeData[0]);
    const _endDate = moment(rangeData[1]);

    const selectedPeriod = itemData.selected_option.selected_period;
    const periodTimes = selectedPeriod.replace(/[^\d]/g, '');
    const periodUnit = selectedPeriod.replace(/[\d]/g, '');

    const totalCount = moment.duration(_endDate.diff(_startDate)).asDays();
    const startCount = moment.duration(moment(_itemStartDate).diff(_startDate)).asDays();

    let _rangeDate = _startDate;
    if (periodUnit !== '회') {
      if (periodUnit === '개월') {
        _rangeDate = moment(_rangeDate)
          .add(periodTimes, 'M')
          .add(startCount - 1, 'd');
      } else if (periodUnit === '주') {
        _rangeDate = moment(_rangeDate)
          .add(periodTimes * 7, 'd')
          .add(startCount - 1, 'd');
      } else if (periodUnit === '일') {
        _rangeDate = moment(_rangeDate)
          .add(periodTimes, 'd')
          .add(startCount - 1, 'd');
      }
    }

    let _dateText = '';
    const rangeDaysComponent = () => {
      let _component = [];

      for (let i = 0; i <= totalCount; i++) {
        const _sdList = _itemStartDate.split('-');
        const _sd = `${_sdList[1]}/${_sdList[2]}`;
        const _edList = _rangeDate.format('YYYY-MM-DD').split('-');
        const _ed = `${_edList[1]}/${_edList[2]}`;

        _dateText = (
          <>
            {_sd} ~ {_ed}
          </>
        );
      }

      return _component;
    };

    return (
      <span>
        {rangeDaysComponent()}
        {_dateText}
      </span>
    );
  } else {
    return <div></div>;
  }
};
