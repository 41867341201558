import React, { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import axios from 'axios';
import './mypage.css';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { GrClose } from 'react-icons/gr';
import { Link, useNavigate } from 'react-router-dom';
import { getCookieToken } from '../../storage/Cookie'; //쿠키 가져오기
import { GetAccessToken } from '../../api/GetToken';
import Swal from 'sweetalert2';
import { useMediaQuery } from 'react-responsive';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';

import { DELETE_TOKEN } from '../../store/Auth';
import { removeCookieToken } from '../../storage/Cookie';
import { APIURL, DEFAULTURL } from '../../api/apiDefault';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import '../login/font.css';
import '../login/signup.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Mypage = ({ ...props }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { number, setNumber } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // 새문의
  const accessToken = GetAccessToken();
  const [newModal, setNewModal] = useState(false);
  const [newText, setNewText] = useState({
    title: '',
    content: '',
  });
  const newTextChange = (e) => {
    setNewText({ ...newText, [e.target.name]: e.target.value });
  };
  const newReset = () => {
    setNewModal(false);
    setNewText({ title: '', content: '' });
    setNewFiles([]);
  };
  const newSubmit = () => {};
  const [newFiles, setNewFiles] = useState([]);
  const newFile = (e) => {
    console.log(e.target.files[0]);
    if (e.target.files[0].size > 30000000) {
      Swal.fire({
        icon: 'error',
        iconColor: '#971b4d',
        text: `첨부파일은 30MB 이하까지 가능합니다.`,
        confirmButtonText: '확인',
        confirmButtonColor: '#971b4d',
      });
      return;
    }
    // if (newFiles.length > 0) {
    //   setNewFiles([...newFiles, ...e.target.files]);
    // } else {
    //   setNewFiles(Array.from(e.target.files));
    // }
    if (newFiles.length > 0) {
      setNewFiles([...newFiles, e.target.files[0]]);
    } else {
      setNewFiles([e.target.files[0]]);
    }
    // e.target.value = '';
  };

  const removeFile = (_name) => {
    const updatedFiles = newFiles.filter((file) => file.name !== _name);
    setNewFiles(updatedFiles);
  };

  // 문의보기
  const [viewModal, setViewModal] = useState(false);
  const viewReset = () => {
    setViewModal(false);
  };
  const [viewContent, setViewContent] = useState();
  const handleView = (_item) => {
    setViewModal(true);
    setViewContent(_item);
  };

  const [myInfo, setMyInfo] = useState({});
  const [reservation, setReservation] = useState([]);
  const [plan, setPlan] = useState([]);
  const mypage_scroll = useRef(null);
  useEffect(() => {
    if (!accessToken) {
      navigate('/');
      return;
    }

    const config = {
      headers: {
        Authorization: accessToken,
      },
    };
    axios
      .post(`${APIURL}user/me`, '', config)
      .then((res) => {
        if (res.status === 200) {
          setMyInfo(res.data);
          setEditData(res.data);
          setTerms(res.data.agree_email === 0 ? false : true);
        }
      })
      .catch((err) => console.log(err));

    axios
      .get(`${APIURL}reservation/`, config)
      .then((res) => {
        if (res.status === 200) {
          setReservation(res.data);
        }
      })
      .catch((err) => console.log(err));

    axios
      .get(`${APIURL}plan/`, config)
      .then((res) => {
        if (res.status === 200) {
          const selfList = res.data.self_list;
          const newSelfList = selfList.map((item) => ({
            ...item,
            label: item.name,
            value: item.id,
          }));
          const reqList = res.data.req_list;
          const newReqList = reqList.map((item) => ({
            ...item,
            label: item.name,
            value: item.id,
          }));
          const rialtoList = res.data.rialto_list;
          const newRialtoList = rialtoList.map((item) => ({
            ...item,
            label: item.name,
            value: item.id,
          }));
          // const newPlan = res.data.map((item) => ({
          //   ...item,
          //   label: item.name,
          //   value: item.id,
          // }));
          setPlan([...newSelfList, ...newReqList, ...newRialtoList]);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    scrollToBottom();
    console.log(reservation);
  }, [reservation]);

  // 로그아웃
  const logout = () => {
    Swal.fire({
      icon: 'question',
      iconColor: '#971b4d',
      text: '로그아웃 하시겠습니까?',
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      showCancelButton: true,
      confirmButtonColor: '#971b4d',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DELETE_TOKEN());
        removeCookieToken();

        localStorage.removeItem('showData');
        navigate('/');
      }
    });
  };

  // 문의하기
  const handleInquiry = async () => {
    if (!newText.title || !newText.content) {
      Swal.fire({
        icon: 'info',
        iconColor: '#971b4d',
        text: '새 문의하기 정보를 모두 입력해 주세요.',
        confirmButtonText: '확인',
        confirmButtonColor: '#971b4d',
      });
      return;
    }

    // 파일 업로드
    const uploadFiles = () => {
      if (newFiles.length === 0) {
        return Promise.resolve([]); // 파일이 없을 경우 빈 배열 반환
      }

      const formData = new FormData();
      newFiles.forEach((file) => {
        formData.append('files', file);
      });

      return axios
        .post(`${APIURL}upload/origin`, formData)
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          } else {
            return [];
          }
        })
        .catch((err) => {
          console.log(err);
          return [];
        });
    };

    try {
      const uploadedFiles = await uploadFiles();
      // plan 데이터 가져오기
      const newPlan = planChange.map((item) => item.id);
      const planDatas = [];

      for (const item of newPlan) {
        const plan = await axios.get(`${APIURL}plan/${item}`);
        if (plan.status === 200) {
          planDatas.push(plan.data);
        }
      }

      for (const copy of planDatas) {
        if (copy.plan_type !== 'REQ') {
          const copyData = {
            user_id: myInfo.id,
            plan_type: 'REQ',
            name: `${copy.name}(요청됨)`,
            plan_data: JSON.stringify(copy.plan_data),
            status: 0,
            // is_show:1,
            is_read: 0,
          };
          const copyPost = await axios.post(`${APIURL}plan`, copyData, {
            headers: {
              Authorization: accessToken,
            },
          });
          if (copyPost.status === 200) {
            planDatas.push(copyPost.data);
          }
        }
      }

      const reqPlanDatas = planDatas.filter((item) => item.plan_type === 'REQ');
      const reqPlanIds = reqPlanDatas.map((item) => {
        return item.id;
      });

      const _data = {
        user_id: myInfo.id,
        parent_id: 0,
        title: newText.title,
        content: newText.content,
        parent_name: '',
        type: 'INQUIRY',
        files: JSON.stringify(uploadedFiles), // 업로드된 파일 목록
        status: 0,
        plan_ids: reqPlanIds.join(),
        reserve_data: JSON.stringify(reqPlanDatas),
      };

      const res = await axios.post(`${APIURL}reservation`, _data, {
        headers: {
          Authorization: accessToken,
        },
      });

      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          iconColor: '#971b4d',
          text: '새 문의가 작성되었습니다.',
          confirmButtonText: '확인',
          confirmButtonColor: '#971b4d',
        }).then(() => {
          newReset();
          const newRes = res.data;

          if (planChange.length > 0) {
            const namesArray = reqPlanDatas.map((item) => item.name);
            newRes.plan_list = namesArray;
          }
          setReservation([...reservation, newRes]);
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  // const handleInquiry = () => {
  //   if (!newText.title || !newText.content) {
  //     Swal.fire({
  //       icon: 'info',
  //       iconColor: '#971b4d',
  //       text: '새 문의하기 정보를 모두 입력해 주세요.',
  //       confirmButtonText: '확인',
  //       confirmButtonColor: '#971b4d',
  //     });
  //     return;
  //   }

  //   // 파일 업로드
  //   const uploadFiles = () => {
  //     if (newFiles.length === 0) {
  //       return Promise.resolve([]); // 파일이 없을 경우 빈 배열 반환
  //     }

  //     const formData = new FormData();
  //     newFiles.forEach((file) => {
  //       formData.append('files', file);
  //     });

  //     return axios
  //       .post(`${APIURL}upload/origin`, formData)
  //       .then((res) => {
  //         if (res.status === 200) {
  //           return res.data;
  //         } else {
  //           return [];
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         return [];
  //       });
  //   };

  //   // 파일 업로드 후 예약 데이터 전송
  //   uploadFiles()
  //     .then((uploadedFiles) => {

  //       const newPlan = planChange.map((item) => item.id);
  //       console.log(newPlan)
  //       const planDatas = []
  //       if (newPlan.length > 0) {
  //         newPlan.map((item) => {
  //           axios.get(`${APIURL}plan/${item}`)
  //             .then((plan) => {
  //               console.log(plan)
  //               if (plan.status === 200) {
  //                 planDatas.push(plan.data)
  //               }
  //             })
  //             .catch((planErr) => console.log(planErr))
  //         })
  //       }

  //       console.log(planDatas)
  //       const _data = {
  //         user_id: myInfo.id,
  //         parent_id: 0,
  //         title: newText.title,
  //         content: newText.content,
  //         parent_name: '',
  //         type: 'INQUIRY',
  //         files: JSON.stringify(uploadedFiles), // 업로드된 파일 목록
  //         status: 0,
  //         plan_ids: newPlan.join(),
  //         // reserve_data: []
  //       };

  //       return axios.post(`${APIURL}reservation`, _data, {
  //         headers: {
  //           Authorization: accessToken,
  //         },
  //       });
  //     })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         Swal.fire({
  //           icon: 'success',
  //           iconColor: '#971b4d',
  //           text: '새 문의가 작성되었습니다.',
  //           confirmButtonText: '확인',
  //           confirmButtonColor: '#971b4d',
  //         }).then(() => {
  //           newReset();
  //           // setNumber({ ...number, mypage: number.mypage + 1 });
  //           console.log(res);
  //           const newRes = res.data;
  //           console.log(planChange);
  //           if (planChange.length > 0) {
  //             const namesArray = planChange.map((item) => item.name);
  //             newRes.plan_list = namesArray;
  //           }
  //           setReservation([...reservation, newRes]);
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const [planChange, setPlanChange] = useState([]);
  const handlePlan = (e) => {
    setPlanChange(e);
  };

  // 회원정보수정
  const [editModal, setEditModal] = useState(false);
  const editReset = () => {
    setEditModal(false);
  };
  const [editData, setEditData] = useState();

  /** 연락처 정규식 */
  const formatPhoneNumber = (value) => {
    // 숫자만 추출
    const numericValue = value.replace(/\D/g, '');

    return numericValue;
  };

  const fileDown = async (_file, _origin) => {
    const newTab = window.open('about:blank', '_blank');

    const res = await axios.get(`${APIURL}download/${_file}?origin_name=${_origin}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (res.status === 200) {
      // let contentType = res.headers['content-type'];
      const contentType = res.data.type;
      const splitContentType = contentType.split('/');

      const blob = new Blob([res.data], { type: contentType });
      const fileUrl = window.URL.createObjectURL(blob);

      if (splitContentType[0] === 'image' || splitContentType[1] === 'pdf') {
        newTab.location.href = fileUrl;
      } else {
        newTab.close();
        const link = document.createElement('a');
        link.href = fileUrl;
        link.style.display = 'none';
        link.download = _origin;
        link.target = '_blank';

        document.body.appendChild(link);
        link.click();
        link.remove();
      }

      window.URL.revokeObjectURL(fileUrl);
    }
  };

  const scrollToBottom = () => {
    const mypage_height = document.querySelector('#mypage_height');
    mypage_scroll.current.scrollTo({
      top: mypage_height ? mypage_height.scrollHeight + 10000 : 0,
      behavior: 'smooth',
    });
  };

  const [list, setList] = useState([]);
  const listAdd = (item) => {
    if (item.parent_user_id !== 0 && item.is_read !== 1) {
      setNumber({ ...number, mypage: number.mypage - 1 });
      axios
        .put(`${APIURL}reservation/read/${item.id}`, '', {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            // myList와 같은 id를 가진 객체를 찾아 is_read를 1로 변경
            const updatedList = reservation.map((_item) => {
              if (_item.id === item.id) {
                return { ..._item, is_read: 1 };
              }
              if (_item.childs && _item.childs.length > 0) {
                const updatedChilds = _item.childs.map((child) => {
                  if (child.id === item.id) {
                    return { ...child, is_read: 1 };
                  }
                  return child;
                });
                return { ..._item, childs: updatedChilds };
              }
              return _item;
            });

            setReservation(updatedList);
          }
        })
        .catch((err) => console.log(err));
    }
    setList([item]);
  };

  useEffect(() => {
    const mypageSubContainer = document.querySelector('.mypage-sub-container');
    const myListContainer = document.querySelector('.my-list-container');
    mypageSubContainer.scrollTo({
      top: myListContainer ? myListContainer.offsetHeight + 1000 : 0,
      behavior: 'smooth',
    });
  }, [list]);
  const listFilter = (item) => {
    const newList = list.filter((_item) => _item.id !== item.id);
    setList(newList);
  };

  // 회원정보수정
  const [pw, setPw] = useState({
    password: '',
    re_password: '',
  });
  const pwChange = (e) => {
    setPw({
      ...pw,
      [e.target.name]: e.target.value,
    });
  };
  const [alert, setAlert] = useState({
    password: false,
    re_password: false,
    name: false,
    mobile: false,
    reMobile: false,
    // favor: false,
  });
  const handleInfo = (e) => {
    setEditData({
      ...editData,
      [e.target.name]: e.target.name === 'mobile' ? formatPhoneNumber(e.target.value) : e.target.value,
    });
  };

  // 연락처 정규식2
  const formatPhoneNumber2 = () => {
    var regPhone = /^\d{2,3}-?\d{3,4}-?\d{4}$/;
    return regPhone.test(editData.mobile);
  };
  const handleSignup = (e) => {
    const { name, mobile } = editData;
    const { password, re_password } = pw;
    let newAlert = {
      password: false,
      re_password: false,
      name: false,
      mobile: false,
      reMobile: false,
    };

    if (password || re_password) {
      if (password.length > 20 || password.length < 8) {
        newAlert.password = true;
      } else {
        newAlert.password = false;
      }

      password !== re_password ? (newAlert.re_password = true) : (newAlert.re_password = false);
    }

    !name ? (newAlert.name = true) : (newAlert.name = false);
    !mobile ? (newAlert.mobile = true) : (newAlert.mobile = false);

    !formatPhoneNumber2() ? (newAlert.reMobile = true) : (newAlert.reMobile = false);

    setAlert(newAlert);
    const isAllTrue = Object.values(newAlert).every((value) => value === false);

    if (isAllTrue) {
      //회원정보수정 submit
      const _data = {
        email: editData.email,
        name: editData.name,
        mobile: editData.mobile,
        company: editData.company,
        division: editData.division,
        preference: editData.preference,
      };
      axios
        .put(`${APIURL}user/${myInfo.id}`, _data, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (password) {
              const _password = { password: password };
              axios
                .put(`${APIURL}user/password/${myInfo.id}`, _password, {
                  headers: {
                    Authorization: accessToken,
                  },
                })
                .then((res2) => {
                  if (res2.status === 200) {
                    axios
                      .put(`${APIURL}user/email/${myInfo.id}?is_agree=${terms ? 1 : 0}`, '', {
                        headers: {
                          Authorization: accessToken,
                        },
                      })
                      .then((res3) => {
                        if (res3.status === 200) editSuccess(res);
                      })
                      .catch((err3) => console.log(err3));
                  }
                })
                .catch((err2) => console.log(err2));
            } else {
              axios
                .put(`${APIURL}user/email/${myInfo.id}?is_agree=${terms ? 1 : 0}`, '', {
                  headers: {
                    Authorization: accessToken,
                  },
                })
                .then((res2) => {
                  if (res2.status === 200) editSuccess(res);
                })
                .catch((err2) => console.log(err2));
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const [terms, setTerms] = useState(false);
  const handleTerms = (e) => {
    setTerms(e.target.checked);
  };

  const editSuccess = (_res) => {
    Swal.fire({
      icon: 'success',
      iconColor: '#971b4d',
      text: '수정되었습니다.',
      confirmButtonText: '확인',
      confirmButtonColor: '#971b4d',
    }).then(() => {
      setMyInfo(_res.data);
      setEditData(_res.data);

      editReset();
    });
  };

  const [showPassword, setShowPassword] = useState({
    pass1: false,
    pass2: false,
  });
  const handlePasswordToggle = (_v) => {
    setShowPassword({
      ...showPassword,
      [_v]: !showPassword[_v],
    });
  };

  const handleSignReset = () => {
    setEditModal(false);
    setAlert({
      password: false,
      re_password: false,
      nickname: false,
      tel: false,
    });
    setEditData(myInfo);
  };

  const quillRef = useRef();
  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.addEventListener('change', async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('files', file);

      try {
        axios
          .post(`${APIURL}upload/origin`, formData, {
            headers: {
              Authorization: accessToken,
            },
          })
          .then((result) => {
            const IMG_URL = result.data[0].upload;

            const editor = quillRef.current.getEditor();
            const range = editor.getSelection();
            editor.insertEmbed(range.index, 'image', `${DEFAULTURL}image/${IMG_URL}`);
          });
      } catch (error) {
        console.log(error);
      }
    });
  };
  const quillModules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['image'],
          ['clean'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    [],
  );

  const myQuillChange = (e) => {
    setNewText({ ...newText, content: e });
  };

  // 회원탈퇴
  const handleOut = () => {
    const config = {
      headers: {
        Authorization: accessToken,
      },
    };
    Swal.fire({
      icon: 'error',
      iconColor: '#971b4d',
      text: '정말 탈퇴하시겠습니까?',
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      showCancelButton: true,
      confirmButtonColor: '#971b4d',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${APIURL}user`, config)
          .then((res) => {
            if (res.status === 200) {
              console.log(res);
              Swal.fire({
                icon: 'success',
                iconColor: '#971b4d',
                text: '탈퇴되었습니다.',
                confirmButtonText: '확인',
                confirmButtonColor: '#971b4d',
              }).then(() => {
                dispatch(DELETE_TOKEN());
                removeCookieToken();

                navigate('/');
              });
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  return (
    <>
      <div className="my-container bg-white">
        <div className="bg-white mypage-container ">
          <div className="scroll mypage-sub-container">
            <div className="bg-white h-100 mypage-content-box w-100">
              <div className="d-flex justify-content-between align-items-center mypage-title-content">
                <div className="d-flex align-items-center">
                  <img src={`${process.env.PUBLIC_URL}/image/icon/cart.svg`} className="mypage-title-icon" alt="" />
                  <div className="fw-800 mypage-title">마이 페이지</div>
                </div>
                <div className="d-flex">
                  <div
                    onClick={() => setEditModal(true)}
                    className="pointer logout-btn text-white fw-700 fs-10px py-1 px-2 me-2">
                    회원정보수정
                  </div>
                  <div
                    className="pointer logout-btn text-white fw-700 fs-10px py-1 px-2 my-logout-btn text-center"
                    onClick={logout}>
                    로그아웃
                  </div>
                </div>
              </div>

              <div>
                <div className="fw-800 mypage-title-text main mb-2">나의 문의 내역</div>
                <div className="mypage-inquiry-box p-3 scroll" ref={mypage_scroll}>
                  {reservation.length > 0 && (
                    <div className="mb-2 mypage-inquiry-content-box" id="mypage_height">
                      {reservation.map((item) => (
                        <div key={item.id} className="mypage-inquiry-content-box">
                          {item.parent_user_id === 0 && (
                            <>
                              <div className="d-flex align-items-end my-user-box">
                                <div className="d-flex align-items-center">
                                  <div className="fw-800 fs-14px">나</div>
                                  <div
                                    className="ms-2 mypage-inquiry-content px-3 py-2 pointer"
                                    onClick={() => listAdd(item)}>
                                    <div className="d-flex flex-column flex-sm-row align-items-sm-start">
                                      <div className="fw-800 fs-12px me-2 text-nowrap">제목</div>
                                      <div className="fs-11px my-text-margin-top">{item.title}</div>
                                    </div>
                                    {item.type === 'RESERVE' && item.reserve_data.length > 0 && (
                                      <div className="d-flex flex-column flex-sm-row align-items-sm-center">
                                        <div className="fw-800 fs-12px me-2">플랜</div>
                                        <div
                                          className={`fs-11px ${
                                            item.reserve_data[0]?.plan_type === 'REQ'
                                              ? 'my-fc-ABABAB'
                                              : item.reserve_data[0]?.plan_type === 'SELF'
                                              ? ''
                                              : 'main'
                                          }`}>
                                          {item.reserve_data[0].name}
                                        </div>
                                      </div>
                                    )}
                                    {item.type === 'INQUIRY' && item.plan_list && item.plan_list.length > 0 && (
                                      <div className="d-flex flex-column flex-sm-row align-items-sm-center">
                                        <div className="fw-800 fs-12px me-2">플랜</div>
                                        <div className="d-flex flex-wrap" style={{ gap: '0 5px' }}>
                                          {item.plan_list.map((plan, index) => (
                                            <div
                                              className={`fs-11px ${
                                                item.reserve_data[0]?.plan_type === 'REQ'
                                                  ? 'my-fc-ABABAB'
                                                  : item.reserve_data[0]?.plan_type === 'SELF'
                                                  ? ''
                                                  : 'main'
                                              }`}
                                              // className={`fs-11px`}
                                              key={'plan_' + index}>
                                              {plan}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )}

                                    <div className="fs-7px">{item.created_at.replace('T', ' ')}</div>
                                  </div>
                                </div>
                                <div className="main fs-9px ms-2">{item.is_read === 0 && <div>1</div>}</div>
                              </div>
                              {item.childs &&
                                item.childs.length > 0 &&
                                item.childs.map((child) => (
                                  <div
                                    key={'child' + child.id}
                                    className="d-flex justify-content-end align-items-end parent-user-box">
                                    <div className="main fs-9px me-2">{child.is_read === 0 && <div>1</div>}</div>
                                    <div className="d-flex align-items-center">
                                      <div
                                        className="me-2 mypage-inquiry-content px-3 py-2 pointer"
                                        onClick={() => listAdd(child)}>
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-start">
                                          <div className="fw-800 fs-12px me-2 text-nowrap">제목</div>
                                          <div className="fs-11px my-text-margin-top">{child.title}</div>
                                        </div>
                                        {/* <div className="d-flex flex-column flex-sm-row align-items-sm-center">
        <div className="fw-800 fs-14px me-2 text-nowrap">첨부된 플랜</div>
        <div className="fs-12px">2023 겨울신상품 부킹요청(6/7리알토 조정)</div>
      </div> */}
                                        {child.type === 'RESERVE' && child.reserve_data.length > 0 && (
                                          <div className="d-flex flex-column flex-sm-row align-items-sm-start">
                                            <div className="fw-800 fs-12px me-2">플랜</div>
                                            <div
                                              className={`fs-11px ${
                                                child.reserve_data[0]?.plan_type === 'REQ'
                                                  ? 'my-fc-ABABAB'
                                                  : child.reserve_data[0]?.plan_type === 'SELF'
                                                  ? 'text-black'
                                                  : 'main'
                                              }`}>
                                              {child.reserve_data[0].name}
                                            </div>
                                          </div>
                                        )}
                                        {child.type === 'INQUIRY' && child.plan_list && child.plan_list.length > 0 && (
                                          <div className="d-flex flex-column flex-sm-row align-items-sm-start">
                                            <div className="fw-800 fs-12px me-2">플랜</div>
                                            <div className="d-flex flex-wrap" style={{ gap: '0 5px' }}>
                                              {child.plan_list.map((plan, index) => (
                                                <div
                                                  className={`fs-11px ${
                                                    child.reserve_data[index]?.plan_type === 'REQ'
                                                      ? 'my-fc-ABABAB'
                                                      : child.reserve_data[index]?.plan_type === 'SELF'
                                                      ? ''
                                                      : 'main'
                                                  }`}
                                                  key={'plan_' + index}>
                                                  {plan}
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        )}
                                        <div className="fs-7px">{child.created_at.replace('T', ' ')}</div>
                                      </div>
                                      <img
                                        src={`${process.env.PUBLIC_URL}/image/icon/${
                                          isMobile ? 'logo_r_main.svg' : 'logo_main.svg'
                                        }`}
                                        style={{
                                          width: isMobile ? '13px' : '50px',
                                        }}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                ))}
                            </>
                          )}
                          {item.parent_user_id !== 0 && item.parent_id === 0 && (
                            <div className="d-flex justify-content-end align-items-end parent-user-box">
                              <div className="main fs-9px me-2">{item.is_read === 0 && <div>1</div>}</div>
                              <div className="d-flex align-items-center">
                                <div
                                  className="me-2 mypage-inquiry-content px-3 py-2 pointer"
                                  onClick={() => listAdd(item)}>
                                  <div className="d-flex flex-column flex-sm-row align-items-sm-start">
                                    <div className="fw-800 fs-12px me-2 text-nowrap">제목</div>
                                    <div className="fs-11px my-text-margin-top">{item.title}</div>
                                  </div>
                                  {item.type === 'RESERVE' && item.reserve_data.length > 0 && (
                                    <div className="d-flex flex-column flex-sm-row align-items-sm-start">
                                      <div className="fw-800 fs-12px me-2">플랜</div>
                                      <div
                                        className={`fs-11px ${
                                          item.reserve_data[0]?.plan_type === 'REQ'
                                            ? 'my-fc-ABABAB'
                                            : item.reserve_data[0]?.plan_type === 'SELF'
                                            ? ''
                                            : 'main'
                                        }`}>
                                        {item.reserve_data[0].name}
                                      </div>
                                    </div>
                                  )}
                                  {item.type === 'INQUIRY' && item.plan_list && item.plan_list.length > 0 && (
                                    <div className="d-flex flex-column flex-sm-row align-items-sm-start">
                                      <div className="fw-800 fs-12px me-2">플랜</div>
                                      <div className="d-flex flex-wrap" style={{ gap: '0 5px' }}>
                                        {item.plan_list.map((plan, index) => (
                                          <div
                                            className={`fs-11px ${
                                              item.reserve_data[0]?.plan_type === 'REQ'
                                                ? 'my-fc-ABABAB'
                                                : item.reserve_data[0]?.plan_type === 'SELF'
                                                ? ''
                                                : 'main'
                                            }`}
                                            key={'plan_' + index}>
                                            {plan}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                  <div className="fs-7px">{item.created_at.replace('T', ' ')}</div>
                                </div>
                                <img
                                  src={`${process.env.PUBLIC_URL}/image/icon/${
                                    isMobile ? 'logo_r_main.svg' : 'logo_main.svg'
                                  }`}
                                  style={{
                                    width: isMobile ? '13px' : '50px',
                                  }}
                                  alt=""
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                      {/* <div className="d-flex justify-content-end align-items-center">
                        <div className="me-3 mypage-inquiry-content px-3 py-2 pointer" onClick={() => setViewModal(true)}>
                          <div className="d-flex flex-column flex-sm-row align-items-sm-center">
                            <div className="fw-800 fs-14px me-2 text-nowrap">제목</div>
                            <div className="fs-12px">일부 매체 조정된 플랜 전달 드립니다.</div>
                          </div>
                          <div className="d-flex flex-column flex-sm-row align-items-sm-center">
                            <div className="fw-800 fs-14px me-2 text-nowrap">첨부된 플랜</div>
                            <div className="fs-12px">2023 겨울신상품 부킹요청(6/7리알토 조정)</div>
                          </div>
                          <div className="fs-10px">2023-06-07 16:33</div>
                        </div>
                        <img src={`${process.env.PUBLIC_URL}/image/icon/logo_main.svg`} style={{ width: "50px" }} />
                      </div> */}
                    </div>
                  )}

                  <div className="d-flex align-items-center">
                    <div className="fw-800 fs-14px" style={{ color: '#efefef' }}>
                      나
                    </div>
                    <div className="fs-12px fw-800 ms-2 new-inquiry-btn pointer " onClick={() => setNewModal(true)}>
                      + 새 문의
                    </div>
                  </div>
                </div>
              </div>

              {newModal && (
                <div className="mt-4">
                  <div className="d-flex justify-content-between">
                    <div className="mypage-title-text fw-800 main mb-2">새 문의하기</div>
                    <div className="pointer" onClick={() => newReset()}>
                      <GrClose />
                    </div>
                  </div>
                  <div className="new-inquiry-box p-3 mb-2 ">
                    <div className="mb-3">
                      <div className="fw-800 fs-12px mb-1">
                        제목 <span className="main">*</span>
                      </div>
                      <div>
                        <input
                          type="text"
                          name="title"
                          value={newText.title}
                          onChange={newTextChange}
                          className="new-inquiry-input fs-11px"
                          placeholder="2023 겨울 신상품 플랜 부킹 요청"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="fw-800 fs-12px mb-1">
                        세부사항 <span className="main">*</span>
                      </div>
                      <div>
                        <textarea
                          type="text"
                          name="content"
                          value={newText.content}
                          onChange={newTextChange}
                          className="new-inquiry-input new-inquiry-textarea scroll fs-11px"
                          placeholder="캠페인의 특성 : 2023 겨울신상품 홍보용으로 20~39 여성 타겟 캠페인을 준비하고 있습니다.&#13;&#10;캠페인 기간과 옥외광고 예산 : 9.10.11월 3개월 총 예산 00억정도이며&#13;&#10;선호하는 매체 : 홍대, 강남 전광판들과 복합쇼핑몰 위주로 플랜 부탁드려요.&#13;&#10;매진된 매체는 같은 기간 유사한 매체로 추천해주세요.&#13;&#10;기타 자유롭게 문의사항을 남겨주세요."
                        />
                        {/* <ReactQuill ref={quillRef}
                          modules={quillModules} placeholder="캠페인의 특성 : 2023 겨울신상품 홍보용으로 20~39 여성 타겟 캠페인을 준비하고 있습니다.&#13;&#10;캠페인 기간과 옥외광고 예산 : 9.10.11월 3개월 총 예산 00억정도이며&#13;&#10;선호하는 매체 : 홍대, 강남 전광판들과 복합쇼핑몰 위주로 플랜 부탁드려요.&#13;&#10;매진된 매체는 같은 기간 유사한 매체로 추천해주세요.&#13;&#10;기타 자유롭게 문의사항을 남겨주세요."
                          className="mediaQuill" onChange={myQuillChange} /> */}
                      </div>
                    </div>
                  </div>
                  <div className="new-inquiry-box p-3">
                    <div className="fw-800 fs-12px mb-2">플랜 첨부하기</div>
                    <div>
                      <Select
                        isMulti
                        name="colors"
                        options={plan}
                        className="basic-multi-select fs-11px"
                        classNamePrefix="select"
                        placeholder="선택해 주세요"
                        onChange={handlePlan}
                        noOptionsMessage={() => '생성된 플랜이 없습니다.'}
                      />
                    </div>
                  </div>
                  <div className="py-2 mypage-file-box d-flex flex-wrap align-items-center">
                    <div
                      className="new-inquiry-submit bg-main text-white pointer py-2 fs-11px my-same-btn"
                      onClick={handleInquiry}>
                      문의하기
                    </div>
                    <div>
                      <input type="file" onChange={newFile} className="d-none" id="planFile" />
                      <label
                        htmlFor="planFile"
                        className="new-inquiry-submit bg-sub text-black pointer py-2 fs-11px my-same-btn">
                        파일 첨부하기
                      </label>
                    </div>
                    {newFiles.length > 0 && (
                      <div className="d-flex flex-wrap" style={{ gap: '0 1.25rem' }}>
                        {newFiles.map((item, index) => (
                          <div key={index} className="d-flex align-items-center">
                            <div className="fs-9px fw-700 me-2">{item.name}</div>
                            <GrClose size="10" className="pointer" onClick={() => removeFile(item.name)} />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}

              {list.length > 0 &&
                list.map((item) => (
                  <div className="mt-4 my-list-container" key={item.id}>
                    <div className="d-flex justify-content-between">
                      <div className="mypage-title-text fw-800 main mb-2">내용 확인하기</div>
                      <div className="pointer" onClick={() => listFilter(item)}>
                        <GrClose />
                      </div>
                    </div>
                    <div className="new-inquiry-box p-3 mb-2">
                      <div className="mb-3">
                        <div className="fw-800 fs-12px mb-1">제목</div>
                        <div className="fs-11px text-black">{item.title}</div>
                      </div>
                      <div>
                        <div className="fw-800 fs-12px">세부사항</div>
                        <ReactQuill
                          style={{
                            whiteSpace: 'pre-wrap',
                            overflowY: 'auto',
                            maxHeight: '350px',
                            resize: 'none',
                          }}
                          className="fs-11px text-black scroll mypage-view-quill"
                          value={item.content}
                          readOnly
                        />
                      </div>
                    </div>
                    {item.type === 'RESERVE' && item.reserve_data.length > 0 && (
                      <div className="new-inquiry-box p-3 mt-1">
                        <div className="fw-800 fs-12px mb-2">첨부된 플랜</div>
                        {item.reserve_data.map((res) => (
                          <div
                            className={`fs-11px pointer ${
                              res?.plan_type === 'REQ'
                                ? 'my-fc-ABABAB'
                                : res?.plan_type === 'SELF'
                                ? 'text-black'
                                : 'main'
                            }`}
                            onClick={() => navigate(`/mediaCart?plan=${res.id}`)}
                            key={res.id}>
                            {res.name}
                          </div>
                        ))}
                      </div>
                    )}
                    {item.type === 'INQUIRY' && item.plan_list && (
                      <div className="new-inquiry-box p-3 mt-1">
                        <div className="fw-800 fs-12px mb-2">첨부된 플랜</div>
                        <div
                          className="d-flex"
                          style={{
                            maxHeight: '35vh',
                            overflowY: 'auto',
                            gap: '0 5px',
                          }}>
                          {item.plan_list.map((plan, index) => (
                            <div
                              className={`fs-11px pointer ${
                                item.reserve_data[0]?.plan_type === 'REQ'
                                  ? 'my-fc-ABABAB'
                                  : item.reserve_data[0]?.plan_type === 'SELF'
                                  ? 'text-black'
                                  : 'main'
                              }`}
                              key={'plan_' + index}
                              onClick={() => navigate(`/mediaCart?plan=${item.reserve_data[0].id}`)}>
                              {plan}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <div className="py-2 mypage-file-box d-flex flex-wrap align-items-center px-0 ps-3">
                      <div className="text-black fs-9px ">{item.created_at.replace('T', ' ')}</div>

                      {item.files.length > 0 && (
                        <div className="d-flex">
                          <div className="text-black fs-9px">첨부된 파일&nbsp;:&nbsp;</div>
                          <div className="d-flex flex-wrap" style={{ gap: '0 .75rem' }}>
                            {item.files.map((item, index) => (
                              // <a
                              //   href={`http://115.85.180.225/image/${item.upload}`}
                              //   className="fs-10px fw-700 me-2 text-black pointer"
                              //   key={index}
                              //   download={item.origin}
                              // >
                              //   {item.origin}
                              // </a>
                              <div
                                className="fs-9px fw-700 me-2 text-black pointer"
                                key={index}
                                onClick={() => fileDown(item.upload, item.origin)}>
                                {item.origin}
                              </div>
                            ))}
                            {/* <div className="d-flex align-items-center" key={index}>
                            </div>

                          <GrClose size="10" /> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              {isMobile && <div style={{ paddingTop: '100px' }}></div>}
              {!isMobile && <div style={{ paddingTop: '50px' }}></div>}
            </div>
          </div>
        </div>
      </div>

      {/* 회원정보수정 */}
      <Modal show={editModal} onHide={editReset} centered className="edit-modal">
        <Modal.Header closeButton className="border-bottom-0">
          <div className="mypage-title-text fw-800 main">회원정보수정</div>
        </Modal.Header>
        <Modal.Body className="mypage-info-edit-modal scroll py-0">
          {editData && (
            <div>
              <div className="floating-container login-fs-11px">
                <FloatingLabel controlId="floatingEmail" label="이메일">
                  <Form.Control
                    type="text"
                    className="login-fs-11px"
                    name="email"
                    placeholder="이메일"
                    value={editData.email}
                    readOnly
                  />
                </FloatingLabel>
                <form>
                  <FloatingLabel controlId="floatingPassword " label="비밀번호" className={`mt-3`}>
                    <Form.Control
                      type={showPassword.pass1 ? 'text' : 'password'}
                      name="password"
                      className="login-fs-11px"
                      value={pw.password}
                      onChange={pwChange}
                      placeholder="비밀번호"
                      autoComplete="off"
                    />
                    <img
                      src={`${process.env.PUBLIC_URL}image/icon/${showPassword.pass1 ? 'eye_slash' : 'eye'}.svg`}
                      onClick={() => handlePasswordToggle('pass1')}
                      className="floatingEye pointer"
                      alt=""
                    />
                  </FloatingLabel>
                  {alert.password && (
                    <div className="red login-fs-10px ">* 비밀번호는 8자 이상 20자이하로 설정해주세요.</div>
                  )}
                  <FloatingLabel
                    controlId="floatingRePassword"
                    label="비밀번호 확인"
                    className={`${!alert.password && 'mt-3'}`}>
                    <Form.Control
                      type={showPassword.pass2 ? 'text' : 'password'}
                      name="re_password"
                      value={pw.re_password}
                      onChange={pwChange}
                      placeholder="비밀번호 확인"
                      className="login-fs-11px"
                      autoComplete="off"
                    />
                    <img
                      src={`${process.env.PUBLIC_URL}image/icon/${showPassword.pass2 ? 'eye_slash' : 'eye'}.svg`}
                      onClick={() => handlePasswordToggle('pass2')}
                      className="floatingEye pointer"
                      alt=""
                    />
                  </FloatingLabel>
                  {alert.re_password && <div className="red login-fs-10px ">* 같은 비밀번호를 입력해주세요.</div>}
                </form>
                <FloatingLabel controlId="floatingName" label="이름" className={`${!alert.re_password && 'mt-3'}`}>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleInfo}
                    value={editData.name}
                    placeholder="이름"
                    className="login-fs-11px"
                  />
                </FloatingLabel>
                {alert.name && <div className="red login-fs-10px ">* 이름을 입력해주세요.</div>}
                <FloatingLabel controlId="floatingTel" label="연락처" className={`${!alert.name && 'mt-3'}`}>
                  <Form.Control
                    type="text"
                    maxLength={11}
                    onChange={handleInfo}
                    name="mobile"
                    value={editData.mobile}
                    placeholder="연락처"
                    className="login-fs-11px"
                  />
                </FloatingLabel>
                {alert.mobile && <div className="red login-fs-10px ">* 연락처를 입력해주세요.</div>}
                {alert.reMobile && <div className="red login-fs-10px ">* 올바른 연락처를 입력해주세요.</div>}
                <FloatingLabel
                  controlId="floatingBrand"
                  label="소속 회사 / 담당 브랜드 (선택사항)"
                  className={`${!alert.mobile && !alert.reMobile && 'mt-3'}`}>
                  <Form.Control
                    type="text"
                    name="company"
                    value={editData.company}
                    onChange={handleInfo}
                    placeholder="소속 회사 / 담당 브랜드 (선택사항)"
                    className="login-fs-11px"
                  />
                </FloatingLabel>
                <FloatingLabel controlId="floatingTeam" label="소속 팀 / 직급 (선택사항)" className="mt-3">
                  <Form.Control
                    type="text"
                    onChange={handleInfo}
                    name="division"
                    value={editData.division}
                    placeholder="소속 팀 / 직급 (선택사항)"
                    className="login-fs-11px"
                  />
                </FloatingLabel>
                <FloatingLabel className="mt-3 login-fs-11px" controlId="floatingSelect" label="선호하는 연락방식">
                  <Form.Select
                    aria-label="선택해 주세요"
                    className="login-fs-11px"
                    onChange={(e) => setEditData({ ...editData, preference: e.target.value })}>
                    <option>선택해 주세요</option>
                    <option value="리알토 문의란">리알토 문의란</option>
                    <option value="카톡">카톡</option>
                    <option value="이메일">이메일</option>
                    <option value="전화통화">전화통화</option>
                    <option value="화상미팅">화상미팅</option>
                    <option value="대면미팅">대면미팅</option>
                  </Form.Select>
                </FloatingLabel>
              </div>
              <div className="d-flex align-items-center mt-3">
                <input
                  type="checkbox"
                  id="terms"
                  className="sign-checkbox d-none"
                  onChange={handleTerms}
                  defaultChecked={myInfo.agree_email}
                />
                <label htmlFor="terms" className="sign-label">
                  {terms && <img src={`${process.env.PUBLIC_URL}image/icon/terms_check.svg`} alt="" />}
                </label>
                <label htmlFor="terms" className="login-fs-11px fw-400 ms-2 terms-gray">
                  리알토 소식(기능 업데이트/신규매체 등) 이메일 수신(선택)
                </label>
              </div>
              <div className="d-flex justify-content-center position-relative" style={{ marginTop: '16px' }}>
                <div className="my-edit-btn bg-main" onClick={handleSignup}>
                  확인
                </div>
                <div className="my-edit-btn edit-gray" onClick={handleSignReset}>
                  취소
                </div>
                <div className="position-absolute end-0 top-50 translate-middle-y fs-10px pointer" onClick={handleOut}>
                  회원 탈퇴
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>

    // <div className="rialto-container">
    //   <Nav />
    //   <div className="rialto-content-box d-flex">
    //     <Aside />
    //   </div>

    // </div >
  );
};
export default Mypage;
