import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { APIURL, DEFAULTURL } from '../../api/apiDefault';
import { Loading } from '../../component/loading';
import styles from '../../css/billboard.module.css';
import trafficStyles from '../../css/traffic.module.css';

export const NaverMap = ({
  accessToken,
  defaultLocation,
  mediaData,
  wishList,
  setWishList,
  wishHandler,
  defaultZoomLevel,
  setDefaultZoomLevel,
  defaultLatLng,
  setDefaultLatLng,
  clickedDetail,
  listCheck,
  moveToMediaRef,
  selectedCategoryId,
  listData,
  setMapBounds,
  closeInfoWindow,
  resetMap,
  setResetMap,
}) => {
  const navigate = useNavigate();
  const mapElement = useRef();
  const mapRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [mapTitle, setMapTitle] = useState();
  const [locationInfo, setLocationInfo] = useState();
  const [oldLocationInfo, setOldLocationInfo] = useState();
  const [markerSize, setMarkerSize] = useState(10);
  const [markers, setMarkers] = useState([]);
  const [infoWindows, setInfoWindows] = useState([]);
  const [clickedDetailId, setClickedDetailId] = useState();
  const [overMarker, setOverMarker] = useState();
  const { naver } = window;
  const isMobile = useMediaQuery({ query: '(max-width: 1280px)' });
  useEffect(() => {
    setClickedDetailId(clickedDetail);
  }, [clickedDetail]);

  function doCloseInfoWindow() {
    for (let i = 0; i < infoWindows.length; i++) {
      if (infoWindows[i].getMap()) {
        infoWindows[i].close();
        break;
      }
    }
  }

  useEffect(() => {
    if (closeInfoWindow) {
      doCloseInfoWindow();
    }
  }, [closeInfoWindow]);

  function showMarker(map, marker) {
    if (marker.getMap()) return;
    marker.setMap(map);
  }

  function changeMap() {
    mapElement.innerHTML = '';

    for (const marker of markers) {
      if (marker.getMap()) {
        marker.setMap(null);
      }
    }

    for (const infoWindow of infoWindows) {
      if (infoWindow.getMap()) {
        infoWindow.close();
      }
    }

    let _markers = [];
    let _infoWindows = [];
    setMarkers(_markers);
    setInfoWindows(_infoWindows);

    if (defaultZoomLevel > 11) {
      if (defaultZoomLevel < 15) {
        for (let i = 0; i < listData.length; i++) {
          const item = listData[i];
          const _mainColor = i === overMarker ? '#FF0000' : '#FF7777';

          const mSize = {
            zoom_12: 10,
            zoom_13: 16,
            zoom_14: 30,
          };

          const _size = mSize[`zoom_${defaultZoomLevel}`];

          const _content = `<div class="position-relative">
            <div class="billboardZoneMarker" style="
              display: flex;
              justify-content: center;
              align-items: center;
              width: max-content;
              padding: ${_size + _size / 1.3}px;
              font-size: ${_size}px;
              font-weight: 800;
              background: radial-gradient(${_mainColor} 0%, transparent 70%);
              background-repeat: no-repeat;
              pointer-events: none;">
              <div>${item.name}</div>
            </div>
          </div>`;

          const _div = document.createElement('div');
          _div.innerHTML = _content;
          document.body.append(_div);
          _div.style.width = 'max-content';
          const _contentWidth = _div.clientWidth;
          const _contentHeight = _div.clientHeight;
          document.body.removeChild(_div);

          const position = new naver.maps.LatLng(item.lat, item.lon);

          const marker = new naver.maps.Marker({
            id: item.id,
            contentName: item.name,
            map: mapRef.current,
            position: position,
            // title: item.media_name,
            icon: {
              content: [_content].join(''),
              anchor: new naver.maps.Point(_contentWidth / 2, _contentHeight / 2),
            },
            zIndex: 100,
          });

          _markers.push(marker);
        }
      } else {
        for (const item of mediaData) {
          if (item.id === 28) {
            // G-라이트 커스텀 마커
            const gLightOptionList = {
              zoom_15: [130, 1, 1, 2],
              zoom_16: [260, 1, 2, 3],
              zoom_17: [530, 3, 3, 6],
              zoom_18: [1050, 4, 4, 10],
              zoom_19: [2000, 5, 5, 15],
            };
            const gLightOption = gLightOptionList[`zoom_${defaultZoomLevel}`];

            const _content = `<div
            style="
              position: absolute;
              top: -2px;
              left: -${gLightOption[0] / 2}px;
              width: ${gLightOption[0]}px;
              padding: ${gLightOption[1]}px;
              border: ${gLightOption[2]}px dashed #FF0000;
              background-color: transparent;
              transform: rotate(69.5deg);
            ">
              <div
                style="
                  position: relative;
                  width: 100%;
                  height: ${gLightOption[3]}px;
                  background-color: transparent;
                "></div>
            </div>`;

            const _div = document.createElement('div');
            _div.innerHTML = _content;
            document.body.append(_div);
            _div.style.width = 'max-content';
            const _contentWidth = _div.clientWidth;
            const _contentHeight = _div.clientHeight;
            document.body.removeChild(_div);

            const position = new naver.maps.LatLng(item.lat, item.lon);

            const marker = new naver.maps.Marker({
              id: item.id,
              categoryId: item.category_id,
              map: mapRef.current,
              position: position,
              // title: item.media_name,
              icon: {
                content: [_content].join(''),
                anchor: new naver.maps.Point(_contentWidth / 2, _contentHeight / 2),
              },
              zIndex: 100,
            });

            _markers.push(marker);
          } else {
            let _markerImage = item.marker;
            let _markerSize = markerSize[`marker${defaultZoomLevel}`];
            if (item.id === 1) {
              // 케이팝 라이브 커스텀 마커
              _markerImage = 'marker_kpop.png';
              const _markerSizeMap = {
                zoom_15: 22,
                zoom_16: 45,
                zoom_17: 90,
                zoom_18: 175,
                zoom_19: 370,
              };
              _markerSize = _markerSizeMap[`zoom_${defaultZoomLevel}`];
            } else if (item.id === 2) {
              // H-라이브 커스텀 마커
              _markerImage = 'marker_hyundai.png';
              const _markerSizeMap = {
                zoom_15: 30,
                zoom_16: 65,
                zoom_17: 120,
                zoom_18: 220,
                zoom_19: 450,
              };
              _markerSize = _markerSizeMap[`zoom_${defaultZoomLevel}`];
            }

            // 그 외 커스텀 마커
            const _content = `<div style="position: relative;">
            <img src="https://admin.rialto.kr/image/marker/${_markerImage}" alt="" style="width: ${_markerSize}px; transform: rotate(${item.rotate}deg);" />
          </div>`;

            const _div = document.createElement('div');
            _div.innerHTML = _content;
            document.body.append(_div);
            _div.style.width = 'max-content';
            const _contentWidth = _div.clientWidth;
            const _contentHeight = _div.clientHeight;
            document.body.removeChild(_div);

            const position = new naver.maps.LatLng(item.lat, item.lon);

            const marker = new naver.maps.Marker({
              id: item.id,
              categoryId: item.category_id,
              map: mapRef.current,
              position: position,
              // title: item.media_name,
              icon: {
                content: [_content].join(''),
                anchor: new naver.maps.Point(_contentWidth / 2, _contentHeight / 2),
              },
              zIndex: 100,
            });

            _markers.push(marker);
          }

          const _mainImageData = item.file_data.filter((_item) => _item.title === '메인 이미지');
          const _mainImage = _mainImageData[0]?.content;

          // sort to item option
          item.option.sort((a, b) => a.sort - b.sort);

          const _periods = item.option[0]?.period.split(',') ?? '';
          const _period =
            _periods[0] === '1개월'
              ? '월'
              : _periods[0] === '1주'
              ? '주'
              : _periods[0] === '1일'
              ? '일'
              : _periods[0] === '1회'
              ? '회'
              : _periods[0] ?? '';

          const _wish = wishList.length > 0 ? wishList.includes(item.id) : false;

          let priceText =
            item.option[0]?.price === '별도안내'
              ? '별도안내'
              : `${_period} ${item.option[0]?.price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`;

          const _infoWindowContent = `<div>
          <div class="infoWindowsItems" data-id="${item.category_id}" data-idx="${
            item.id
          }" style="width: 300px; text-align: center; display: flex; background-color: #FFFFFF; border: 2px solid #971B4D; cursor: pointer;">
            <div style="width: 140px;">
              <img src="${DEFAULTURL}image/${_mainImage}" alt="" style="width: 100%; height: 100%; object-fit: cover;" />
            </div>
            <div style="width: 160px; padding: 6px; text-align: left;">
              <div style="width: 100%; font-size: 14px; font-weight: 800; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">${
                item.media_name
              }</div>
              <div style="width: 100%; font-size: 8px; font-weight: 400; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">${
                item.media_type
              }</div>
              ${
                item.standard === undefined
                  ? ''
                  : `<div style="width: 100%; font-size: 8px; font-weight: 400; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">${item.standard}</div>`
              }
              <div style="width: 100%; font-size: 8px; font-weight: 400; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-top: 8px;">${
                item.option[0]?.name ?? ''
              }</div><div style="width: 100%; font-size: 12px; font-weight: 800; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">${priceText}</div>

            </div>
          </div>
          <div style="width: 2px; height: 30px; margin: 0 auto; background-color: #971B4D;"></div>
        </div>`;

          const infoWindow = new naver.maps.InfoWindow({
            title: `iw_${item.id}`,
            content: [_infoWindowContent].join(''),
            borderWidth: null,
            backgroundColor: 'transparent',
            disableAnchor: true,
            pixelOffset: new naver.maps.Point(0, -markerSize[`info${defaultZoomLevel}`]),
          });

          _infoWindows.push(infoWindow);

          const titleInfo = new naver.maps.InfoWindow({
            title: `ti_${item.id}`,
            content: `
            <div>
              <div style="width: 160px; padding: 4px 8px; background-color: white; border: 2px solid #971B4D; font-size: 10px; font-weight: 800; text-align: center; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                ${item.media_name}
              </div>
              <div style="width: 2px; height: 30px; margin: 0 auto; background-color: #971B4D;"></div>
            </div>
          `,
            borderWidth: null,
            backgroundColor: 'transparent',
            disableAnchor: true,
            pixelOffset: new naver.maps.Point(0, -markerSize[`info${defaultZoomLevel}`]),
          });

          _infoWindows.push(titleInfo);
        }

        setInfoWindows(_infoWindows);
      }

      if (_markers.length > 0) {
        setMarkers(_markers);
      }
    }

    var mapBounds = mapRef.current.getBounds();
    setMapBounds({
      max: mapBounds._max,
      min: mapBounds._min,
    });

    var marker, position;
    for (var i = 0; i < _markers.length; i++) {
      marker = _markers[i];
      position = marker.getPosition();

      if (mapBounds.hasLatLng(position)) {
        showMarker(mapRef.current, marker);
      }
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (mapRef.current) {
      let c1Click, c1Mouseover, c1Mouseout, elseClick, elseMouseover, elseMouseout;

      for (let i = 0; i < markers.length; i++) {
        if (selectedCategoryId === '1') {
          c1Click = naver.maps.Event.addListener(markers[i], 'click', () =>
            getClickMarker(markers[i]['id']),
          );
          c1Mouseover = naver.maps.Event.addListener(markers[i], 'mouseover', () =>
            setOverMarker(i),
          );
          c1Mouseout = naver.maps.Event.addListener(markers[i], 'mouseout', () =>
            setOverMarker(null),
          );
        } else {
          elseClick = naver.maps.Event.addListener(markers[i], 'click', () => getClickMarker(i));
          elseMouseover = naver.maps.Event.addListener(markers[i], 'mouseover', () => {
            listCheck(markers[i].id);
            moveToMediaRef(markers[i].id);

            if (infoWindows.length > 0) {
              infoWindows[i * 2 + 1].open(mapRef.current, markers[i]);
            }
          });

          if (infoWindows.length > 0) {
            elseMouseout = naver.maps.Event.addListener(markers[i], 'mouseout', () => {
              for (let i = 0; i < markers.length; i++) {
                const _index = i * 2 + 1;
                if (infoWindows[_index].getMap()) {
                  infoWindows[_index].close();
                }
              }
            });
          }
        }
      }

      const clickListener = naver.maps.Event.addListener(
        mapRef.current,
        'click',
        debounce(() => resetOverlay('click')),
      );
      const dragStartListener = naver.maps.Event.addListener(
        mapRef.current,
        'dragstart',
        debounce(() => resetOverlay('dragstart')),
      );
      const dragEndListener = naver.maps.Event.addListener(
        mapRef.current,
        'dragend',
        debounce(() => resetOverlay('dragend')),
      );
      const zoomChangeListener = naver.maps.Event.addListener(
        mapRef.current,
        'zoom_changed',
        debounce(() => resetOverlay('zoomChanged')),
      );

      return () => {
        if (selectedCategoryId === '1') {
          naver.maps.Event.removeListener(c1Click);
          naver.maps.Event.removeListener(c1Mouseover);
          naver.maps.Event.removeListener(c1Mouseout);
        } else {
          naver.maps.Event.removeListener(elseClick);
          naver.maps.Event.removeListener(elseMouseover);
          if (infoWindows.length > 0) {
            naver.maps.Event.removeListener(elseMouseout);
          }
        }
        naver.maps.Event.removeListener(clickListener);
        naver.maps.Event.removeListener(dragStartListener);
        naver.maps.Event.removeListener(dragEndListener);
        naver.maps.Event.removeListener(zoomChangeListener);
      };
    }
  }, [markers]);

  const debounce = (fn) => {
    let _timer;
    return (...args) => {
      clearTimeout(_timer);
      _timer = setTimeout(() => {
        fn.apply(this, args);
      }, 100);
    };
  };

  useEffect(() => {
    if (clickedDetailId?.id && infoWindows.length > 0) {
      let _infoWindowsIndex, _markersIndex;
      for (let i = 0; i < markers.length; i++) {
        _infoWindowsIndex = i * 2 + 1;
        if (infoWindows[_infoWindowsIndex]['title'] === `ti_${clickedDetailId.id}`) {
          _markersIndex = i;
          break;
        }
      }

      const _targetInfoWindow = infoWindows[_infoWindowsIndex];
      const _targetMarker = markers[_markersIndex];

      if (clickedDetailId.type === 'hover') {
        mapRef.current.morph(locationInfo, defaultZoomLevel, 'easeOutCubic');
        _targetInfoWindow.open(mapRef.current, _targetMarker);
      } else if (clickedDetailId.type === 'clicked') {
        _targetInfoWindow.open(mapRef.current, _targetMarker);
      }
    }
  }, [clickedDetailId]);

  useEffect(() => {
    if (markers.length > 0) {
      changeMap();
    }
  }, [markerSize, overMarker]);

  function resetOverlay(type) {
    for (let i = 0; i < infoWindows.length; i++) {
      if (infoWindows[i].getMap()) {
        infoWindows[i].close();
        break;
      }
    }

    const _zoomLevel = mapRef.current.getZoom();
    setDefaultZoomLevel(_zoomLevel);

    setClickedDetailId();

    const _center = mapRef.current.getCenter();

    if (type === 'dragend') {
      setDefaultLatLng([_center._lat, _center._lng]);
    }

    if (type === 'dragend' || type === 'zoomChanged') {
      var mapBounds = mapRef.current.getBounds();
      setMapBounds({
        max: mapBounds._max,
        min: mapBounds._min,
      });

      const _center = mapRef.current.getCenter();
      setDefaultLatLng([_center._lat, _center._lng]);

      if (_zoomLevel > 14) {
        let _list = {
          id: 0,
          gap: 9999999,
        };

        for (const item of listData) {
          if (item.lat && item.lon) {
            const _lat = Math.abs(_center._lat - Number(item.lat));
            const _lon = Math.abs(_center._lng - Number(item.lon));
            const _gap = _lat + _lon;
            if (_gap < _list.gap) {
              _list = {
                id: item.id,
                gap: _gap,
              };
            }
          }
        }

        navigate(`/billboard?id=${_list.id}`);
      } else {
        navigate(`/billboard`);
      }
    }
  }

  function getClickMarker(i) {
    const _index = i * 2;

    if (selectedCategoryId === '1') {
      navigate(`/billboard?id=${i}`);
      setResetMap(resetMap + 1);
    } else if (infoWindows.length > 0) {
      const _marker = markers[i],
        _infoWindow = infoWindows[_index];

      if (_infoWindow.getMap()) {
        _infoWindow.close();
      } else {
        _infoWindow.open(mapRef.current, _marker);

        const _infoWindows = document.querySelectorAll('.infoWindowsItems');
        for (const _item of _infoWindows) {
          _item.addEventListener('click', () => {
            const _id = _item.dataset.id;
            const _idx = _item.dataset.idx;
            navigate(`/billboardDetail?id=${_id}&idx=${_idx}`);
          });
        }

        const _infoWindowsWish = document.querySelectorAll('.infoWindowsWish');
        for (const _item of _infoWindowsWish) {
          _item.addEventListener('click', (e) => {
            e.stopPropagation();

            const _id = _item.dataset.id;
            let _wish = _item.dataset.wish;

            if (accessToken) {
              wishHandler(e, i, parseInt(_id), _wish === 'true' ? 'delete' : 'add');

              if (_wish === 'true') {
                _item.dataset.wish = 'false';
                _item.setAttribute('src', process.env.PUBLIC_URL + 'image/icon/wishDefault.svg');

                return false;
              } else if (_wish === 'false') {
                _item.dataset.wish = 'true';
                _item.setAttribute('src', process.env.PUBLIC_URL + 'image/icon/wishVariant.svg');

                return false;
              }
            } else {
              navigate('/login');
            }
          });
        }
      }
    }
  }

  useEffect(() => {
    if (defaultLatLng) {
      const _lat = defaultLatLng[0];
      const _lon = defaultLatLng[1];
      const mapLatLng = new naver.maps.LatLng(_lat, _lon);

      setLocationInfo(mapLatLng);
    }
  }, [defaultLatLng]);

  useEffect(() => {
    if (listData.length > 0) {
      if (selectedCategoryId === '1') {
        setMapTitle('빌보드');
      } else {
        const _data = listData.filter((item) => item.id === parseInt(selectedCategoryId));
        setMapTitle(_data[0]['name']);
      }
    }
  }, [locationInfo, selectedCategoryId, mediaData]);

  useEffect(() => {
    if (
      oldLocationInfo !== locationInfo &&
      locationInfo &&
      mediaData &&
      mapRef.current &&
      locationInfo &&
      defaultZoomLevel
    ) {
      mapRef.current.morph(locationInfo, defaultZoomLevel, 'easeOutCubic');

      changeMap();
      setOldLocationInfo(locationInfo);
    }
  }, [locationInfo, mediaData]);

  useEffect(() => {
    axios.get(`${APIURL}map/setting`).then((res) => {
      setMarkerSize(res.data);
    });

    const mapLatLng = new naver.maps.LatLng(defaultLocation[0], defaultLocation[1]);

    const mapOptions = {
      center: mapLatLng,
      zoom: 12,
      maxZoom: 19,
      mapDataControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: naver.maps.Position.RIGHT_TOP,
      },
      zoomControl: true,
      zoomControlOptions: {
        position: isMobile ? naver.maps.Position.RIGHT_CENTER : naver.maps.Position.RIGHT_BOTTOM,
      },
      logoControl: true,
      logoControlOptions: {
        position: naver.maps.Position.LEFT_BOTTOM,
      },
      scaleControl: true,
      scaleControlOptions: {
        position: naver.maps.Position.LEFT_BOTTOM,
      },
    };

    mapRef.current = new naver.maps.Map(mapElement.current, mapOptions);
  }, []);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <div ref={mapElement} style={{ width: '100%', height: '100%' }}></div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          top: '11px',
          left: '7.5px',
        }}>
        <img
          src={'image/icon/billboard.svg'}
          alt=""
          // style={{ width: "36px", height: "36px", marginRight: "10px" }}
          className={trafficStyles.icon_size}
        />
        <div
          // style={{ color: "#000000", fontSize: "27.5px", fontWeight: 800 }}
          className={styles.map_title}>
          {mapTitle}
        </div>
      </div>
      {isMobile && (
        <div
          onClick={() => navigate('/search?b=true')}
          style={{
            position: 'absolute',
            top: '10px',
            right: '100px',
            height: '30px',
            lineHeight: '30px',
          }}
          className="bg-main text-white px-2 fs-11px">
          리스트로 보기
        </div>
      )}

      {isLoading && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            top: 0,
            left: 0,
            zIndex: 9999,
          }}>
          <Loading />;
        </div>
      )}
    </div>
  );
};
