import React, { useEffect, useState } from 'react';
import '../css/media.css';
import axios from 'axios';
import { APIURL } from '../api/apiDefault';
import { Link, useNavigate } from 'react-router-dom';

const idToNavigator = {
  1: '/billboard',
  2: '/traffic',
  3: '/shopping',
  4: '/life',
  5: '/sport',
  6: '/etc',
};
const Media = () => {
  const [category, setCategory] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`${APIURL}category/list`)
      .then((res) => {
        if (res.status === 200) {
          const newData = res.data.map((item) => ({
            ...item,
            navigator: idToNavigator[item.id] || '/',
          }));
          const sortedArray = newData;

          sortedArray.sort((a, b) => a.sort - b.sort);

          sortedArray.forEach((item) => {
            if (item.second) {
              item.second.sort((a, b) => a.sort - b.sort);
              if (item.second) {
                item.second.forEach((subItem) => {
                  if (subItem.third) {
                    subItem.third.sort((a, b) => a.sort - b.sort);
                    if (subItem.third) {
                      subItem.third.forEach((subSubItem) => {
                        if (subSubItem.fourth) {
                          subSubItem.fourth.sort((a, b) => a.sort - b.sort);
                        }
                      });
                    }
                  }
                });
              }
            }
          });
          setCategory(sortedArray.filter((item) => item.is_admin !== 1));
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const navi = (_item, _id) => {
    console.log(_item)
    navigate(`${_item.navigator}?id=${_id}`)
  }
  return (
    <div className="my-container bg-white media-container-box">
      <div className="media-container scroll">
        <div className="d-flex flex-wrap">
          {category.map((item) => (
            <div className="text-center media-box" key={item.id}>
              <div className="media-1-box">
                <img src={`${process.env.PUBLIC_URL}image/icon/${item.navigator}.svg`} className="media-icon" />
                <div className="main media-1 fw-800">{item.name}</div>
              </div>
              {item.second.length > 0 && (
                <div>
                  {item.second.map((item2) => (
                    <div className="media-2-box" key={item2.id}>
                      <div className="media-2 fw-800">{item2.name}</div>
                      {item2.third.length > 0 && (
                        <div>
                          {item2.third.map((item3) => (
                            <div className="media-3 pointer" onClick={() => navi(item, item3.id)} key={item3.id}>
                              <div className="media-3-text">
                                {item3.name}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Media;
