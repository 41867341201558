import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import Accordion from 'react-bootstrap/Accordion';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { APIURL } from './api/apiDefault';

const idToNavigator = {
  1: '/billboard',
  2: '/traffic',
  3: '/shopping',
  4: '/life',
  5: '/sport',
  6: '/etc',
};
const Aside = (props) => {
  const { homeStyle, resetMap, setResetMap } = props;
  const [category, setCategory] = useState([]);
  // let token = useSelector((state) => state);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1281px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 1280px)',
  });
  useEffect(() => {
    axios
      .get(`${APIURL}category/list`)
      .then((res) => {
        if (res.status === 200) {
          const newData = res.data.map((item) => ({
            ...item,
            navigator: idToNavigator[item.id] || '/',
          }));

          const sortedArray = newData;

          sortedArray.sort((a, b) => a.sort - b.sort);

          sortedArray.forEach((item) => {
            if (item.second) {
              item.second.sort((a, b) => a.sort - b.sort);
              if (item.second) {
                item.second.forEach((subItem) => {
                  if (subItem.third) {
                    subItem.third.sort((a, b) => a.sort - b.sort);
                    if (subItem.third) {
                      subItem.third.forEach((subSubItem) => {
                        if (subSubItem.fourth) {
                          subSubItem.fourth.sort((a, b) => a.sort - b.sort);
                        }
                      });
                    }
                  }
                });
              }
            }
          });
          setCategory(sortedArray.filter((item) => item.is_admin !== 1));
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      {isDesktopOrLaptop && (
        <PC category={category} homeStyle={homeStyle} resetMap={resetMap} setResetMap={setResetMap} />
      )}

      {isMobile && <Mobile category={category} resetMap={resetMap} setResetMap={setResetMap} />}
    </>
  );
};

const PC = ({ ...props }) => {
  const { category, homeStyle, resetMap, setResetMap } = props;
  const location = useLocation();
  const { state } = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [paramsId, setParamsId] = useState(null);

  const [activeKey, setActiveKey] = useState();
  // const handleAccordionToggle = (eventKey) => {
  //   let newActiveKeys = [...activeKeys];

  //   // 선택한 아코디언 아이템의 키 값이 이미 선택된 목록에 있는지 확인
  //   const index = newActiveKeys.indexOf(eventKey);

  //   // 이미 선택된 아이템인 경우 제거하고, 그렇지 않은 경우 추가
  //   if (index > -1) {
  //     newActiveKeys.splice(index, 1);
  //   } else {
  //     newActiveKeys.push(eventKey);
  //   }

  //   setActiveKeys(newActiveKeys);
  // };
  const handleAccordionToggle = (eventKey) => {
    setActiveKey(eventKey);
  };

  //scroll smooth하게 이동
  const handleScrollToSection = (e) => {
    e.preventDefault();
    // e.target.classList.add("active");
    if (!state) {
      const targetId = e.currentTarget.getAttribute('href').substring(1);
      const targetElement = document.getElementById(targetId);
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setParamsId(queryParams.get('id') ?? null);

    let _key;
    switch (location.pathname) {
      case '/billboard':
      case '/billboardDetail':
        _key = 1;
        break;
      case '/traffic':
      case '/trafficDetail':
        _key = 2;
        break;
      case '/shopping':
      case '/shoppingDetail':
        _key = 3;
        break;
      case '/life':
      case '/lifeDetail':
        _key = 4;
        break;
      case '/sport':
      case '/sportDetail':
        _key = 5;
        break;
      case '/etc':
      case '/etcDetail':
        _key = 6;
        break;
      default:
        _key = null;
        break;
    }
    setActiveKey(_key);
  }, [location]);

  return (
    <div className={`${location.pathname === '/' ? 'aside-box h-auto' : 'aside-box aside-scroll'} ${homeStyle}`}>
      <div className="aside-container">
        <Accordion activeKey={activeKey} onSelect={handleAccordionToggle} className="text-white">
          <Accordion.Item eventKey="0">
            <Link to="/media" className={location.pathname === '/media' ? 'active' : ''}>
              <img
                src={`${process.env.PUBLIC_URL}${location.pathname === '/media' ? 'image/icon/home_active.svg' : 'image/icon/home.svg'
                  }`}
                className="aside-icon"
                alt=""
              />
              홈
            </Link>
          </Accordion.Item>
          {category.map((item) => {
            let categoryState;
            switch (item.id) {
              case 1:
                categoryState = { categoryId: '1' };
                break;
              default:
                break;
            }

            return (
              <Accordion.Item eventKey={item.id} key={item.id}>
                <Link
                  to={
                    item.navigator === '/billboard'
                      ? '/billboard'
                      : `${item.navigator}?id=${item.second[0].third[0].id}`
                  }
                  onClick={() => setResetMap(resetMap + 1)}
                  state={{ categoryState }}
                  className={
                    paramsId === null &&
                      (location.pathname === item.navigator || location.pathname === `${item.navigator}Detail`)
                      ? 'active'
                      : ''
                  }>
                  <img
                    src={`${process.env.PUBLIC_URL}${paramsId === null &&
                      (location.pathname === item.navigator || location.pathname === `${item.navigator}Detail`)
                      ? `image/icon/${item.navigator}_active.svg`
                      : `image/icon/${item.navigator}.svg`
                      }`}
                    className="aside-icon"
                    alt=""
                  />
                  {item.name}
                </Link>
                <Accordion.Body className="p-0">
                  <div className="d-flex flex-column accordion-margin-box">
                    {item.second.length > 0 &&
                      item.second.map((item2) => (
                        <div key={item2.id}>
                          {/* <div
                            className="fs-11px fw-800"
                            style={{ paddingLeft: '8px', marginTop: '15px', marginBottom: '5px' }}>
                            {item2.name}
                          </div> */}
                          <div className="fs-11px fw-800 aside-1-title">{item2.name}</div>
                          {item2.third.length > 0 && (
                            <div className="accordion-item2-margin">
                              {item2.third.map((item3) => (
                                <Link
                                  key={item3.id}
                                  to={item.id === 1 ? `/billboard?id=${item3.id}` : `${item.navigator}?id=${item3.id}`}
                                  onClick={() => setResetMap(resetMap + 1)}
                                  className="text-black detailAsideItems"
                                  data-id={item3.id}>
                                  <div
                                    style={{ fontSize: '12px' }}
                                    // className={
                                    //   location.pathname !==
                                    //     "/billboardDetail" &&
                                    //     parseInt(paramsId) === item3.id
                                    //     ? `aside-sub active`
                                    //     : "aside-sub"
                                    // }
                                    className={
                                      location.pathname === 'billbaord'
                                        ? parseInt(paramsId) === item3.id
                                          ? `aside-sub active`
                                          : 'aside-sub'
                                        : parseInt(paramsId) === item3.id
                                          ? `aside-sub active`
                                          : 'aside-sub'
                                    }>
                                    <div className="aside-sub-text">
                                      {item3.name}
                                      <div className="aside-sub-hover"></div>
                                    </div>
                                    {/* 마우스 오버 시 밑줄 요소 */}
                                  </div>
                                  {/* {item3.fourth.length > 0 && (
                                    <div className="d-flex flex-column" style={{ gap: '5px' }}>
                                      {item3.fourth.map((item4) => (
                                        <div key={item4.id} className="fs-9px ms-2">
                                          <div>{item4.name}</div>
                                        </div>
                                      ))}
                                    </div>
                                  )} */}
                                </Link>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}

          {/* <Accordion.Item eventKey="0">
            <Link to="/" className={location.pathname === "/" ? "active" : ""}>
              <img
                src={`${process.env.PUBLIC_URL}${location.pathname === "/"
                  ? "image/icon/home_active.svg"
                  : "image/icon/home.svg"
                  }`}
                className="aside-icon"
              />
              홈
            </Link>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Link
              to="/billboard"
              className={
                location.pathname === "/billboard" ||
                  location.pathname === "/billBoardDetail"
                  ? "active"
                  : ""
              }
            >
              <img
                src={`${process.env.PUBLIC_URL}${location.pathname === "/billboard" ||
                  location.pathname === "/billBoardDetail"
                  ? "image/icon/billboard_active.svg"
                  : "image/icon/billboard.svg"
                  }`}
                className="aside-icon"
              />
              빌보드
            </Link>
            {(location.pathname === "/billboard" ||
              location.pathname === "/billBoardDetail") && (
                <div className="p-1">
                  <div className="mb-2">
                    <div className="fs-11px fw-800">강남 지역</div>
                    <div className="mt-1">
                      <Link to="/" className="aside-sub fs-10px">
                        삼성역/영동대로
                      </Link>
                      <Link to="/" className="aside-sub fs-10px">
                        신사역/도산대로
                      </Link>
                      <Link to="/" className="aside-sub fs-10px">
                        강남역/강남대로
                      </Link>
                      <Link to="/" className="aside-sub fs-10px">
                        테헤란로
                      </Link>
                      <Link to="/" className="aside-sub fs-10px">
                        여의도
                      </Link>
                      <Link to="/" className="aside-sub fs-10px">
                        잠실/천호
                      </Link>
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="fs-11px fw-800">강북 지역</div>
                    <div className="mt-1">
                      <Link to="/" className="aside-sub fs-10px">
                        홍대/신촌/이대
                      </Link>
                      <Link to="/" className="aside-sub fs-10px">
                        이태원/한남
                      </Link>
                      <Link to="/" className="aside-sub fs-10px">
                        광화문 등 구도심
                      </Link>
                      <Link to="/" className="aside-sub fs-10px">
                        성수/건대
                      </Link>
                    </div>
                  </div>
                  <div>
                    <div className="fs-11px fw-800">자동차 전용</div>
                    <div className="mt-1">
                      <Link to="/" className="aside-sub fs-10px">
                        올림픽/강변북로
                      </Link>
                      <Link to="/" className="aside-sub fs-10px">
                        경부고속도로
                      </Link>
                    </div>
                  </div>
                </div>
              )}
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Link
              to="/traffic"
              className={
                location.pathname === "/traffic" ||
                  location.pathname === "/trafficDetail"
                  ? "active"
                  : ""
              }
            >
              <img
                src={`${process.env.PUBLIC_URL}${location.pathname === "/traffic" ||
                  location.pathname === "/trafficDetail"
                  ? "image/icon/traffic_active.svg"
                  : "image/icon/traffic.svg"
                  }`}
                className="aside-icon"
              />
              교통
            </Link>
            {(location.pathname === "/traffic" ||
              location.pathname === "/trafficDetail") && (
                <div className="p-1">
                  <div className="mb-2">
                    <div className="fs-11px fw-800">실외 교통 매체</div>
                    <div className="mt-1">
                      <a
                        href="#use_bus"
                        className={`aside-sub fs-10px ${state && state.id === "bus" ? "active" : ""
                          }`}
                        onClick={handleScrollToSection}
                      >
                        버스 이용 광고
                      </a>
                      <a
                        href="#use_taxi"
                        className={`aside-sub fs-10px ${state && state.id === "taxi" ? "active" : ""
                          }`}
                        onClick={handleScrollToSection}
                      >
                        택시 이용 광고
                      </a>
                      <a
                        href="#use_shelter"
                        className={`aside-sub fs-10px ${state && state.id === "shelter" ? "active" : ""
                          }`}
                        onClick={handleScrollToSection}
                      >
                        쉘터광고
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="fs-11px fw-800">실내 교통 매체</div>
                    <div className="mt-1">
                      <Link to="/" className="aside-sub fs-10px">
                        지하철 광고
                      </Link>
                      <Link to="/" className="aside-sub fs-10px">
                        지하상가 광고
                      </Link>
                      <Link to="/" className="aside-sub fs-10px">
                        공황 광고
                      </Link>
                      <Link to="/" className="aside-sub fs-10px">
                        기차역 광고
                      </Link>
                      <Link to="/" className="aside-sub fs-10px">
                        버스 터미널 광고
                      </Link>
                    </div>
                  </div>
                </div>
              )}
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Link
              to="/shopping"
              className={location.pathname === "/shopping" ? "active" : ""}
            >
              <img
                src={`${process.env.PUBLIC_URL}${location.pathname === "/shopping"
                  ? "image/icon/shopping_active.svg"
                  : "image/icon/shopping.svg"
                  }`}
                className="aside-icon"
              />
              쇼핑/외식
            </Link>
            {location.pathname === "/shopping" && (
              <div className="p-1">
                <div className="mb-2">
                  <div className="fs-11px fw-800">복합쇼핑몰</div>
                  <div className="mt-1">
                    <Link to="/" className="aside-sub fs-10px">
                      스타필드 코엑스
                    </Link>
                    <Link to="/" className="aside-sub fs-10px">
                      스타필드 고양 하남 안성
                    </Link>
                    <Link to="/" className="aside-sub fs-10px">
                      롯데월드몰
                    </Link>
                    <Link to="/" className="aside-sub fs-10px">
                      더 현대 서울
                    </Link>
                    <Link to="/" className="aside-sub fs-10px">
                      IFC몰
                    </Link>
                    <Link to="/" className="aside-sub fs-10px">
                      타임스퀘어
                    </Link>
                    <Link to="/" className="aside-sub fs-10px">
                      기타
                    </Link>
                  </div>
                </div>
                <div className="mb-2">
                  <div className="fs-11px fw-800">쇼핑</div>
                  <div className="mt-1">
                    <Link to="/" className="aside-sub fs-10px">
                      마트
                    </Link>
                    <Link to="/" className="aside-sub fs-10px">
                      편의점
                    </Link>
                    <Link to="/" className="aside-sub fs-10px">
                      올리브영
                    </Link>
                  </div>
                </div>
                <div>
                  <div className="fs-11px fw-800">외식</div>
                  <div className="mt-1">
                    <Link to="/" className="aside-sub fs-10px">
                      외식
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Link
              to="/life"
              className={location.pathname === "/life" ? "active" : ""}
            >
              <img
                src={`${process.env.PUBLIC_URL}${location.pathname === "/life"
                  ? "image/icon/life_active.svg"
                  : "image/icon/life.svg"
                  }`}
                className="aside-icon"
              />
              생활동선
            </Link>
            {location.pathname === "/life" && (
              <div className="p-1">
                <div>
                  <div className="fs-11px fw-800">엘리베이터 및 로비</div>
                  <div className="mt-1">
                    <Link to="/" className="aside-sub fs-10px">
                      아파트
                    </Link>
                    <Link to="/" className="aside-sub fs-10px">
                      오피스
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Link
              to="/sports"
              className={location.pathname === "/sports" ? "active" : ""}
            >
              <img
                src={`${process.env.PUBLIC_URL}${location.pathname === "/sports"
                  ? "image/icon/sport_active.svg"
                  : "image/icon/sport.svg"
                  }`}
                className="aside-icon"
              />
              스포츠/엔터
            </Link>
            {location.pathname === "/sports" && (
              <div className="p-1">
                <div className="mb-2">
                  <div className="fs-11px fw-800">스포츠</div>
                  <div className="mt-1">
                    <Link to="/" className="aside-sub fs-10px">
                      야구장
                    </Link>
                    <Link to="/" className="aside-sub fs-10px">
                      스크린골프/골프장
                    </Link>
                    <Link to="/" className="aside-sub fs-10px">
                      피트니스
                    </Link>
                  </div>
                </div>
                <div>
                  <div className="fs-11px fw-800">멀티플렉스</div>
                  <div className="mt-1">
                    <Link to="/" className="aside-sub fs-10px">
                      3사 스크린 광고
                    </Link>
                    <Link to="/" className="aside-sub fs-10px">
                      CGV 로비
                    </Link>
                    <Link to="/" className="aside-sub fs-10px">
                      롯데시네마 로비
                    </Link>
                    <Link to="/" className="aside-sub fs-10px">
                      메가박스 로비
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Link
              to="/etc"
              className={location.pathname === "/etc" ? "active" : ""}
            >
              <img
                src={`${process.env.PUBLIC_URL}${location.pathname === "/etc"
                  ? "image/icon/etc_active.svg"
                  : "image/icon/etc.svg"
                  }`}
                className="aside-icon"
              />
              기타
            </Link>
            {location.pathname === "/etc" && (
              <div className="p-1">
                <div>
                  <div className="mt-1">
                    <Link to="/" className="aside-sub fs-10px">
                      키즈클럽
                    </Link>
                    <Link to="/" className="aside-sub fs-10px">
                      스터디클럽
                    </Link>
                    <Link to="/" className="aside-sub fs-10px">
                      병원
                    </Link>
                    <Link to="/" className="aside-sub fs-10px">
                      리조트
                    </Link>
                    <Link to="/" className="aside-sub fs-10px">
                      미용실
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </Accordion.Item> */}
        </Accordion>
      </div>
    </div>
  );
};


const Mobile = ({ ...props }) => {
  const { category, resetMap, setResetMap } = props;
  const location = useLocation();
  const navigator = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleSheet = () => {
    setIsOpen(!isOpen);
    // setCurrentHeight(divRef.current.offsetHeight);
    !isOpen === false ? setCurrentHeight(71) : setCurrentHeight(divRef.current.offsetHeight);
  };

  const [currentHeight, setCurrentHeight] = useState(71);
  const divRef = useRef(null);
  useEffect(() => {
    setIsOpen(false);
    setCurrentHeight(71);
  }, [location.pathname]);

  useEffect(() => {
    if (
      location.pathname === '/' ||
      location.pathname === '/search' ||
      location.pathname === '/mediaCart' ||
      location.pathname === '/mypage' ||
      location.pathname === '/wish' ||
      location.pathname === '/media'
    ) {
      setMenuList(category[0]);
    } else {
      const targetObject = category.find((item) => item.navigator === location.pathname.replace('Detail', ''));
      setMenuList(targetObject);
    }
  }, [category, location.pathname]);
  // 메뉴 리스트
  const [menuList, setMenuList] = useState();
  const handleMenu = (_name, _nav) => {
    const dataUrl = divRef.current.querySelector('.content-url').dataset.url;
    if (_nav === dataUrl && isOpen) {
      navigator(_nav);
    } else {
      const targetObject = category.find((item) => item.name === _name);
      setMenuList(targetObject);
      setIsOpen(true);
    }
  };
  useEffect(() => {
    if (isOpen) {
      setCurrentHeight(divRef.current.offsetHeight + 100);
    }
  }, [menuList, isOpen]);

  const mobileNav = (_nav, _id) => {
    navigator(`${_nav}?id=${_id}`);
  };

  return (
    <div className={`bottom-sheet`} style={{ height: currentHeight }}>
      {/* {!isOpen && (
        <div
          onClick={
            location.pathname !== "/" &&
              location.pathname !== "/search" &&
              location.pathname !== "/mypage" &&
              location.pathname !== "/mediaCart"
              ? toggleSheet
              : null
          }
          className="text-center"
        >
          <img
            src={`${process.env.PUBLIC_URL}/image/icon/more_m.svg`}
            style={{ marginTop: "8px" }}
          />
        </div>
      )} */}

      <div onClick={toggleSheet} className="d-flex justify-content-center align-items-center bottom-sheet-arrow">
        <img
          style={{ borderBottom: '1px solid #fff' }}
          src={`${process.env.PUBLIC_URL}/image/icon/${isOpen ? 'more_close_m' : 'more_m'}.svg`}
          alt=""
        />
      </div>

      <div className="bg-white bottom-content">
        <div>
          <div className="bottom-menu mx-auto">
            <div className="d-flex justify-content-between">
              {category.map((item) => (
                <div onClick={() => handleMenu(item.name, item.navigator)} key={item.id} className="text-center flex-1">
                  <img src={`${process.env.PUBLIC_URL}/image/icon/${item.navigator}_m.svg`} alt="" />
                  <div className="bottom-text fs-10px">
                    <span className="position-relative d-inline-block">
                      {item.name}
                      <div
                        className={
                          location.pathname === item.navigator || location.pathname === `${item.navigator}Detail`
                            ? 'bottom-active'
                            : ''
                        }></div>
                    </span>
                  </div>
                </div>
              ))}
              {/* <Link to="/billboard" className="text-center flex-1">
                <img
                  src={`${process.env.PUBLIC_URL}/image/icon/billboard_m.svg`}
                />
                <div className="bottom-text fs-10px">
                  <span className="position-relative">
                    빌보드
                    <div
                      className={
                        location.pathname === "/billboard"
                          ? "bottom-active"
                          : ""
                      }
                    ></div>
                  </span>
                </div>
              </Link>
              <Link to="/traffic" className="text-center flex-1">
                <img
                  src={`${process.env.PUBLIC_URL}/image/icon/traffic_m.svg`}
                />
                <div className="bottom-text fs-10px">
                  <span className="position-relative">
                    교통
                    <div
                      className={
                        location.pathname === "/traffic" ||
                          location.pathname === "/trafficDetail"
                          ? "bottom-active"
                          : ""
                      }
                    ></div>
                  </span>
                </div>
              </Link>
              <div className="text-center flex-1">
                <img
                  src={`${process.env.PUBLIC_URL}/image/icon/shopping_m.svg`}
                />
                <div className="bottom-text fs-10px">
                  <span className="position-relative">
                    쇼핑/외식
                    <div></div>
                  </span>
                </div>
              </div>
              <div className="text-center flex-1">
                <img src={`${process.env.PUBLIC_URL}/image/icon/life_m.svg`} />
                <div className="bottom-text fs-10px">
                  <span className="position-relative">
                    생활동선
                    <div></div>
                  </span>
                </div>
              </div>
              <div className="text-center flex-1">
                <img src={`${process.env.PUBLIC_URL}/image/icon/sport_m.svg`} />
                <div className="bottom-text fs-10px">
                  <span className="position-relative">
                    스포츠/엔터
                    <div></div>
                  </span>
                </div>
              </div>
              <div className="text-center flex-1">
                <img src={`${process.env.PUBLIC_URL}/image/icon/etc_m.svg`} />
                <div className="bottom-text fs-10px">
                  <span className="position-relative">
                    기타
                    <div></div>
                  </span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="content" ref={divRef}>
          <div className="text-center">
            {/* <div className="fs-12px fw-800 main mb-1">
              {location.pathname === "/billboard" && "빌보드"}
              {(location.pathname === "/traffic" || location.pathname === "/trafficDetail") && "교통"}
              {location.pathname === "/shopping" && "쇼핑/외식"}
              {location.pathname === "/life" && "생활동선"}
              {location.pathname === "/sport" && "스포츠/엔터"}
              {location.pathname === "/etc" && "기타"}
            </div> */}
            {menuList && (
              <div data-url={menuList.navigator} className="content-url">
                <div className="fs-15px fw-900 main mb-1">{menuList.name}</div>
                {menuList.second.length > 0 &&
                  menuList.second.map((item2) => (
                    <div key={item2.id} className="mb-3">
                      <div className="fw-900 fs-12px mb-1">{item2.name}</div>
                      {item2.third.length > 0 && (
                        <div className="d-flex flex-column" style={{ gap: '5px 0' }}>
                          {item2.third.map((item3) => (
                            <div key={item3.id}>
                              <div
                                key={item3.id}
                                className="fs-10px fw-700"
                                onClick={() => {
                                  mobileNav(menuList.navigator, item3.id);
                                  setResetMap(resetMap + 1);
                                  toggleSheet();
                                }}>
                                {item3.name}
                              </div>

                              {/* {item3.fourth.length > 0 &&
                                item3.fourth.map((item4) => (
                                  <div key={item4.id} className="fs-9px">
                                    {item4.name}
                                  </div>
                                ))} */}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            )}
            {/* {category.map((item) => (
              <div key={item.id}>
                {(location.pathname === item.navigator ||
                  location.pathname === `${item.navigator}Detail`) && (
                    <div>
                      <div className="fs-12px fw-800 main mb-1">{item.name}</div>
                      {item.second.length > 0 &&
                        item.second.map((item2) => (
                          <div key={item2.id} className="mb-3">
                            <div className="fw-800 fs-11px mb-1">
                              {item2.name}
                            </div>
                            {item2.third.length > 0 && (
                              <div
                                className="d-flex flex-column"
                                style={{ gap: "5px 0" }}
                              >
                                {item2.third.map((item3) => (
                                  <div key={item3.id}>
                                    <div key={item3.id} className="fs-10px">
                                      {item3.name}
                                    </div>
                                    {item3.fourth.length > 0 &&
                                      item3.fourth.map((item4) => (
                                        <div key={item4.id} className="fs-9px">
                                          {item4.name}
                                        </div>
                                      ))}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  )}
              </div>
            ))} */}

            {/* {location.pathname === "/billboard" && (
              <div>
                <div className="fs-12px fw-800 main mb-1">빌보드</div>
                {asideMenu.billboard.map((item, index) => (
                  <div key={index} className="mb-3">
                    <div className="fw-800 fs-11px">{item.area}</div>
                    {item.locations.map((item2, index2) => (
                      <div key={index2 + 20} className="fs-10px">
                        {item2.label}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )} */}

            {/* {(location.pathname === "/traffic" ||
              location.pathname === "/trafficDetail") && (
                <div>
                  <div className="fs-12px fw-800 main mb-1">교통</div>
                  {asideMenu.traffic.map((item, index) => (
                    <div key={index} className="mb-3">
                      <div className="fw-800 fs-11px">{item.area}</div>
                      {item.locations.map((item2, index2) => (
                        <div key={index2 + 20} className="fs-10px">
                          {item2.label}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}

            {location.pathname === "/shopping" && (
              <div>
                <div className="fs-12px fw-800 main mb-1">쇼핑/외식</div>
                {asideMenu.shopping.map((item, index) => (
                  <div key={index} className="mb-3">
                    <div className="fw-800 fs-11px">{item.area}</div>
                    {item.locations.map((item2, index2) => (
                      <div key={index2 + 20} className="fs-10px">
                        {item2.label}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}

            {location.pathname === "/life" && (
              <div>
                <div className="fs-12px fw-800 main mb-1">생활동선</div>
                {asideMenu.life.map((item, index) => (
                  <div key={index} className="mb-3">
                    <div className="fw-800 fs-11px">{item.area}</div>
                    {item.locations.map((item2, index2) => (
                      <div key={index2 + 20} className="fs-10px">
                        {item2.label}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}

            {location.pathname === "/sport" && (
              <div>
                <div className="fs-12px fw-800 main mb-1">스포츠/엔터</div>
                {asideMenu.sport.map((item, index) => (
                  <div key={index} className="mb-3">
                    <div className="fw-800 fs-11px">{item.area}</div>
                    {item.locations.map((item2, index2) => (
                      <div key={index2 + 20} className="fs-10px">
                        {item2.label}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}

            {location.pathname === "/etc" && (
              <div>
                <div className="fs-12px fw-800 main mb-1">기타</div>
                {asideMenu.etc.map((item, index) => (
                  <div key={index} className="mb-3">
                    <div className="fw-800 fs-11px">{item.area}</div>
                    {item.locations.map((item2, index2) => (
                      <div key={index2 + 20} className="fs-10px">
                        {item2.label}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )} */}

            {/* {asideMenu[location.pathname.replace("/", "")].map(
                (item, index) => (
                  <div key={index} className="mb-3">
                    <div className="fw-800 fs-11px">{item.area}</div>
                    {item.locations.map((item2, index2) => (
                      <div key={index2 + 20} className="fs-10px">
                        {item2.label}
                      </div>
                    ))}
                  </div>
                )
              )} */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Aside;
