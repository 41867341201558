import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import './search.css';
import Swal from 'sweetalert2';
import axios from 'axios';
import { GetAccessToken } from '../../api/GetToken';
import { Link, useNavigate } from 'react-router-dom';
import { APIURL, DEFAULTURL } from '../../api/apiDefault';

const Wish = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const accessToken = GetAccessToken();
  const navigate = useNavigate();
  const [myWish, setMyWish] = useState([]);
  const [wishList, setWishList] = useState([]);

  useEffect(() => {
    axios
      .get(`${APIURL}favorite/my`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          // setMyWish([...res.data]);
          setMyWish(res.data);
        }
      })
      .catch((err) => console.log(err));

    axios
      .get(`${APIURL}favorite/my/media`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((res) => {
        if (res.status === 200) setWishList(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  /** 아이콘 변경 */
  const categoryIcon = (_id, _navId) => {
    console.log(_id);

    axios
      .get(`${APIURL}category/one/${_id}`)
      .then((res) => {
        console.log(res);
        const categoryId = res.data.id;
        if (res.data.depth === 3) {
          axios
            .get(`${APIURL}category/one/${res.data.parent_id}`)
            .then((res2) => {
              if (res2.status === 200) returnNav(_navId, res2.data.parent_id, categoryId);
              console.log(res2.data);
            })
            .catch((err2) => console.log(err2));
        } else {
          axios
            .get(`${APIURL}category/one/${res.data.parent_id}`)
            .then((res2) => {
              if (res2.status === 200) {
                const categoryId = res2.data.id;
                axios
                  .get(`${APIURL}category/one/${res2.data.parent_id}`)
                  .then((res3) => {
                    if (res3.status === 200) returnNav(_navId, res3.data.parent_id, categoryId);
                    console.log(res.data.parent_id);
                  })
                  .catch((err3) => console.log(err3));
              }
            })
            .catch((err2) => console.log(err2));
        }
      })
      .catch((err) => console.log(err));
  };
  const returnNav = (_navId, resultId, categoryId) => {
    switch (Number(resultId)) {
      case 1:
        return navigate(`/billboardDetail?id=${_navId}`);
      case 2:
        return navigate(`/trafficDetail?idx=${_navId}&id=${categoryId}`);
      case 3:
        return navigate(`/shoppingDetail?idx=${_navId}&id=${categoryId}`);
      case 4:
        return navigate(`/lifeDetail?idx=${_navId}&id=${categoryId}`);
      case 5:
        return navigate(`/sportDetail?idx=${_navId}&id=${categoryId}`);
      default:
        return navigate(`/etcDetail?idx=${_navId}&id=${categoryId}`);
    }
  };

  const handleWish = (e, id, status) => {
    e.stopPropagation();
    e.preventDefault();

    if (!accessToken) {
      navigate('/login');
      return;
    }

    if (!status) {
      const _data = {
        media_id: id,
      };
      axios
        .post(`${APIURL}favorite`, _data, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            setWishList([...wishList, id]);
          }
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .delete(`${APIURL}favorite/${id}`, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const updatedWishList = wishList.filter((itemId) => itemId !== id);
            setWishList(updatedWishList);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <div className="my-container bg-white">
      <div className="bg-white search-bg-box">
        <div className="position-relative search-container scroll">
          <div className="position-absolute bg-white h-100 w-100 search-full-box">
            <div className="d-flex align-items-center search-title-content">
              <img src={`${process.env.PUBLIC_URL}/image/icon/wishDefault.svg`} className="search-title-icon" />
              <div className="fw-800 search-title">찜리스트</div>
            </div>

            <div>
              <div className="row">
                {myWish.length > 0 ? (
                  myWish.map((data) => (
                    <div
                      key={data.id}
                      className="result-box pointer pb-4 col-lg-3 col-6"
                      onClick={() => categoryIcon(data.category_id, data.id)}>
                      <div className="result-img">
                        <img src={`${DEFAULTURL}image/${data.file_data[0]['content']}`} />
                      </div>
                      <div className="result-content ">
                        <div className="d-flex align-items-center justify-content-between result-title-flex">
                          <div className="result-title-text fw-800">{data.media_name}</div>
                          {!isMobile && (
                            <div className="d-flex pointer ms-3">
                              <img
                                onClick={(e) => {
                                  handleWish(e, data.id, wishList.includes(data.id));
                                }}
                                className="result-icon"
                                src={
                                  wishList.includes(data.id)
                                    ? `${process.env.PUBLIC_URL}image/icon/wishVariant.svg`
                                    : `${process.env.PUBLIC_URL}image/icon/wishDefault.svg`
                                }
                              />
                            </div>
                          )}
                        </div>
                        <div className="result-sub-text result-sub-type-text fw-400">{data.media_type}</div>
                        <div className="result-sub-text fw-400">
                          {data.option[0].name}
                          <span className="ms-2">{data.option[0].amount?.split(',')[0]}</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="fw-800 result-price-text">
                            {isNaN(data.option[0].price) && data.option[0].price}
                            {!isNaN(data.option[0].price) && (
                              <div>월 {Number(data.option[0].price).toLocaleString()}원</div>
                            )}
                          </div>
                          {isMobile && (
                            <div className="d-flex pointer ms-3">
                              <img
                                onClick={(e) => {
                                  handleWish(e, data.id, wishList.includes(data.id));
                                }}
                                className="result-icon"
                                src={
                                  wishList.includes(data.id)
                                    ? `${process.env.PUBLIC_URL}image/icon/wishVariant.svg`
                                    : `${process.env.PUBLIC_URL}image/icon/wishDefault.svg`
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    className="fw-800 fs-12px"
                    style={{
                      width: '100%',
                      marginTop: '100px',
                      textAlign: 'center',
                    }}>
                    찜한 매체리스트가 없습니다.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Wish;
