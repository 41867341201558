// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rangeOuter {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 19.5px;
  background-color: #fbe5d6;
  font-size: 7px;
  color: white;
}

.rangeStack {
  position: relative;
  flex: 1 1;
  height: 100%;
}

.rangeStack.active {
  background-color: #961d4c;
}

.rangeStack.startMonth {
  border-left: 1px solid #ffffff;
}

.startDate {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 2px;
  z-index: 1;
  display: flex;
  align-items: flex-start;
}

.endDate {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 2px;
  z-index: 1;
  display: flex;
  align-items: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/css/MediaRangeComponent.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,cAAc;EACd,yBAAyB;EACzB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,SAAO;EACP,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,SAAS;EACT,UAAU;EACV,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,UAAU;EACV,UAAU;EACV,aAAa;EACb,qBAAqB;AACvB","sourcesContent":[".rangeOuter {\n  position: relative;\n  display: flex;\n  align-items: center;\n  width: 100%;\n  height: 19.5px;\n  background-color: #fbe5d6;\n  font-size: 7px;\n  color: white;\n}\n\n.rangeStack {\n  position: relative;\n  flex: 1;\n  height: 100%;\n}\n\n.rangeStack.active {\n  background-color: #961d4c;\n}\n\n.rangeStack.startMonth {\n  border-left: 1px solid #ffffff;\n}\n\n.startDate {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 2px;\n  z-index: 1;\n  display: flex;\n  align-items: flex-start;\n}\n\n.endDate {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: 2px;\n  z-index: 1;\n  display: flex;\n  align-items: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
