import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  forwardRef,
} from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../css/traffic.module.css";
import { Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import {
  FreeMode,
  Navigation,
  Thumbs,
  Pagination,
  Mousewheel,
  Keyboard,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import Select from "react-select";
import { BiSolidShareAlt } from "react-icons/bi";
import { BsMapFill, BsLink45Deg, BsFiles } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import moment from "moment";
import { ko } from "date-fns/esm/locale";
import {
  addDays,
  getDay,
  getDate,
  isSameDay,
  isSameMonth,
  isSameYear,
  getMonth,
} from "date-fns";
import { getCookieToken } from "../storage/Cookie";
import { GetAccessToken } from "../api/GetToken";
import "../css/pages.css";
import { useMediaQuery } from "react-responsive";
import Modal from "react-bootstrap/Modal";
import { APIURL, DEFAULTURL } from "../api/apiDefault";
import BasicModal from "../component/BasicModal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Loading } from '../component/loading';

const imgUrl = `${DEFAULTURL}image/`;

function PagesDetail({ ...props }) {
  const isLaptop = useMediaQuery({ query: "(max-width: 768px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 425px" });

  const accessToken = GetAccessToken();

  const { userInfo, number, setNumber } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const id =
    location.pathname === "/billboardDetail"
      ? queryParams.get("idx")
      : queryParams.get("idx");

  const [info, setInfo] = useState({});

  const [categoryId, setCategoryId] = useState({
    depth1: "",
    depth1_name: "",
    depth3: "",
    depth3_name: "",
  });

  const getBgClass = (index) => {
    const classes = [
      "bg-light-purple",
      "bg-light-pink",
      "bg-light-green",
      "bg-light-beige",
    ];
    return classes[index % classes.length];
  };

  const [selectOptions, setSelectOptions] = useState({
    option: [],
    period: [],
    quantity: [],
  });

  const [content, setContent] = useState({
    wish: false,
    images: [],
  });

  //위시리스트 추가
  const handleWish = (status) => {
    if (!accessToken) {
      navigate("/login");
      return;
    }
    if (!status) {
      const _data = {
        media_id: data.id,
      };
      axios
        .post(`${APIURL}favorite`, _data, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setWishList([...wishList, data.id]);
          }
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .delete(`${APIURL}favorite/${data.id}`, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const updatedWishList = wishList.filter(
              (itemId) => itemId !== data.id
            );
            setWishList([...updatedWishList]);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  //셀렉트박스 데이터
  const [selectSet, setSelectSet] = useState({
    option_data: null,
    option: null,
    startDate: null,
    period: null,
    quantity: null,
    price: null,
    limitDate: null,
  });

  const [selectEa, setSelectEa] = useState({
    period: 1,
    quantity: 1,
  });

  // 시작일
  const [startDate, setStartDate] = useState(null);

  //클립보드
  const toClipboard = async (text) => {
    // try {
    //   await navigator.clipboard.writeText(text);
    //   alert("클립보드에 링크가 복사되었습니다.");
    // } catch (err) {
    //   console.log(err);
    // }

    const currentUrl = window.location.href;

    const tempInput = document.createElement("input");
    tempInput.value = currentUrl;

    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    Swal.fire({
      icon: "success",
      iconColor: "#971b4d",
      text: "클립보드에 복사되었습니다.",
      confirmButtonText: "확인",
      confirmButtonColor: "#971b4d",
    });
  };

  const [getItem, setGetItem] = useState({
    show: false,
    data: [],
  });


  // 카트에 담기
  const cartSubmit = (e) => {
    if (!accessToken) {
      navigate("/login");
      return;
    }
    if (!selectSet.option || !startDate) {
      Swal.fire({
        icon: "info",
        iconColor: "#971b4d",
        text: "시작일을 선택해 주세요.",
        confirmButtonText: "확인",
        confirmButtonColor: "#971b4d",
      });
      return;
    }

    const planCount = getItem.data.rialto_count + getItem.data.self_count;

    // axios
    //   .get(`${APIURL}plan`, {
    //     headers: {
    //       Authorization: accessToken,
    //     },
    //   })
    //   .then((res) => {
    //     if (res.status === 200) {
    //       const data = res.data;
    //       const planCount = data.rialto_count + data.self_count;
          if (planCount > 0) {
            setGetItem({
              ...getItem,
              show: true,
            });
          } else {
            newCart();
          }
    //     }
    //   })
    //   .catch((err) => console.log(err));
  };

  // 새 플랜에 생성하여 담기
  const newCart = () => {
    if (getItem.data.length !== 0) {
      const maxCartCount = getItem.data.self_count;
      if (maxCartCount >= 10) {
        Swal.fire({
          icon: "error",
          iconColor: "#971b4d",
          text: "더 이상 카트에 담을 수 없습니다.",
          confirmButtonText: "확인",
          confirmButtonColor: "#971b4d",
        });

        return;
      }
    }

    const _header = {
      headers: {
        Authorization: accessToken,
      },
    };
    let newName = "";
    if (getItem.data.length === 0) {
      newName = 1;
    } else {
      const selfList = getItem.data.self_list;

      if (selfList.length === 0) {
        newName = 1;
      } else {
        const filteredSelfList = selfList.filter((item) =>
          item.name.includes("새플랜")
        );
        const filteredList = filteredSelfList
          .map((item) => {
            const nameWithoutNewPlan = item.name.replace("새플랜", "").trim();
            const number = parseInt(nameWithoutNewPlan);

            if (!isNaN(number)) {
              return number;
            }
            return null;
          })
          .filter((item) => item !== null);

        if (filteredList.length > 0) {
          const maxNumber = Math.max(...filteredList);
          newName = maxNumber + 1;
        } else {
          newName = 1;
        }
      }
    }
    const _data = {
      name: `새플랜 ${newName}`,
      // plan_data: JSON.stringify([_subData]),
      plan_data: "[]",
      plan_type: "SELF",
    };

    axios
      .post(`${APIURL}plan`, _data, _header)
      .then((res) => {
        if (res.status === 200) {
          const _mediaData = [
            {
              category_id: categoryId.depth1,
              category_name: categoryId.depth1_name,
              data: [
                {
                  category_id: categoryId.depth3,
                  category_name: categoryId.depth3_name,
                  data: [
                    {
                      media: Number(id),
                      option: selectSet.option_data.id,
                      media_name: data.media_name,
                      selected_option: {
                        start_date: moment(startDate).format("YYYY-MM-DD"),
                        selected_option_item: selectSet.option_data.id,
                        selected_period: selectSet.period,
                        selected_amount: selectSet.quantity,
                        selected_price: isNaN(selectSet.price)
                          ? selectSet.price
                          : selectSet.price *
                            selectEa.period *
                            selectEa.quantity,
                        selected_install_price: isNaN(
                          selectSet.option_data.install_price
                        )
                          ? selectSet.option_data.install_price
                          : selectSet.option_data.install_price *
                            selectEa.quantity,
                        selected_limit_Date: selectSet.limitDate,
                      },
                    },
                  ],
                },
              ],
            },
          ];
          const planMediaData = {
            plan_id: res.data.id,
            plan_data: JSON.stringify(_mediaData),
          };
          axios
            .post(`${APIURL}plan/media`, planMediaData, _header)
            .then((res2) => {
              if (res2.status === 200) {
                Swal.fire({
                  icon: "success",
                  iconColor: "#971b4d",
                  text: `새플랜을 생성하고 매체를 추가했습니다.`,
                  confirmButtonText: "확인",
                  confirmButtonColor: "#971b4d",
                }).then(() => {
                  setGetItem({
                    ...getItem,
                    show: false,
                  });
                  setNumber({ ...number, cart: number.cart + 1 });
                });
              }
            })
            .catch((err2) => console.log(err2));
        }
      })
      .catch((err) => console.log(err));
  };
  // 기존 플랜
  const currentCart = (_item) => {
    console.log(_item);

    const _header = {
      headers: {
        Authorization: accessToken,
      },
    };

    let _data = { ..._item };
    let sendData = null;

    const _mediaData = {
      media: Number(id),
      option: selectSet.option_data.id,
      media_name: data.media_name,
      selected_option: {
        start_date: moment(startDate).format("YYYY-MM-DD"),
        selected_option_item: selectSet.option_data.id,
        selected_period: selectSet.period,
        selected_amount: selectSet.quantity,
        selected_price: isNaN(selectSet.price)
          ? selectSet.price
          : selectSet.price * selectEa.period * selectEa.quantity,
        selected_install_price: isNaN(selectSet.option_data.install_price)
          ? selectSet.option_data.install_price
          : selectSet.option_data.install_price * selectEa.quantity,
        selected_limit_Date: selectSet.limitDate,
      },
    };

    const depth1List = _data.plan_data.map((_item) => _item.category_id);
    const depth1Index = depth1List.indexOf(categoryId.depth1);

    if (depth1Index === -1) {
      _data.plan_data.push({
        category_id: categoryId.depth1,
        category_name: categoryId.depth1_name,
        data: [
          {
            category_id: categoryId.depth3,
            category_name: categoryId.depth3_name,
            data: [_mediaData],
          },
        ],
      });
    } else {
      const depth3List = _data["plan_data"][depth1Index]["data"].map(
        (_item) => _item.category_id
      );
      const depth3Index = depth3List.indexOf(categoryId.depth3);

      if (depth3Index === -1) {
        _data["plan_data"][depth1Index]["data"].push({
          category_id: categoryId.depth3,
          category_name: categoryId.depth3_name,
          data: [_mediaData],
        });
      } else {
        let _target =
          _data["plan_data"][depth1Index]["data"][depth3Index]["data"];
        let _hasItem = false;
        for (const _item of _target) {
          if (_item.media === Number(id)) {
            _hasItem = true;
            break;
          }
        }

        // if (!_hasItem) {
        _data["plan_data"][depth1Index]["data"][depth3Index]["data"].push(
          _mediaData
        );
        // } else {
        //   Swal.fire({
        //     icon: "warning",
        //     iconColor: "#971b4d",
        //     text: "해당 플랜에 이미 존재하는 항목입니다.",
        //     confirmButtonColor: "#971b4d",
        //     confirmButtonText: "확인",
        //   });
        //   return;
        // }
      }
    }
    sendData = {
      plan_id: _data.id,
      plan_data: JSON.stringify(_data.plan_data),
    };

    if (sendData !== null) {
      axios
        .post(`${APIURL}plan/media`, sendData, _header)
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              iconColor: "#971b4d",
              text: `${_item.name}에 추가되었습니다.`,
              confirmButtonText: "확인",
              confirmButtonColor: "#971b4d",
            }).then(() => {
              setGetItem({
                ...getItem,
                show: false,
              });
            });
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const [data, setData] = useState();
  const [soldOut, setSoldOut] = useState(false);
  const [wishList, setWishList] = useState([]);
  const dateDefault = (_date) => {
    const today = new Date();
    switch (Number(_date)) {
      case 1:
        let date1 = new Date(today.getFullYear(), today.getMonth(), 1);
        if (date1 < today) {
          const nextYear =
            today.getMonth() === 11
              ? today.getFullYear() + 1
              : today.getFullYear();
          const nextMonth = today.getMonth() === 11 ? 0 : today.getMonth() + 1;
          date1 = new Date(nextYear, nextMonth, 1);
        }
        return date1;
      case 2:
        if (today.getDate() > 1 && today.getDate() <= 16) {
          const nextMonth = today.getMonth() === 11 ? 0 : today.getMonth() + 1;
          const nextYear =
            today.getMonth() === 11
              ? today.getFullYear() + 1
              : today.getFullYear();
          if (today > new Date(today.getFullYear(), today.getMonth(), 1)) {
            return new Date(today.getFullYear(), today.getMonth(), 16);
          } else {
            return new Date(nextYear, nextMonth, 1);
          }
        } else {
          const nextMonth = today.getMonth() === 11 ? 0 : today.getMonth() + 1;
          const nextYear =
            today.getMonth() === 11
              ? today.getFullYear() + 1
              : today.getFullYear();
          if (today > new Date(today.getFullYear(), today.getMonth(), 16)) {
            return new Date(nextYear, nextMonth, 1);
          } else {
            return new Date(today.getFullYear(), today.getMonth(), 16);
          }
        }
      case 3:
        return new Date(today.getFullYear() + 1, 0, 1);
      case 4:
        return getNextDayOfWeek(today, 1);
      case 5:
        return getNextDayOfWeek(today, 2);
      case 6:
        return getNextDayOfWeek(today, 3);
      case 7:
        return getNextDayOfWeek(today, 4);
      case 8:
        return getNextDayOfWeek(today, 5);
      case 9:
        return getNextDayOfWeek(today, 6);
      case 10:
        return getNextDayOfWeek(today, 7);
      case 11:
        if (today.getDate() < 10) {
          return getNextSelectableDate(today, 10);
        } else if (today.getDate() < 20) {
          return getNextSelectableDate(today, 20);
        } else {
          return getNextSelectableDate(today, 1);
        }
      // ... the rest of your cases ...
      default:
        return today;
    }
  };
  const getNextDayOfWeek = (date, day) => {
    const resultDate = new Date(date);
    resultDate.setDate(date.getDate() + ((7 + day - date.getDay()) % 7));
    return resultDate;
  };
  const getNextSelectableDate = (date, day) => {
    const nextMonth = date.getMonth() === 11 ? 0 : date.getMonth() + 1;
    const nextYear =
      date.getMonth() === 11 ? date.getFullYear() + 1 : date.getFullYear();

    if (date.getDate() < day) {
      if (date > new Date(date.getFullYear(), date.getMonth(), day - 1)) {
        return new Date(nextYear, nextMonth, day);
      } else {
        return new Date(date.getFullYear(), date.getMonth(), day);
      }
    } else {
      if (date > new Date(date.getFullYear(), date.getMonth(), day)) {
        return new Date(nextYear, nextMonth, day);
      } else {
        return new Date(date.getFullYear(), date.getMonth(), day);
      }
    }
  };

  useEffect(() => {
    const currentDate = new Date();

    if (data?.limit_date_start && data?.limit_date_end) {
      const startDate = new Date(data.limit_date_start);
      const endDate = new Date(data.limit_date_end);
      // if (currentDate >= startDate && currentDate <= endDate) {
      //   setSoldOut(false);
      // } else {
      //   setSoldOut(true);
      // }
    }
  }, [data]);


  useEffect(() => {
    axios
      .get(`${APIURL}media/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);

          const specData = res.data.spec;
          setInfo({
            icons: [
              res.data.media_type,
              res.data.form,
              res.data.content,
              res.data.location,
            ],
            tableInfo: specData,
          });

          const sortOptionData = res.data.options.sort(
            (a, b) => a.sort - b.sort
          );
          const updatedOptions = sortOptionData.map((item) => {
            return {
              ...item,
              label: item.name,
              value: item.id,
            };
          });
          setSelectOptions({ ...selectOptions, option: updatedOptions });
          setContent({
            ...content,
            images: res.data.file_data,
          });
          setSelectSet({
            // startDate: dateDefault(res.data.options[0].limit_start_date),
            ...selectSet,
            option_data: res.data.options[0],
            option: res.data.options[0].name,
            // startDate: null,
            period: res.data.options[0].period.split(",")[0],
            quantity: res.data.options[0].amount.split(",")[0],
            price: res.data.options[0].price,
            limitDate: res.data.options[0].limit_start_date,
          });
          const _period = res.data.options[0].period
            .split(",")
            .map((item, index) => {
              return { value: index + 1, label: item };
            });
          const _quantity = res.data.options[0].amount
            .split(",")
            .map((item, index) => {
              return { value: index + 1, label: item };
            });
          setSelectOptions({
            option: updatedOptions,
            period: _period,
            quantity: _quantity,
          });
          setStartDate(dateDefault(res.data.options[0].limit_start_date));
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          iconColor: "#971b4d",
          text: "삭제된 매체입니다.",
          confirmButtonText: "확인",
          confirmButtonColor: "#971b4d",
        }).then((result) => {
          navigate(-1);
        });
      });
    categoryIdInfo();

    accessToken &&
      axios
        .get(`${APIURL}favorite/my/media`, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((res) => {
          if (res.status === 200) setWishList([...res.data]);
        })
        .catch((err) => console.log(err));

  }, []);

  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  const categoryIdInfo = () => {
    axios
      .get(`${APIURL}media/${id}`)
      .then((response) => {
        if (response.status === 200) {
          axios
            .get(`${APIURL}category/one/${response.data.category_id}`)
            .then((res) => {
              const newCategoryId = {
                depth1: "",
                depth1_name: "",
                depth3: "",
                depth3_name: "",
              };
              if (res.status === 200) {
                if (res.data.depth === 3) {
                  axios
                    .get(`${APIURL}category/one/${res.data.parent_id}`)
                    .then((res2) => {
                      if (res2.status === 200) {
                        axios
                          .get(`${APIURL}category/one/${res2.data.parent_id}`)
                          .then((res3) => {
                            if (res3.status === 200) {
                              newCategoryId.depth1 = res3.data.id;
                              newCategoryId.depth1_name = res3.data.name;
                              newCategoryId.depth3 = res.data.id;
                              newCategoryId.depth3_name = res.data.name;
                              setCategoryId(newCategoryId);
                            }
                          })
                          .catch((err3) => console.log(err3));
                      }
                    })
                    .catch((err2) => console.log(err2));
                } else if (res.data.depth === 4) {
                  axios
                    .get(`${APIURL}category/one/${res.data.parent_id}`)
                    .then((res2) => {
                      if (res2.status === 200) {
                        axios
                          .get(`${APIURL}category/one/${res2.data.parent_id}`)
                          .then((res3) => {
                            if (res3.status === 200) {
                              axios
                                .get(
                                  `${APIURL}category/one/${res3.data.parent_id}`
                                )
                                .then((res4) => {
                                  if (res4.status === 200) {
                                    newCategoryId.depth1 = res4.data.id;
                                    newCategoryId.depth1_name = res4.data.name;
                                    newCategoryId.depth3 = res2.data.id;
                                    newCategoryId.depth3_name = res2.data.name;

                                    setCategoryId(newCategoryId);
                                  }
                                })
                                .catch((err4) => console.log(err4));
                            }
                          })
                          .catch((err3) => console.log(err3));
                      }
                    })
                    .catch((err2) => console.log(err2));
                }
              }
            });
        }
        // if (response.status === 200) {
        //   axios
        //     .get(`${APIURL}category/one/${response.data.category_id}`)
        //     .then((res) => {
        //       if (res.status === 200) {
        //         const newCategoryId = {
        //           depth1: "",
        //           depth1_name: "",
        //           depth3: res.data.id,
        //           depth3_name: res.data.name,
        //         };

        //         console.log(res.data.depth)
        //         if (res.data.depth === 3) {
        //           axios
        //             .get(`${APIURL}category/one/${res.data.parent_id}`)
        //             .then((res2) => {
        //               if (res2.status === 200) {
        //                 axios
        //                   .get(`${APIURL}category/one/${res2.data.parent_id}`)
        //                   .then((res3) => {
        //                     if (res3.status === 200) {
        //                       newCategoryId.depth1 = res3.data.id;
        //                       newCategoryId.depth1_name = res3.data.name;

        //                       setCategoryId(newCategoryId);
        //                     }
        //                   })
        //                   .catch((err3) => console.log(err3));
        //               }
        //             })
        //             .catch((err2) => console.log(err2));
        //         } else {
        //           axios
        //             .get(`${APIURL}category/one/${res.data.parent_id}`)
        //             .then((res2) => {
        //               if (res2.status === 200) {
        //                 axios
        //                   .get(`${APIURL}category/one/${res2.data.parent_id}`)
        //                   .then((res3) => {
        //                     if (res3.status === 200) {
        //                       axios
        //                         .get(
        //                           `${APIURL}category/one/${res3.data.parent_id}`
        //                         )
        //                         .then((res4) => {
        //                           if (res4.status === 200) {
        //                             newCategoryId.depth1 = res4.data.id;
        //                             newCategoryId.depth1_name = res4.data.name;

        //                             setCategoryId(newCategoryId);
        //                           }
        //                         })
        //                         .catch((err4) => console.log(err4));
        //                     }
        //                   })
        //                   .catch((err3) => console.log(err3));
        //               }
        //             })
        //             .catch((err2) => console.log(err2));
        //         }
        //       }
        //     })
        //     .catch((err) => console.log(err));
        // }
      })
      .catch((error) => console.log(error));
  };

  const detailRef = useRef(null);

  const [typeGuide, setTypeGuide] = useState({
    show: false,
    eventKey: "",
    media_type: [],
    media_form: [],
    content: [],
    install_place: [],
  });

  const showTypeGuide = (key) => {
    let _key;
    switch (key) {
      case 0:
        _key = "type";
        break;
      case 1:
        _key = "form";
        break;
      case 2:
        _key = "content";
        break;
      case 3:
        _key = "place";
        break;
      default:
        _key = "";
        break;
    }
    setTypeGuide({
      ...typeGuide,
      show: true,
      eventKey: _key,
    });
  };

  const getGuide = () => {
    axios
      .get(`${APIURL}mediadata`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((res) => {
        const _data = res.data;
        setTypeGuide({
          ...typeGuide,
          media_type: _data.media_type,
          media_form: _data.form,
          content: _data.content,
          install_place: _data.location,
        });
      })
      .catch((err) => {
        console.log(err);
      });
      
      if (accessToken) {
        //내 카트 데이터 가져오기
        axios
        .get(`${APIURL}plan`, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data)
            setGetItem({
              ...getItem,
              data: res.data,
            });
          }
        })
      }
  };

  useEffect(() => {
    getGuide();

  }, []);

  return (
    <>
      {data && (
        <div
          className="my-container my-detail-container bg-white"
          style={{ maxWidth: "1557px" }}
        >
          <div className="detail-container w-100">
            {isLaptop && (
              <div className="w-100 over-auto">
                <div
                  className="d-flex align-items-center detail_info_title"
                  ref={detailRef}
                >
                  <div
                    className="pointer position-absolute"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}image/icon/back_black.svg`}
                      style={{ width: "14px", height: "12px" }}
                    />
                  </div>
                  <div
                    className="fw-800 px-3 text-center w-100"
                    style={{ fontSize: "25px" }}
                  >
                    {data.media_name}
                  </div>
                </div>
                {typeGuide.show && (
                  <div className="position-relative">
                    <div className={`${styles.guide_wrapper}`}>
                      <div className="d-flex">
                        <img
                          src={`${process.env.PUBLIC_URL}image/icon/close_button.svg`}
                          alt=""
                          className="ms-auto pointer"
                          onClick={() => {
                            setTypeGuide({
                              ...typeGuide,
                              show: false,
                            });
                          }}
                        />
                      </div>
                      <Tabs
                        defaultActiveKey={typeGuide.eventKey}
                        className="mb-3 guide_tap mt-2"
                        fill
                        onMouseDown={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        <Tab
                          eventKey="type"
                          title="매체 타입"
                          className="bg-transparent text-dark"
                          onMouseDown={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                        >
                          <div className={`scroll ${styles.guide_tab}`}>
                            {typeGuide.media_type.map((item, idx) => (
                              <div key={`type${idx}`}>
                                <div className="d-flex gap-2">
                                  {item.image !== "" && (
                                    <div className={styles.type_img_wrapper}>
                                      <img
                                        src={`${DEFAULTURL}image/site/${item.image}`}
                                        alt=""
                                      />
                                    </div>
                                  )}
                                  <div>
                                    <div className={`fw-700 ${styles.fs_12px}`}>
                                      {item.title}
                                    </div>
                                    <div className={styles.fs_9px}>
                                      {item.content}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Tab>
                        <Tab
                          eventKey="form"
                          title="매체 형태"
                          className="bg-transparent text-dark"
                        >
                          <div className={`scroll ${styles.guide_tab}`}>
                            {typeGuide.media_form.map((item, idx) => (
                              <div key={`form${idx}`}>
                                <div className="d-flex gap-2">
                                  {item.image !== "" && (
                                    <div className={styles.type_img_wrapper}>
                                      <img
                                        src={`${DEFAULTURL}image/site/${item.image}`}
                                        alt=""
                                      />
                                    </div>
                                  )}
                                  <div>
                                    <div className={`fw-700 ${styles.fs_12px}`}>
                                      {item.title}
                                    </div>
                                    <div className={styles.fs_9px}>
                                      {item.content}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Tab>
                        <Tab
                          eventKey="content"
                          title="컨텐츠"
                          className="bg-transparent text-dark"
                        >
                          <div className={`scroll ${styles.guide_tab}`}>
                            {typeGuide.content.map((item, idx) => (
                              <div key={`content${idx}`}>
                                <div className="d-flex gap-2">
                                  {item.image !== "" && (
                                    <div className={styles.type_img_wrapper}>
                                      <img
                                        src={`${DEFAULTURL}image/site/${item.image}`}
                                        alt=""
                                      />
                                    </div>
                                  )}
                                  <div>
                                    <div className={`fw-700 ${styles.fs_12px}`}>
                                      {item.title}
                                    </div>
                                    <div className={styles.fs_9px}>
                                      {item.content}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Tab>
                        <Tab
                          eventKey="place"
                          title="설치 장소"
                          className="bg-transparent text-dark"
                        >
                          <div className={`scroll ${styles.guide_tab}`}>
                            {typeGuide.install_place.map((item, idx) => (
                              <div key={`place${idx}`}>
                                <div className="d-flex gap-2">
                                  {item.image !== "" && (
                                    <div className={styles.type_img_wrapper}>
                                      <img
                                        src={`${DEFAULTURL}image/site/${item.image}`}
                                        alt=""
                                      />
                                    </div>
                                  )}
                                  <div>
                                    <div className={`fw-700 ${styles.fs_12px}`}>
                                      {item.title}
                                    </div>
                                    <div className={styles.fs_9px}>
                                      {item.content}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                )}
                <div className={`${styles.tag_whole_group} detail_info_tag`}>
                  <img
                    src={`${process.env.PUBLIC_URL}image/icon/questionCircle.svg`}
                    style={{ width: "14px", height: "14px" }}
                    onClick={() => {
                      showTypeGuide(0);
                    }}
                    onBlur={() => {
                      setTypeGuide({
                        ...typeGuide,
                        show: false,
                      });
                    }}
                    tabIndex={0}
                  />
                  <div
                    className={`${styles.tag_group} ${styles.bd_bottom_dashed} d-flex ${styles.tag_div}`}
                  >
                    {info.icons.map((item, index) => (
                      <div className="fw-400 p-0" key={`icon_${index}`}>
                        <div
                          className={`${styles.tag_color} ${getBgClass(index)}`}
                          onClick={() => {
                            showTypeGuide(index);
                          }}
                          onBlur={() => {
                            setTypeGuide({
                              ...typeGuide,
                              show: false,
                            });
                          }}
                          tabIndex={0}
                        >
                          {item}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className={`detail_info_price`}>
                  <div
                    className={`scroll ${styles.text_info_area}`}
                    dangerouslySetInnerHTML={{ __html: info.tableInfo }}
                  ></div>
                  <div
                    className={`text-center fw-800 ${styles.fs_14px} ${styles.pt_33px}`}
                  >
                    옵션선택
                  </div>
                  <div className="pt-1">
                    <CustomSelect
                      setSelectSet={setSelectSet}
                      selectSet={selectSet}
                      name={"option"}
                      setStartDate={setStartDate}
                      selectEa={selectEa}
                      setSelectEa={setSelectEa}
                      options={selectOptions}
                      setSelectOptions={setSelectOptions}
                    />
                  </div>
                  <Row className={styles.row}>
                    <Col xs={4} xxl={6} className="d-flex flex-column">
                      <div
                        className={`${styles.fs_14px} ${styles.ppt_9px} text-center fw-900 fs-8px pb-1`}
                      >
                        시작일
                      </div>
                      <DateHolder
                        selectSet={selectSet}
                        startDate={startDate}
                        setStartDate={setStartDate}
                      />
                    </Col>
                    <Col xs={4} xxl={3} className="">
                      <div
                        className={`${styles.fs_14px} ${styles.ppt_9px} text-center fw-900 fs-8px pb-1`}
                      >
                        기간
                      </div>
                      <Selectbox
                        options={selectOptions}
                        setSelectOptions={setSelectOptions}
                        name={"period"}
                        setSelectSet={setSelectSet}
                        selectSet={selectSet}
                        selectEa={selectEa}
                        setSelectEa={setSelectEa}
                      />
                    </Col>
                    <Col xs={4} xxl={3} className="">
                      <div
                        className={`${styles.fs_14px} ${styles.ppt_9px} text-center fw-900 fs-8px pb-1`}
                      >
                        수량
                      </div>
                      <Selectbox
                        options={selectOptions}
                        setSelectOptions={setSelectOptions}
                        name={"quantity"}
                        setSelectSet={setSelectSet}
                        selectSet={selectSet}
                        selectEa={selectEa}
                        setSelectEa={setSelectEa}
                      />
                    </Col>
                  </Row>

                  {/* <div
                    className={`${styles.fs_11px} text-center text-gray fw-700`}
                  >
                    옵션, 시작일, 기간, 수량은 카트에서도 수정 가능합니다.
                  </div> */}
                </div>

                <div
                  className="detail_info_result"
                  style={{
                    top: detailRef.current?.offsetHeight - 16 + "px",
                  }}
                >
                  <div className="price_wrapper">
                    <div
                      className={`${styles.fs_11px} fw-700 text-center`}
                      style={{ marginBottom: "4px" }}
                    >
                      {selectSet.option}
                    </div>

                    <div className={`${styles.fs_11px} fw-700 text-center`}>
                      <span>{selectSet.period} </span>
                      <span>{selectSet.quantity}</span>
                    </div>
                    {selectSet.option_data && (
                      <div className={`text-center`}>
                        <div className="d-flex justify-content-center align-items-end flex-wrap">
                          {selectSet.option_data.install_price !== "0" && (
                            <div
                              className={`${styles.fs_13px} text-gray me-1 fw-400`}
                            >
                              광고료
                            </div>
                          )}

                          <div className={`${styles.fs_20px} fw-800`}>
                            {isNaN(Number(selectSet.price)) ? (
                              <div>별도안내</div>
                            ) : (
                              <div>
                                {Number(
                                  selectSet.price *
                                    selectEa.period *
                                    selectEa.quantity
                                ).toLocaleString()}
                                원
                              </div>
                            )}
                          </div>
                        </div>
                        {selectSet.option_data.install_price !== "0" && (
                          <div
                            className={`d-flex justify-content-center align-items-end flex-wrap`}
                          >
                            <div
                              className={`${styles.fs_13px} text-gray me-1 fw-400`}
                            >
                              출력/설치비
                            </div>
                            <div className={`${styles.fs_20px} fw-800`}>
                              {isNaN(
                                Number(selectSet.option_data.install_price)
                              ) ? (
                                <div>별도안내</div>
                              ) : (
                                <div>
                                  {Number(
                                    selectSet.option_data.install_price *
                                      selectEa.quantity
                                  ).toLocaleString()}
                                  원
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        <div className={`${styles.fs_11px} text-gray fw-700`}>
                          부가세 (VAT) 별도
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={`button_wrapper`} style={{ gap: "3px" }}>
                    <div className="d-flex gap-3px">
                      <div className={styles.buttonGroup}>
                        <img
                          src={
                            wishList.includes(data.id)
                              ? `${process.env.PUBLIC_URL}image/icon/wishVariant.svg`
                              : `${process.env.PUBLIC_URL}image/icon/wishDefault.svg`
                          }
                          alt="wish"
                          className="pointer"
                          onClick={() => {
                            handleWish(wishList.includes(data.id));
                          }}
                        />
                      </div>
                      <div
                        className={`d-flex align-items-center justify-content-center ${styles.buttonGroup}`}
                        style={{
                          backgroundColor: "#C0BFBE",
                          borderRadius: "6px",
                        }}
                      >
                        <BiSolidShareAlt
                          color={"#fff"}
                          size={30}
                          className="fs-20px pointer text-gray"
                          onClick={() => {
                            toClipboard(`baseUrl${location.pathname}`);
                          }}
                        />
                      </div>
                    </div>
                    {/* {soldOut ? (
                      <div
                        className={`${styles.px_30px} bg-dark-gray text-white d-flex align-items-center bd-radius-5px py-7px`}
                      >
                        <div
                          className={`${styles.w_97px} ${styles.fs_17px} text-center fw-700`}
                        >
                          매진
                        </div>
                      </div>
                    ) : ( */}

                    <div
                      className={`${styles.px_30px} bg-purple text-white d-flex align-items-center pointer bd-radius-5px py-7px`}
                      onClick={cartSubmit}
                      onBlur={() => {
                        setGetItem({
                          ...getItem,
                          show: false,
                        });
                      }}
                      tabIndex={0}
                    >
                      <div
                        className={`${styles.w_97px} text-center fw-700`}
                        style={{ fontSize: "13px" }}
                      >
                        카트에 담기
                      </div>
                    </div>
                    {/* )} */}
                    <div
                      className={`${styles.mobile_plan_modal} position-absolute`}
                    >
                      {getItem.show && (
                        <div className={`${styles.plan_modal}`}>
                          <div className="d-flex align-items-center">
                            <div
                              className={`fw-800 ${styles.fs_14px} white-nowrap pe-2 ${styles.plan_title}`}
                            >
                              아래 플랜에 매체 담기
                            </div>
                            <img
                              src={`${process.env.PUBLIC_URL}image/icon/close_button.svg`}
                              alt=""
                              className="ms-auto pointer"
                            />
                          </div>
                          {getItem.data.self_list &&
                            getItem.data.self_list.length > 0 && (
                              <div
                                className={`${styles.fs_10px} ${styles.py_20px} fw-800 pointer d-flex flex-column`}
                                style={{ gap: "7px 0" }}
                              >
                                {getItem.data.self_list.map((item) => (
                                  <div
                                    key={item.id}
                                    onMouseDown={() => currentCart(item)}
                                    // className="white-nowrap"
                                  >
                                    {item.name}
                                  </div>
                                ))}
                              </div>
                            )}
                          {getItem.data.rialto &&
                            getItem.data.rialto.length > 0 && (
                              <div
                                className={`${styles.fs_10px} ${styles.py_20px} fw-800 pointer d-flex flex-column`}
                                style={{ gap: "7px 0" }}
                              >
                                {getItem.data.rialto.map((item) => (
                                  <div
                                    className="main"
                                    key={item.id}
                                    onClick={() => currentCart(item)}
                                  >
                                    {item.name}
                                  </div>
                                ))}
                              </div>
                            )}
                          <div
                            className={`${styles.fs_10px} fw-800 pointer`}
                            onMouseDown={newCart}
                          >
                            + 새 플랜을 생성하여 담기
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={`detail-info-container`}>
                  <div
                    className={`${styles.scroll_container} ${styles.detail_scroll} scroll bg-white w-100`}
                  >
                    <MySlider
                      imageData={content.images}
                      setSelectedItemIndex={setSelectedItemIndex}
                      selectedItemIndex={selectedItemIndex}
                      data={data}
                      isLaptop={isLaptop}
                      isMobile={isMobile}
                      setTypeGuide={setTypeGuide}
                      typeGuide={typeGuide}
                      setGetItem={setGetItem}
                      getItem={getItem}
                    />
                    <div
                      className={styles.detail_info}
                      style={{ paddingTop: 0 }}
                      dangerouslySetInnerHTML={{
                        __html: data.media_detail_info,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
            {!isLaptop && (
              <div
                className={`${styles.detail_option_container} scroll detail-option-container position-relative detail-option-scroll-container`}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <div
                    className={`${styles.back_button}`}
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}image/icon/backIcon.svg`}
                      style={{ width: "18px", height: "17px" }}
                      alt=""
                    />
                  </div>
                  <div className={styles.media_name_width}>
                    <div className={`${styles.media_detail_name}`}>
                      {data.media_name}
                    </div>
                  </div>
                </div>

                {typeGuide.show && (
                  <div className="position-relative">
                    <div className={`${styles.guide_wrapper}`}>
                      <div className="d-flex align-items-start">
                        <div className="w-100">
                          <Tabs
                            defaultActiveKey={typeGuide.eventKey}
                            className="mb-3 guide_tap mt-2"
                            fill
                            onMouseDown={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                          >
                            <Tab
                              eventKey="type"
                              title="매체 타입"
                              className="bg-transparent text-dark"
                            >
                              <div className={`scroll ${styles.guide_tab}`}>
                                {typeGuide.media_type.map((item, idx) => (
                                  <div key={`type${idx}`}>
                                    <div className="d-flex gap-2">
                                      {item.image !== "" && (
                                        <div
                                          className={styles.type_img_wrapper}
                                        >
                                          <img
                                            src={`${DEFAULTURL}image/site/${item.image}`}
                                            alt=""
                                          />
                                        </div>
                                      )}
                                      <div>
                                        <div
                                          className={`fw-700 ${styles.fs_12px}`}
                                        >
                                          {item.title}
                                        </div>
                                        <div className={styles.fs_9px}>
                                          {item.content}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </Tab>
                            <Tab
                              eventKey="form"
                              title="매체 형태"
                              className="bg-transparent text-dark"
                            >
                              <div className={`scroll ${styles.guide_tab}`}>
                                {typeGuide.media_form.map((item, idx) => (
                                  <div key={`form${idx}`}>
                                    <div className="d-flex gap-2">
                                      {item.image !== "" && (
                                        <div
                                          className={styles.type_img_wrapper}
                                        >
                                          <img
                                            src={`${DEFAULTURL}image/site/${item.image}`}
                                            alt=""
                                          />
                                        </div>
                                      )}
                                      <div>
                                        <div
                                          className={`fw-700 ${styles.fs_12px}`}
                                        >
                                          {item.title}
                                        </div>
                                        <div className={styles.fs_9px}>
                                          {item.content}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </Tab>
                            <Tab
                              eventKey="content"
                              title="컨텐츠"
                              className="bg-transparent text-dark"
                            >
                              <div className={`scroll ${styles.guide_tab}`}>
                                {typeGuide.content.map((item, idx) => (
                                  <div key={`content${idx}`}>
                                    <div className="d-flex gap-2">
                                      {item.image !== "" && (
                                        <div
                                          className={styles.type_img_wrapper}
                                        >
                                          <img
                                            src={`${DEFAULTURL}image/site/${item.image}`}
                                            alt=""
                                          />
                                        </div>
                                      )}
                                      <div>
                                        <div
                                          className={`fw-700 ${styles.fs_12px}`}
                                        >
                                          {item.title}
                                        </div>
                                        <div className={styles.fs_9px}>
                                          {item.content}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </Tab>
                            <Tab
                              eventKey="place"
                              title="설치 장소"
                              className="bg-transparent text-dark"
                            >
                              <div className={`scroll ${styles.guide_tab}`}>
                                {typeGuide.install_place.map((item, idx) => (
                                  <div key={`place${idx}`}>
                                    <div className="d-flex gap-2">
                                      {item.image !== "" && (
                                        <div
                                          className={styles.type_img_wrapper}
                                        >
                                          <img
                                            src={`${DEFAULTURL}image/site/${item.image}`}
                                            alt=""
                                          />
                                        </div>
                                      )}
                                      <div>
                                        <div
                                          className={`fw-700 ${styles.fs_12px}`}
                                        >
                                          {item.title}
                                        </div>
                                        <div className={styles.fs_9px}>
                                          {item.content}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              {/* <div className={`scroll ${styles.guide_tab}`}>
                              {typeGuide.install_place.map((item, idx) => (
                                <div key={`place${idx}`}>
                                  <div className={styles.fs_14px}>
                                    {item.title}
                                  </div>
                                  <div className="d-flex gap-2">
                                    {item.image !== "" && (
                                      <div className={styles.type_img_wrapper}>
                                        <img
                                          src={`${DEFAULTURL}image/site/${item.image}`}
                                          alt=""
                                        />
                                      </div>
                                    )}
                                    <div>{item.content}</div>
                                  </div>
                                </div>
                              ))}
                            </div> */}
                            </Tab>
                          </Tabs>
                        </div>
                        <img
                          src={`${process.env.PUBLIC_URL}image/icon/close_button.svg`}
                          alt=""
                          className="pointer pt-3 ps-2"
                          onClick={() => {
                            setTypeGuide({
                              ...typeGuide,
                              show: false,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className={`${styles.tag_whole_group}`}>
                  <img
                    src={`${process.env.PUBLIC_URL}image/icon/questionCircle.svg`}
                    style={{ width: "14px", height: "14px" }}
                    className={`${styles.cursor_help}`}
                    alt=""
                    onClick={() => {
                      showTypeGuide(0);
                    }}
                    onBlur={() => {
                      setTypeGuide({
                        ...typeGuide,
                        show: false,
                      });
                    }}
                    tabIndex={0}
                  />
                  <div
                    className={`${styles.tag_group} ${styles.bd_bottom_dashed} d-flex ${styles.tag_div}`}
                  >
                    {info.icons.map((item, index) => (
                      <div className="fw-400 p-0" key={`icon_${index}`}>
                        <div
                          className={`${styles.tag_color} ${
                            styles.cursor_help
                          } ${getBgClass(index)}`}
                          onClick={() => {
                            showTypeGuide(index);
                          }}
                          onBlur={() => {
                            setTypeGuide({
                              ...typeGuide,
                              show: false,
                            });
                          }}
                          tabIndex={0}
                        >
                          {item}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={`ps-4 ${styles.detail_mobile_content}`}>
                  <div
                    className={styles.text_info_area}
                    dangerouslySetInnerHTML={{ __html: info.tableInfo }}
                  ></div>
                  <div
                    className={`text-center fw-800 ${styles.fs_14px} ${styles.pt_33px}`}
                  >
                    옵션선택
                  </div>
                  <div className="pt-1">
                    <CustomSelect
                      setSelectSet={setSelectSet}
                      selectSet={selectSet}
                      name={"option"}
                      setStartDate={setStartDate}
                      selectEa={selectEa}
                      setSelectEa={setSelectEa}
                      options={selectOptions}
                      setSelectOptions={setSelectOptions}
                    />
                  </div>

                  <div className={`${styles.pt_10px} d-flex`}>
                    <div className={`flex-grow-1`}>
                      <div
                        className={`${styles.fs_14px} text-center fw-800 pb-1`}
                      >
                        시작일
                      </div>
                      <DateHolder
                        selectSet={selectSet}
                        startDate={startDate}
                        setStartDate={setStartDate}
                      />
                    </div>
                    <div className={`${styles.option_ea}`}>
                      <div
                        className={`${styles.fs_14px} text-center fw-800 pb-1`}
                      >
                        기간
                      </div>
                      <Selectbox
                        options={selectOptions}
                        setSelectOptions={setSelectOptions}
                        name={"period"}
                        setSelectSet={setSelectSet}
                        selectSet={selectSet}
                        selectEa={selectEa}
                        setSelectEa={setSelectEa}
                      />
                    </div>
                    <div className={`${styles.option_ea}`}>
                      <div
                        className={`${styles.fs_14px} text-center fw-800 pb-1`}
                      >
                        수량
                      </div>
                      <Selectbox
                        options={selectOptions}
                        setSelectOptions={setSelectOptions}
                        name={"quantity"}
                        setSelectSet={setSelectSet}
                        selectSet={selectSet}
                        selectEa={selectEa}
                        setSelectEa={setSelectEa}
                      />
                    </div>
                  </div>
                  {/* <div
                    className={`${styles.fs_11px} ${styles.ppt_4px} text-center text-gray fw-700`}
                  >
                    옵션, 시작일, 기간, 수량은 카트에서도 수정 가능합니다.
                  </div> */}

                  <>
                    <div className={`${styles.mt_4vh}`}>
                      <div
                        className={`${styles.fs_11px} fw-700 text-center`}
                        style={{ marginBottom: "4px" }}
                      >
                        {selectSet.option}
                      </div>

                      <div className={`${styles.fs_11px} fw-700 text-center`}>
                        <span>{selectSet.period} </span>
                        <span>{selectSet.quantity}</span>
                      </div>
                      {selectSet.option_data && (
                        <div className={`text-center`}>
                          <div className="d-flex justify-content-center align-items-end">
                            {selectSet.option_data.install_price !== "0" && (
                              <div
                                className={`${styles.fs_13px} text-gray me-1 fw-400`}
                              >
                                광고료
                              </div>
                            )}

                            <div className={`${styles.fs_20px} fw-800`}>
                              {isNaN(Number(selectSet.price)) ? (
                                <div>별도안내</div>
                              ) : (
                                <div>
                                  {Number(
                                    selectSet.price *
                                      selectEa.period *
                                      selectEa.quantity
                                  ).toLocaleString()}
                                  원
                                </div>
                              )}
                            </div>
                          </div>
                          {selectSet.option_data.install_price !== "0" && (
                            <div
                              className={`d-flex justify-content-center align-items-end`}
                            >
                              <div
                                className={`${styles.fs_13px} text-gray me-1 fw-400`}
                              >
                                출력/설치비
                              </div>
                              <div className={`${styles.fs_20px} fw-800`}>
                                {isNaN(
                                  Number(selectSet.option_data.install_price)
                                ) ? (
                                  <div>별도안내</div>
                                ) : (
                                  <div>
                                    {Number(
                                      selectSet.option_data.install_price *
                                        selectEa.quantity
                                    ).toLocaleString()}
                                    원
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                          <div className={`${styles.fs_11px} text-gray fw-700`}>
                            부가세 (VAT) 별도
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className={`${styles.pt_13px} d-flex gap-2 w-100 justify-content-center pb-5 position-relative`}
                    >
                      <div className={`${styles.buttonGroup}`}>
                        <img
                          src={
                            wishList.includes(data.id)
                              ? `${process.env.PUBLIC_URL}image/icon/wishVariant.svg`
                              : `${process.env.PUBLIC_URL}image/icon/wishDefault.svg`
                          }
                          alt="wish"
                          className="pointer"
                          onClick={() => {
                            handleWish(wishList.includes(data.id));
                          }}
                        />
                      </div>
                      <div
                        className={`d-flex align-items-center justify-content-center ${styles.buttonGroup}`}
                        style={{
                          backgroundColor: "#C0BFBE",
                          borderRadius: "6px",
                        }}
                      >
                        <BiSolidShareAlt
                          color={"#fff"}
                          size={30}
                          className="fs-20px pointer text-gray"
                          onClick={() => {
                            toClipboard(`baseUrl${location.pathname}`);
                          }}
                        />
                      </div>
                      {/* {soldOut ? (
                        <div
                          className={`${styles.px_30px} bg-dark-gray text-white d-flex align-items-center bd-radius-5px`}
                        >
                          <div
                            className={`${styles.w_97px} ${styles.fs_17px} text-center fw-700`}
                          >
                            매진
                          </div>
                        </div>
                      ) : ( */}
                      <div>
                        <div>
                          {getItem.show && (
                            <div className={`${styles.plan_modal}`}
                              >
                              <div className="d-flex align-items-center">
                                <div
                                  className={`fw-800 ${styles.fs_14px} white-nowrap pe-2 ${styles.plan_title}`}
                                >
                                  아래 플랜에 매체 담기
                                </div>
                                <img
                                  src={`${process.env.PUBLIC_URL}image/icon/close_button.svg`}
                                  alt=""
                                  className="ms-auto pointer"
                                  // onClick={() => {
                                  //   setGetItem({
                                  //     ...getItem,
                                  //     show: false,
                                  //   });
                                  // }}
                                />
                              </div>
                              {getItem.data.self_list &&
                                getItem.data.self_list.length > 0 && (
                                  <div
                                    className={`${styles.fs_10px} ${styles.py_20px} fw-800 pointer d-flex flex-column`}
                                    style={{ gap: "7px 0" }}
                                  >
                                    {getItem.data.self_list.map((item) => (
                                      <div
                                        key={item.id}
                                        onMouseDown={() => currentCart(item)}
                                        // className="white-nowrap"
                                      >
                                        {item.name}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              {getItem.data.rialto &&
                                getItem.data.rialto.length > 0 && (
                                  <div
                                    className={`${styles.fs_10px} ${styles.py_20px} fw-800 pointer d-flex flex-column`}
                                    style={{ gap: "7px 0" }}
                                  >
                                    {getItem.data.rialto.map((item) => (
                                      <div
                                        className="main"
                                        key={item.id}
                                        onMouseDown={() => currentCart(item)}
                                      >
                                        {item.name}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              <div
                                className={`${styles.fs_10px} fw-800 pointer`}
                                onMouseDown={newCart}
                              >
                                + 새 플랜을 생성하여 담기
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          className={`${styles.px_30px} bg-purple text-white d-flex align-items-center pointer bd-radius-5px h-100`}
                          onClick={cartSubmit}
                          onBlur={() => {
                            setGetItem({
                              ...getItem,
                              show: false,
                            });
                          }}
                          tabIndex={0}
                        >
                          <div
                            className={`${styles.w_97px} ${styles.fs_17px} text-center fw-700`}
                          >
                            카트에 담기
                          </div>
                        </div>
                      </div>
                      {/* )} */}
                    </div>
                  </>
                </div>
              </div>
            )}
            {!isLaptop && (
              <>
                <div className="bg-light-gray ps-10px"></div>
                <div className={`detail-info-container`}>
                  <div
                    className={`${styles.scroll_container} ${styles.detail_scroll} scroll w-100`}
                  >
                    <MySlider
                      imageData={content.images}
                      setSelectedItemIndex={setSelectedItemIndex}
                      selectedItemIndex={selectedItemIndex}
                      data={data}
                      isLaptop={isLaptop}
                      isMobile={isMobile}
                      setTypeGuide={setTypeGuide}
                      typeGuide={typeGuide}
                      setGetItem={setGetItem}
                      getItem={getItem}
                    />
                    <div
                      className={styles.detail_info}
                      dangerouslySetInnerHTML={{
                        __html: data.media_detail_info,
                      }}
                    ></div>
                  </div>
                </div>
              </>
            )}

            <CartModal
              // show={getItem.show}
              size={"cart-modal"}
              className={styles.cart_wrapper}
              onHide={() => {
                setGetItem({
                  ...getItem,
                  show: false,
                });
              }}
            >
              <div className="d-flex">
                <div
                  className={`fw-800 ${styles.fs_14px} ${styles.plan_title}`}
                >
                  아래 플랜에 매체 담기
                </div>
                <img
                  src={`${process.env.PUBLIC_URL}image/icon/close_button.svg`}
                  alt=""
                  className="ms-auto pointer"
                />
              </div>
              {getItem.data.self_list && getItem.data.self_list.length > 0 && (
                <div
                  className={`${styles.fs_10px} ${styles.py_20px} fw-800 pointer d-flex flex-column`}
                  style={{ gap: "3px 0" }}
                >
                  {getItem.data.self_list.map((item) => (
                    <div key={item.id} onClick={() => currentCart(item)}>
                      {item.name}
                    </div>
                  ))}
                </div>
              )}
              {getItem.data.rialto && getItem.data.rialto.length > 0 && (
                <div
                  className={`${styles.fs_10px} ${styles.py_20px} fw-800 pointer d-flex flex-column`}
                  style={{ gap: "3px 0" }}
                >
                  {getItem.data.rialto.map((item) => (
                    <div
                      className="main"
                      key={item.id}
                      onClick={() => currentCart(item)}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              )}
              <div
                className={`${styles.fs_10px} fw-800 pointer`}
                onClick={newCart}
              >
                + 새 플랜을 생성하여 담기
              </div>
            </CartModal>
          </div>
        </div>
      ) 
      // : 
      //   <div className="my-container">
      //     <Loading/>
      //   </div>
      }
    </>
  );
}

const MySlider = ({ ...props }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { naver } = window;
  const panoramaRefs = useRef([]);
  const mapElementRef = useRef(null);
  const mapRef = useRef(null);
  const [marker, setMarker] = useState(null);
  const [markerSize, setMarkerSize] = useState(10);

  const {
    imageData,
    setSelectedItemIndex,
    selectedItemIndex,
    data,
    isLaptop,
    isMobile,
    setTypeGuide,
    typeGuide,
    setGetItem,
    getItem,
  } = props;

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class="${className}"></span>`;
    },
  };

  function getYouTubeVideoId(url) {
    const regExp =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/|v\/)?([a-zA-Z0-9\-_]+)/;
    const match = url.match(regExp);
    return match && match[1];
  }

  useEffect(() => {
    axios.get(`${APIURL}map/setting`).then((res) => {
      setMarkerSize(res.data);
    });
  }, []);

  useEffect(() => {
    setDefault();
  }, [markerSize]);

  function setDefault() {
    if (panoramaRefs.current.length > 0) {
      for (const _ref of panoramaRefs.current) {
        if (_ref !== null) {
          const _data = _ref.dataset.item.split(",");
          const pano = new naver.maps.Panorama(_ref, {
            position: new naver.maps.LatLng(data.lat, data.lon),
            panoId: _data[0],
            pov: {
              pan: _data[1],
              tilt: _data[2],
              fov: _data[3],
            },
            logoControl: false,
            logoControlOptions: {
              position: naver.maps.Position.RIGHT_BOTTOM,
            },
          });
        }
      }
    }

    if (mapElementRef.current) {
      const mapLatLng = new naver.maps.LatLng(data.lat, data.lon);

      const mapOptions = {
        center: mapLatLng,
        zoom: 17,
        maxZoom: 19,
        mapDataControl: false,
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: naver.maps.Position.RIGHT_TOP,
        },
        zoomControl: isMobile ? false : true,
        zoomControlOptions: {
          position: naver.maps.Position.RIGHT_BOTTOM,
        },
        logoControl: true,
        logoControlOptions: {
          position: naver.maps.Position.LEFT_BOTTOM,
        },
        scaleControl: true,
        scaleControlOptions: {
          position: naver.maps.Position.LEFT_BOTTOM,
        },
      };

      mapRef.current = new naver.maps.Map(mapElementRef.current, mapOptions);

      makeMarker();
    }
  }

  function makeMarker() {
    if (marker) {
      marker.setMap(null);
    }

    if (mapRef.current) {
      const zoomLevel = mapRef.current.getZoom();

      if (data.id === 28) {
        // G-라이트 커스텀 마커
        const gLightOptionList = {
          zoom_11: [10, 1, 1, 2],
          zoom_12: [15, 1, 1, 2],
          zoom_13: [40, 1, 1, 2],
          zoom_14: [70, 1, 1, 2],
          zoom_15: [130, 1, 1, 2],
          zoom_16: [260, 1, 2, 3],
          zoom_17: [530, 3, 3, 6],
          zoom_18: [1050, 4, 4, 10],
          zoom_19: [2000, 5, 5, 15],
        };

        const gLightOption =
          zoomLevel > 10
            ? gLightOptionList[`zoom_${zoomLevel}`]
            : [zoomLevel / 2, 1, 1, 2];

        const _content = `<div
        style="
          position: absolute;
          top: -2px;
          left: -${gLightOption[0] / 2}px;
          width: ${gLightOption[0]}px;
          padding: ${gLightOption[1]}px;
          border: ${gLightOption[2]}px dashed #FF0000;
          background-color: transparent;
          transform: rotate(69.5deg);
        ">
          <div
            style="
              position: relative;
              width: 100%;
              height: ${gLightOption[3]}px;
              background-color: transparent;
            "></div>
        </div>`;

        const _div = document.createElement("div");
        _div.innerHTML = _content;
        document.body.append(_div);
        _div.style.width = "max-content";
        const _contentWidth = _div.clientWidth;
        const _contentHeight = _div.clientHeight;
        document.body.removeChild(_div);

        const position = new naver.maps.LatLng(data.lat, data.lon);

        const _marker = new naver.maps.Marker({
          id: data.id,
          categoryId: data.category_id,
          map: mapRef.current,
          position: position,
          title: data.media_name,
          icon: {
            content: [_content].join(""),
            anchor: new naver.maps.Point(_contentWidth / 2, _contentHeight / 2),
          },
          zIndex: 100,
        });

        _marker.setMap(mapRef.current);
        setMarker(_marker);
      } else {
        let _markerImage = data.marker;
        let _markerSize = 10;

        if (data.id === 1) {
          // 케이팝 라이브 커스텀 마커
          _markerImage = "marker_kpop.png";
          const _markerSizeMap = {
            zoom_15: 22,
            zoom_16: 45,
            zoom_17: 70,
            zoom_18: 150,
            zoom_19: 320,
          };

          if (zoomLevel > 14) {
            _markerSize = _markerSizeMap[`zoom_${zoomLevel}`];
          } else {
            _markerSize = zoomLevel / 2;
          }
        } else if (data.id === 2) {
          // H-라이브 커스텀 마커
          _markerImage = "marker_hyundai.png";
          const _markerSizeMap = {
            zoom_15: 20,
            zoom_16: 45,
            zoom_17: 110,
            zoom_18: 220,
            zoom_19: 450,
          };

          if (zoomLevel > 14) {
            _markerSize = _markerSizeMap[`zoom_${zoomLevel}`];
          } else {
            _markerSize = zoomLevel / 2;
          }
        } else {
          // 그 외 마커
          if (zoomLevel > 14) {
            _markerSize = markerSize[`marker${zoomLevel}`];
          } else {
            _markerSize = zoomLevel / 2;
          }
        }

        const _content = `<div style="position: relative;">
            <img src="https://admin.rialto.kr/image/marker/${_markerImage}" alt="" style="width: ${_markerSize}px; transform: rotate(${data.rotate}deg);" />
          </div>`;

        const _div = document.createElement("div");
        _div.innerHTML = _content;
        document.body.append(_div);
        _div.style.width = "max-content";
        const _contentWidth = _div.clientWidth;
        const _contentHeight = _div.clientHeight;
        document.body.removeChild(_div);

        const position = new naver.maps.LatLng(data.lat, data.lon);

        const _marker = new naver.maps.Marker({
          id: data.id,
          categoryId: data.category_id,
          map: mapElementRef.current,
          position: position,
          title: data.media_name,
          icon: {
            content: [_content].join(""),
            anchor: new naver.maps.Point(_contentWidth / 2, _contentHeight / 2),
          },
          zIndex: 100,
        });

        _marker.setMap(mapRef.current);
        setMarker(_marker);
      }
    }
  }

  useEffect(() => {
    // naver.maps.Event.clearInstanceListeners(mapRef.current, 'zoom_changed');

    if (marker) {
      const _listener = naver.maps.Event.addListener(
        mapRef.current,
        "zoom_changed",
        debounce((_zoomLevel) => {
          makeMarker();
        })
      );

      return () => {
        naver.maps.Event.removeListener(_listener);
      };
    }
  }, [marker]);

  const debounce = (fn) => {
    let _timer;
    return (...args) => {
      clearTimeout(_timer);
      _timer = setTimeout(() => {
        fn.apply(this, args);
      }, 100);
    };
  };

  const videoRef = useRef(null);

  const handleSlideChange = () => {
    if (videoRef.current) {
      videoRef.current.src = videoRef.current.src;

      const _videos = document.querySelectorAll("embed");
      _videos.forEach((e) => {
        e.src = e.src;
      });
    }
  };

  const fileDown = (_file, _origin) => {
    // axios
    //   .get(`${APIURL}download/${_file}?origin_name=${_origin}`, {
    //     responseType: "blob",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then((res) => {
    //     if (res.status === 200) {
    //       let contentType = res.data.type;
    //       const blob = new Blob([res.data], { type: contentType });
    //       const fileUrl = window.URL.createObjectURL(blob);
    //       const link = document.createElement("a");
    //       link.href = fileUrl;
    //       link.style.display = "none";
    //       link.download = _origin;
    //       document.body.appendChild(link);
    //       link.click();
    //       link.remove();
    //       window.URL.revokeObjectURL(fileUrl);
    //     }
    //   })
    //   .catch((err) => console.log(err));
  };

  panoramaRefs.current = [];

  return (
    <div className={styles.slider_wrapper}>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[Navigation, Thumbs, Pagination]}
        className={styles.list_img}
        pagination={pagination}
        cssMode={true}
        onSlideChange={handleSlideChange}
        noSwipingClass="no-swiping"
        onClick={() => {
          setTypeGuide({
            ...typeGuide,
            show: false,
          });
          setGetItem({
            ...getItem,
            show: false,
          });
        }}
      >
        {imageData.map((image, index) => (
          <SwiperSlide
            key={`slider_${index}`}
            className={`${styles.custom_slider_a} ${styles.slider_container}`}
          >
            {image.type === "image" ? (
              <img src={imgUrl + image.content} alt={image.title} />
            ) : image.type === "video" ? (
              <embed
                className="embedVideos"
                src={image.content}
                style={{ width: "100%", height: "100%" }}
                ref={videoRef}
              ></embed>
            ) : image.type === "roadView" ? (
              <div
                ref={(el) => panoramaRefs.current.push(el)}
                className={`${styles.slider_media} no-swiping`}
                data-item={image.content}
              ></div>
            ) : image.type === "map" ? (
              <div
                ref={(el) => (mapElementRef.current = el)}
                className="d-flex justify-content-center align-items-center w-100 h-100 no-swiping"
                id="naverMapArea"
              ></div>
            ) : image.type === "weblink" ? (
              <a
                className="d-block hover_wrapper d-flex justify-content-center align-items-center w-100 h-100"
                href={image.content}
                target="_blank"
                rel="noreferrer"
              >
                {/* 타이틀 표시. 마우스 오버 시 밑줄. 클릭 시 새탭에 해당 링크 연결. */}
                <span className="mouse_hover">{image.content}</span>
              </a>
            ) : image.type === "file" ? (
              <div
                className="hover_wrapper d-flex justify-content-center align-items-center w-100 h-100"
                onClick={() => {
                  fileDown(image.content);
                }}
              >
                {/* 타이틀 표시. 마우스 오버 시 밑줄. 클릭 시 새탭으로 링크
                연결(pdf는 새창으로 열리고 기타 파일은 다운로드 되도록...) */}
                <span className="mouse_hover">{image.content}</span>
              </div>
            ) : (
              <div></div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={isLaptop ? 2 : 6}
        slidesPerView={5.5}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className={`swiper_thumbnail_wrapper ${styles.swiper_thumbnail_wrapper}`}
      >
        {imageData.map((image, index) => (
          <SwiperSlide
            className={styles.swiper_thumbnail}
            key={`slider_thumbnail_${index}`}
          >
            <div className={"thumbnail_img_wrapper"}>
              <div className="thumbnail_img_hover">
                {image.type === "image" ? (
                  <img src={imgUrl + image.content} alt="" />
                ) : image.type === "video" ? (
                  <img
                    src={`https://img.youtube.com/vi/${getYouTubeVideoId(
                      image.content
                    )}/0.jpg`}
                    className={`${styles.slider_thumbnail} pe-none`}
                    alt=""
                  />
                ) : image.type === "roadView" ? (
                  <>
                    <div
                      ref={(el) => panoramaRefs.current.push(el)}
                      className={`${styles.slider_thumbnail} pe-none`}
                      data-item={image.content}
                      style={{ width: "100%", height: "auto" }}
                    ></div>
                    <div className="position-absolute"></div>
                    {index === selectedItemIndex && (
                      <div className={`${styles.slider_shadow}`}></div>
                    )}
                  </>
                ) : (
                  <div
                    className={`${styles.slider_thumbnail} pe-none`}
                    data-item={image.content}
                    style={{
                      width: "100%",
                      height: "auto",
                      backgroundColor: "rgb(255,255,255)",
                    }}
                  >
                    {image.type === "map" ? (
                      <div
                        className="d-flex justify-content-center align-items-center w-100 h-100"
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL}/image/map_background.png)`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      >
                        <BsMapFill className="fs-20px" />
                      </div>
                    ) : image.type === "weblink" ? (
                      <div className="d-flex justify-content-center align-items-center w-100 h-100">
                        <BsLink45Deg className="fs-20px" />
                        <br />
                      </div>
                    ) : image.type === "file" ? (
                      <div className="d-flex justify-content-center align-items-center w-100 h-100">
                        <BsFiles className="fs-20px" />
                        <br />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className={`text-center ${styles.fs_11px} pt-1 fw-700`}>
              {image.title}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

function Selectbox(props) {
  const {
    options,
    setSelectOptions,
    setSelectSet,
    selectSet,
    name,
    setStartDate,
    selectEa,
    setSelectEa,
  } = props;

  const [selectbox, setSelectbox] = useState(false);
  const showOptions = () => {
    setSelectbox(true);
  };

  const selectChange = (e) => {
    if (name === "option") {
      const _period = e.period.split(",").map((item, index) => {
        return { value: index + 1, label: item };
      });
      const _quantity = e.amount.split(",").map((item, index) => {
        return { value: index + 1, label: item };
      });
      setSelectSet({
        ...selectSet,
        option_data: {
          ...selectSet.option_data,
          install_price: selectSet.option_data.install_price * e.value,
        },
        option: e.label,
        period: _period[0].label,
        quantity: _quantity[0].label,
        price: e.price,
        limitDate: e.limit_start_date,
      });

      setSelectOptions({
        ...options,
        period: _period,
        quantity: _quantity,
      });

      setStartDate(null);
    } else {
      if (!isNaN(selectSet.price)) {
        setSelectSet({
          ...selectSet,
          [name]: e.label,
          price: Number(selectSet.option_data.price),
        });
        setSelectEa({
          ...selectEa,
          [name]: e.value,
        });
      } else {
        setSelectSet({
          ...selectSet,
          [name]: e.label,
        });
        setSelectEa({
          ...selectEa,
          [name]: e.value,
        });
      }
    }
  };

  return (
    <>
      <div
        className={styles.option_select}
        onClick={showOptions}
        onBlur={() => {
          setSelectbox(false);
        }}
        tabIndex={0}
      >
        <div className="d-flex justify-content-center w-100">
          {selectSet[name]}
        </div>
        <div className={`custom-select-arrow me-0 ${styles.custom_arrow}`}>
          <img
            src={process.env.PUBLIC_URL + "image/icon/detail_select.svg"}
            alt=""
          />
        </div>
      </div>
      <div className="position-relative w-100">
        {selectbox && (
          <div className={styles.customSelect}>
            {options[name].map((item, index) => (
              <div
                className="position-relative"
                key={`option_${name}_${index}`}
              >
                <div
                  className={styles.customOption}
                  onMouseDown={() => {
                    selectChange(item);
                  }}
                >
                  {item.label}
                </div>
                {index === 0 && (
                  <div className={`${styles.selectClose}`}>
                    <img
                      src={`${process.env.PUBLIC_URL}image/icon/close_button.svg`}
                      style={{ width: "10px", cursor: "pointer" }}
                      alt=""
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

function DateHolder({ ...props }) {
  const { selectSet, startDate, setStartDate } = props;

  const filterDate = (date) => {
    // 조건에 따라 필터링
    const condition = Number(selectSet.limitDate); // 원하는 조건 번호를 선택

    switch (condition) {
      case 1: // 매 월 1일
        return getDate(date) === 1;
      case 2: // 매 월 1일, 16일
        return getDate(date) === 1 || getDate(date) === 16;
      case 3: // 매 년 1월 1일
        return getMonth(date) === 0 && getDate(date) === 1;
      case 4: // 매 주 월요일
        return getDay(date) === 1;
      case 5: // 매 주 화요일
        return getDay(date) === 2;
      case 6: // 매 주 수요일
        return getDay(date) === 3;
      case 7: // 매 주 목요일
        return getDay(date) === 4;
      case 8: // 매 주 금요일
        return getDay(date) === 5;
      case 9: // 매 주 토요일
        return getDay(date) === 6;
      case 10: // 매 주 일요일
        return getDay(date) === 0;
      case 11: // 매 월 1일, 10일, 20일
        return [1, 10, 20].includes(getDate(date));
      default: // 제한 없음
        return "false";
    }
  };
  const conditionText = (() => {
    switch (Number(selectSet.limitDate)) {
      case 1:
        return "매 월 1일";
      case 2:
        return "매 월 1일, 16일";
      case 3:
        return "매 년 1월 1일";
      case 4:
        return "매 주 월요일";
      case 5:
        return "매 주 화요일";
      case 6:
        return "매 주 수요일";
      case 7:
        return "매 주 목요일";
      case 8:
        return "매 주 금요일";
      case 9:
        return "매 주 토요일";
      case 10:
        return "매 주 일요일";
      case 11:
        return "매 월 1일, 10일, 20일";
      default:
        return "";
    }
  })();
  const datePickerRef = useRef();
  useEffect(() => {
    datePickerRef.current.input.readOnly = true;
  }, []);

  const CustomInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <button
        className={`datePicker fw-700 ${styles.custom_datepicker}`}
        onClick={onClick}
        ref={ref}
      >
        <div className="lh-1 text-dark">{value}</div>
      </button>
    );
  });

  return (
    <>
      <div className={`d-flex flex-grow-1 ${styles.fs_11px} position-relative`}>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          dateFormat="yyy-MM-dd"
          locale={ko}
          placeholderText="선택"
          filterDate={filterDate}
          minDate={new Date()}
          disabled={!selectSet.option}
          closeOnScroll={true}
          ref={datePickerRef}
          dateFormatCalendar={"yyyy년 MMMM"}
          customInput={<CustomInput />}
          // popperPlacement="bottom"
        >
          {selectSet.limitDate !== "제한 없음" &&
            selectSet.limitDate !== "0" && (
              <div className="main fs-7px text-end">
                *{conditionText}만 가능
              </div>
            )}
        </DatePicker>
        <div
          className={`${styles.datepicker_arrow} position-absolute ${styles.custom_arrow}`}
        >
          <img src={`${process.env.PUBLIC_URL}image/icon/detail_select.svg`} />
        </div>
      </div>
    </>
  );
}

function CustomSelect(props) {
  const {
    setSelectSet,
    selectSet,
    name,
    setStartDate,
    selectEa,
    setSelectEa,
    options,
    setSelectOptions,
  } = props;

  const [selectbox, setSelectbox] = useState(false);
  const showOptions = () => {
    setSelectbox(true);
  };

  const selectPlace = useRef();

  const selectChange = (e) => {
    selectPlace.current.innerText = e.label;
    const _period = e.period.split(",").map((item, index) => {
      return { value: index + 1, label: item };
    });
    const _quantity = e.amount.split(",").map((item, index) => {
      return { value: index + 1, label: item };
    });
    setSelectSet({
      ...selectSet,
      option_data: e,
      option: e.label,
      period: _period[0].label,
      quantity: _quantity[0].label,
      price: e.price,
      limitDate: e.limit_start_date,
    });
    setSelectOptions({
      ...options,
      period: _period,
      quantity: _quantity,
    });
    const dateDefault = (_date) => {
      const today = new Date();
      switch (Number(_date)) {
        case 1:
          let date1 = new Date(today.getFullYear(), today.getMonth(), 1);
          if (date1 < today) {
            const nextYear =
              today.getMonth() === 11
                ? today.getFullYear() + 1
                : today.getFullYear();
            const nextMonth =
              today.getMonth() === 11 ? 0 : today.getMonth() + 1;
            date1 = new Date(nextYear, nextMonth, 1);
          }
          return date1;
        case 2:
          if (today.getDate() > 1 && today.getDate() <= 16) {
            const nextMonth =
              today.getMonth() === 11 ? 0 : today.getMonth() + 1;
            const nextYear =
              today.getMonth() === 11
                ? today.getFullYear() + 1
                : today.getFullYear();
            if (today > new Date(today.getFullYear(), today.getMonth(), 1)) {
              return new Date(today.getFullYear(), today.getMonth(), 16);
            } else {
              return new Date(nextYear, nextMonth, 1);
            }
          } else {
            const nextMonth =
              today.getMonth() === 11 ? 0 : today.getMonth() + 1;
            const nextYear =
              today.getMonth() === 11
                ? today.getFullYear() + 1
                : today.getFullYear();
            if (today > new Date(today.getFullYear(), today.getMonth(), 16)) {
              return new Date(nextYear, nextMonth, 1);
            } else {
              return new Date(today.getFullYear(), today.getMonth(), 16);
            }
          }
        case 3:
          return new Date(today.getFullYear() + 1, 0, 1);
        case 4:
          return getNextDayOfWeek(today, 1);
        case 5:
          return getNextDayOfWeek(today, 2);
        case 6:
          return getNextDayOfWeek(today, 3);
        case 7:
          return getNextDayOfWeek(today, 4);
        case 8:
          return getNextDayOfWeek(today, 5);
        case 9:
          return getNextDayOfWeek(today, 6);
        case 10:
          return getNextDayOfWeek(today, 7);
        case 11:
          if (today.getDate() < 10) {
            return getNextSelectableDate(today, 10);
          } else if (today.getDate() < 20) {
            return getNextSelectableDate(today, 20);
          } else {
            return getNextSelectableDate(today, 1);
          }
        // ... the rest of your cases ...
        default:
          return today;
      }
    };
    const getNextDayOfWeek = (date, day) => {
      const resultDate = new Date(date);
      resultDate.setDate(date.getDate() + ((7 + day - date.getDay()) % 7));
      return resultDate;
    };
    const getNextSelectableDate = (date, day) => {
      const nextMonth = date.getMonth() === 11 ? 0 : date.getMonth() + 1;
      const nextYear =
        date.getMonth() === 11 ? date.getFullYear() + 1 : date.getFullYear();

      if (date.getDate() < day) {
        if (date > new Date(date.getFullYear(), date.getMonth(), day - 1)) {
          return new Date(nextYear, nextMonth, day);
        } else {
          return new Date(date.getFullYear(), date.getMonth(), day);
        }
      } else {
        if (date > new Date(date.getFullYear(), date.getMonth(), day)) {
          return new Date(nextYear, nextMonth, day);
        } else {
          return new Date(date.getFullYear(), date.getMonth(), day);
        }
      }
    };
    setStartDate(dateDefault(e.limit_start_date));
    setSelectEa({
      period: 1,
      quantity: 1,
    });
  };
  return (
    <>
      <div
        className={styles.option_select}
        onClick={showOptions}
        onBlur={() => {
          setSelectbox(false);
        }}
        tabIndex={0}
      >
        <div className="d-flex justify-content-center w-100" ref={selectPlace}>
          {selectSet.option}
        </div>
        <div className={`custom-select-arrow me-0 ${styles.custom_arrow}`}>
          <img src={process.env.PUBLIC_URL + "image/icon/detail_select.svg"} />
        </div>
      </div>
      <div className="position-relative w-100">
        {selectbox && (
          <div className={styles.customSelect}>
            {options[name].map((item, index) => (
              <div
                className="position-relative"
                key={`option_${name}_${index}`}
              >
                <div
                  className={styles.customOption}
                  onMouseDown={() => {
                    selectChange(item);
                  }}
                >
                  {item.label}
                </div>
                {index === 0 && (
                  <div className={styles.selectClose}>
                    <img
                      src={`${process.env.PUBLIC_URL}image/icon/close_button.svg`}
                      className="pointer"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

function CartModal({ children, ...props }) {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={true}
    >
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}

export default PagesDetail;
