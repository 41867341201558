import React, { useEffect, useState } from "react";
import Nav from "../Nav";
import Aside from "../asideNav";
import { useLocation, useNavigate } from "react-router-dom";
import Billboard from "./billboard/Billboard";
import EachPages from "./eachPages";
import { useDispatch } from "react-redux";
import PagesDetail from "./pagesDetail";
import MediaCart from "./mediaCart/mediaCart";
import Search from "./search/search";
import Wish from "./search/wish";
import Mypage from "./mypage/mypage";
import Media from "./media";
import Home from "./Home";
import axios from "axios";
import { GetAccessToken } from "../api/GetToken";

import { DELETE_TOKEN } from "../store/Auth";
import { removeCookieToken } from "../storage/Cookie";
import { APIURL } from "../api/apiDefault";
import SiteGuide from "./siteGuide";
// import win from 'global';

function CommonPages() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = GetAccessToken();

  const [resetMap, setResetMap] = useState(null);

  const pagesInfo = [
    {
      id: "traffic",
      seq: 2,
      src: "image/icon/traffic.svg",
    },
    {
      id: "shopping",
      seq: 3,
      src: "image/icon/shopping.svg",
    },
    {
      id: "life",
      seq: 4,
      src: "image/icon/life.svg",
    },
    {
      id: "sport",
      seq: 5,
      src: "image/icon/sport.svg",
    },
    {
      id: "etc",
      seq: "6",
      src: "image/icon/etc.svg",
    },
  ];

  const matchedPage = pagesInfo.find(
    (page) => `/${page.id}` === location.pathname
  );

  const detailPage = pagesInfo.find(
    (page) => `/${page.id}Detail` === location.pathname
  );

  const [userInfo, setUserInfo] = useState(null);

  const [number, setNumber] = useState({
    cart: 0,
    mypage: 0,
  });
  useEffect(() => {
    accessToken &&
      axios
        .post(`${APIURL}user/me`, "", {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.msg === "Forbidden") {
              dispatch(DELETE_TOKEN());
              removeCookieToken();
            } else {
              setUserInfo(res.data);
            }
          }
        })
        .catch((error) => {
          navigate("/login");
        });

    accessToken && cartNumber();
  }, [accessToken, navigate]);

  const cartNumber = () => {
    axios
      .get(`${APIURL}plan/my/count`, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((res) => {
        console.log(res)
        if (res.status === 200) {
          setNumber({
            cart: res.data.plan_count,
            mypage: res.data.reserve_count,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // axios
    //   .get(`${APIURL}plan`, {
    //     headers: {
    //       Authorization: accessToken,
    //     },
    //   })
    //   .then((res) => {
    //     if (res.status === 200) {
    //       const newNumber = {
    //         cart: 0,
    //         mypage: 0,
    //       };
    //       newNumber.cart = res.data.rialto_count + res.data.req_count + res.data.self_count;

    //       axios
    //         .get(`${APIURL}reservation/`, {
    //           headers: {
    //             Authorization: accessToken,
    //           },
    //         })
    //         .then((res2) => {
    //           if (res2.status === 200) {
    //             const rialtoMypage = res2.data.filter(
    //               (_rialto) => _rialto.parent_user_id !== 0 && _rialto.is_read !== 1,
    //             );
    //             newNumber.mypage = rialtoMypage.length;
    //             setNumber({ cart: newNumber.cart, mypage: newNumber.mypage });
    //           }
    //         })
    //         .catch((err) => console.log(err));
    //     }
    //   })
    //   .catch((err) => console.log(err));
  };
  // function clearLogin(event) {
  //   console.log(event)
  //   const _auto = window.localStorage.getItem("auto");
  //   if (_auto === "false") {
  //     window.localStorage.setItem("test", 're');
  //     dispatch(DELETE_TOKEN());
  //     removeCookieToken();
  //   }
  // }

  // window.addEventListener("beforeunload", clearLogin);
  // useEffect(() => {

  //   window.addEventListener('beforeunload', clearLogin);
  //   return () => {
  //     window.removeEventListener('beforeunload', clearLogin);
  //   }
  // }, []);

  // const clearLogin = (e) => {
  //   e.preventDefault();
  //   const _auto = window.localStorage.getItem("auto");
  //   if (_auto === "false") {
  //     window.localStorage.setItem("test", 're');
  //     dispatch(DELETE_TOKEN());
  //     removeCookieToken();
  //   }
  // }

  useEffect(() => {
    setScreenSize();
  }, []);
  function setScreenSize() {
    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }
  window.addEventListener("resize", () => setScreenSize());
  return (
    <div className="rialto-container">
      <Nav
        homeStyle={location.pathname === "/" ? "bg-transparent" : ""}
        number={number}
      />
      <div className="rialto-content-box d-flex">
        <Aside
          homeStyle={location.pathname === "/" ? "bg-transparent" : ""}
          resetMap={resetMap}
          setResetMap={setResetMap}
        />
        {location.pathname === "/" && <Home />}
        {location.pathname === "/billboard" && (
          <Billboard
            userInfo={userInfo}
            resetMap={resetMap}
            setResetMap={setResetMap}
          />
        )}
        {location.pathname === "/billboardDetail" && (
          <PagesDetail
            userInfo={userInfo}
            number={number}
            setNumber={setNumber}
          />
        )}
        {matchedPage && (
          <EachPages
            pagesInfo={pagesInfo}
            userInfo={userInfo}
            resetMap={resetMap}
          />
        )}
        {detailPage && (
          <PagesDetail
            userInfo={userInfo}
            number={number}
            setNumber={setNumber}
          />
        )}
        {location.pathname === "/mediaCart" && (
          <MediaCart
            userInfo={userInfo}
            number={number}
            setNumber={setNumber}
          />
        )}
        {location.pathname === "/search" && <Search userInfo={userInfo} />}
        {location.pathname === "/mypage" && (
          <Mypage number={number} setNumber={setNumber} />
        )}
        {location.pathname === "/wish" && <Wish />}
        {location.pathname === "/media" && <Media />}
        {/* {location.pathname === '/siteGuide' && <SiteGuide />} */}
      </div>
    </div>
  );
}

export default CommonPages;
