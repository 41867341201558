import { useSelector, useDispatch } from 'react-redux';
import { getCookieToken } from '../storage/Cookie';
import { SET_TOKEN } from '../store/Auth';

export const GetAccessToken = () => {
  const dispatch = useDispatch();

  let accessToken = useSelector((state) => state.accessToken);
  if (accessToken === undefined) {
    accessToken = getCookieToken();
    dispatch(SET_TOKEN(accessToken));
  }

  return accessToken;
};
