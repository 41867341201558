import { Cookies } from 'react-cookie';


const cookies = new Cookies();

// export const setRefreshToken = (refreshToken, autoLogin) => {
//   const today = new Date();
//   // const expireDate = today.setDate(today.getDate() + 7);
//   let expireDate = null;
//   if (autoLogin) {
//     // 자동 로그인 체크한 경우, 7일 후 만료
//     expireDate = today.setDate(today.getDate() + 7);
//   } else {
//     // 자동 로그인 체크하지 않은 경우, 즉시 만료
//     expireDate = today;
//   }

//   return cookies.set('refresh_token', refreshToken, {
//     sameSite: 'strict',
//     path: "/",
//     expires: new Date(expireDate)
//   });
// };


export const setRefreshToken = (refreshToken, autoLogin) => {
  const today = new Date();
  // const expireDate = today.setDate(today.getDate() + 7);
  let expireDate = new Date(today)
  if (autoLogin) {
    // 자동 로그인 체크한 경우, 7일 후 만료
    expireDate.setDate(today.getDate() + 7);
  } else {
    // expireDate.setDate(today.getDate() + 1);
    expireDate.setHours(23, 59, 59, 999);
  }

  return cookies.set('refresh_token', refreshToken, {
    sameSite: 'strict',
    path: "/",
    expires: expireDate
  });
};

export const getCookieToken = () => {
  return cookies.get('refresh_token');
};

export const removeCookieToken = () => {
  return cookies.remove('refresh_token', { sameSite: 'strict', path: "/" })
}
