import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { APIURL } from '../api/apiDefault';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useMediaQuery } from 'react-responsive';

function SiteGuide() {
  const isTablet = useMediaQuery({ query: '(max-width: 1280px)' });
  const [guide, setGuide] = useState({
    media: '',
    plan: '',
    faq: '',
  });
  useEffect(() => {
    axios
      .get(`${APIURL}guide`)
      .then((res) => {
        const _data = res.data;
        console.log(_data);
        setGuide(_data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // useEffect(() => {
  //   setScreenSize();
  // }, []);
  // function setScreenSize() {
  //   let vh = window.innerHeight * 0.01;

  //   document.documentElement.style.setProperty('--vh', `${vh}px`);
  // }
  // window.addEventListener('resize', () => setScreenSize());

  return (
    <div className="bg-white">
      <div className={`px-2`}>
        <Tabs defaultActiveKey="media" className="mb-3 guide_tap mt-2" fill>
          <Tab eventKey="media" title="이용가이드" className="bg-transparent text-dark">
            <div
              style={{
                overflowY: 'auto',
                maxHeight: `calc((var(--vh, 1vh) * 100) - 80px)`
              }}
              className={`scroll fs-11px`}
              dangerouslySetInnerHTML={{ __html: guide.media }}></div>
          </Tab>
          {/* <Tab
            eventKey="plan"
            title="플랜을 관리하는 방법"
            className="bg-transparent text-dark"
          >
            <div
              style={{
                height: `${isTablet ? "60vh" : "75vh"}`,
                overflowY: "auto",
              }}
              className={`scroll fs-11px`}
              dangerouslySetInnerHTML={{ __html: guide.plan }}
            ></div>
          </Tab> */}
          <Tab eventKey="faq" title="FAQ" className="bg-transparent text-dark">
            <div
              style={{
                maxHeight: `calc((var(--vh, 1vh) * 100) - 80px)`,
                overflowY: 'auto',
              }}
              className={`scroll fs-11px`}
              dangerouslySetInnerHTML={{ __html: guide.faq }}></div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default SiteGuide;
