import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/login/login";
import SignUp from "./pages/login/signUp";
import Pages from "./pages/pages";
import SiteGuide from './pages/siteGuide';
import { useEffect } from "react";

function App() {
  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }


  if (typeof window !== 'undefined' && window !== 'null') {
    window.addEventListener("resize", setScreenSize);
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<SignUp />}></Route>
          <Route path="/" element={<Pages />}></Route>
          <Route path="/billboard" element={<Pages />}></Route>
          <Route path="/traffic" element={<Pages />}></Route>
          <Route path="/shopping" element={<Pages />}></Route>
          <Route path="/life" element={<Pages />}></Route>
          <Route path="/sport" element={<Pages />}></Route>
          <Route path="/etc" element={<Pages />}></Route>
          <Route path="/trafficDetail" element={<Pages />}></Route>
          <Route path="/shoppingDetail" element={<Pages />}></Route>
          <Route path="/lifeDetail" element={<Pages />}></Route>
          <Route path="/sportDetail" element={<Pages />}></Route>
          <Route path="/etcDetail" element={<Pages />}></Route>
          <Route path="/billboardDetail" element={<Pages />}></Route>
          <Route path="/search" element={<Pages />}></Route>
          <Route path="/mypage" element={<Pages />}></Route>
          <Route path="/mediaCart" element={<Pages />}></Route>
          <Route path="/wish" element={<Pages />}></Route>
          <Route path="/media" element={<Pages />}></Route>
          <Route path="/siteGuide" element={<SiteGuide />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
