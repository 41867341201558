import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import '../css/home.css';
import { ISlideConfig, PageSlides, SlideParallaxType } from 'react-page-slides';
import { BsFillArrowUpCircleFill, BsChevronDoubleDown } from 'react-icons/bs';
import Modal from 'react-bootstrap/Modal';
import { getCookieToken } from '../storage/Cookie';
import axios from 'axios';
import { APIURL, DEFAULTURL } from '../api/apiDefault';

const Home = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 769px)',
  });
  const isTablet = useMediaQuery({
    query: '(max-width: 1280px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });

  const [modal, setModal] = useState(false);
  const modalReset = () => {
    setModal(false);
  };

  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');
  const modalOpen = (_title) => {
    setModalTitle(_title);

    let _api;
    switch (_title) {
      case '회사소개서':
        _api = `${APIURL}intro`;
        break;
      case '이용약관':
        _api = `${APIURL}terms`;
        break;
      default:
        _api = `${APIURL}privacy`;
        break;
    }

    axios
      .get(_api)
      .then((res) => {
        if (res.status === 200) {
          switch (_title) {
            case '회사소개서':
              setModal(false);
              console.log(res.data.content);
              window.open(`${DEFAULTURL}image/site/${res.data.content}`, '_blank');
              // setModalContent(res.data.content);
              break;
            case '이용약관':
              setModalContent(res.data[0].terms);
              setModal(true);
              break;
            default:
              setModal(true);
              setModalContent(res.data[0].privacy);
              break;
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const config = {
    headers: {
      Authorization: getCookieToken(),
    },
  };
  const [footerInfo, setFooterInfo] = useState();
  const getFooterInfo = () => {
    axios
      .get(`${APIURL}footer`, config)
      .then((res) => {
        setFooterInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    document.addEventListener('scroll', (e) => {
      console.log(1);
    });
    getFooterInfo();
  }, []);

  const home_data = {
    home1: {
      text: (
        <>
          <div className={`text-center`}>
            <div className="qfs-20px">브랜드 마케터를 위한</div>
            <div className="qfs-32px fw-800 py-2">OOH Media Platform</div>
            <div className="qfs-15px fw-lighter">브랜드 마케팅에 적합한 매체들만 선별하여</div>
            <div className="qfs-15px fw-lighter">일목요연하게 정리한 미디어플랫폼입니다.</div>
            <div className="qfs-15px fw-lighter pt-4">{isTablet ? '하단' : '왼쪽'} 메뉴를 눌러 시작하세요.</div>
          </div>
          {!isMobile && <img src={`${process.env.PUBLIC_URL}image/icon/double_arrow.svg`} className="home-arrow" />}
        </>
      ),
    },
    home2: {
      video: (
        <video width="100%" height="100%" controls={false} preload="auto" autoPlay muted loop playsInline>
          <source src={`${process.env.PUBLIC_URL}video/home_2.mp4`} type="video/mp4" />
        </video>
      ),
      text: (
        <>
          <div className="opacity-30"></div>
          <div className="home-text mb-200px w-90">
            <div className="qfs-32px fw-800 py-2">OOH realistic cam</div>
            <div className="qfs-15px fw-lighter">광고 노출 대상의 시점에서 사실적으로 촬영된</div>
            <div className="qfs-15px fw-lighter">미디어 안내 유튜브 채널입니다.</div>
            <div className="qfs-15px fw-lighter">광고 효과 예측과 미디어 선별에 활용할 수 있습니다.</div>
            <div className="pt-4">
              <a
                target="_blank"
                className="text-white link fs-11px fw-lighter"
                href={footerInfo?.link}
                rel="noreferrer">
                바로가기
              </a>
            </div>
          </div>
          {!isMobile && <img src={`${process.env.PUBLIC_URL}image/icon/double_arrow.svg`} className="home-arrow" />}
        </>
      ),
    },
    home3: {
      video: (
        <video width="100%" height="100%" controls={false} preload="auto" autoPlay muted loop playsInline>
          <source src={`${process.env.PUBLIC_URL}video/home_3.mp4`} type="video/mp4" />
        </video>
      ),
      text: (
        <>
          <div className="opacity-30"></div>
          <div className="home-text mb-200px w-90">
            <div className="qfs-32px fw-800 pb-3">OOH media report</div>
            <div className="qfs-15px fw-lighter">옥외광고가 활발히 소통하는 시간대에 (야간/주말 포함) 촬영된</div>
            <div className="qfs-15px fw-lighter">고화질의 사진 및 영상을 제공합니다.</div>
          </div>
          {!isMobile && <img src={`${process.env.PUBLIC_URL}image/icon/double_arrow.svg`} className="home-arrow" />}
        </>
      ),
    },
    home4: {
      video: (
        <video width="100%" height="100%" controls={false} preload="auto" autoPlay muted loop playsInline>
          <source src={`${process.env.PUBLIC_URL}video/home_4.mp4`} type="video/mp4" />
        </video>
      ),
      text: (
        <>
          <div className="opacity-30"></div>
          <div className="home-text mb-200px w-90">
            <div className="pb-3">
              <div className="qfs-32px fw-800">앰비언트 광고,</div>
              <div className="qfs-32px fw-800">오프라인 액티베이션</div>
            </div>
            <div className="qfs-15px fw-lighter">창의적인 오프라인 캠페인을 기획하거나</div>
            <div className="qfs-15px fw-lighter">훌륭한 아이디어를 현실화 시킬 수 있는 실행력.</div>
          </div>
          {!isMobile && <img src={`${process.env.PUBLIC_URL}image/icon/double_arrow.svg`} className="home-arrow" />}
        </>
      ),
    },
    home5: {
      text: (
        <div className="text-center">
          <div className="qfs-32px fw-800 pb-1 white-nowrap">옥외광고를 잘 아는 파트너</div>
          <div className="qfs-15px fw-lighter pb-4">20년 경력의 옥외광고 전문가 그룹입니다.</div>
          <div className="qfs-15px fw-lighter pb-1">크리에이티브 기획자로서</div>
          <div className="qfs-15px fw-lighter pb-1">다양한 오프라인 캠페인 기획/실행,</div>
          <div className="qfs-15px fw-lighter pb-4">다수의 광고제 옥외광고부문 수상 경험</div>
          <div className="qfs-15px fw-lighter pb-1">미디어 플래너로서</div>
          <div className="qfs-15px fw-lighter pb-4">연간 최대 100억 규모의 미디어 플래닝/바잉 경험</div>
          <div className="qfs-15px fw-lighter pb-1">미디어 개발자로서</div>
          <div className="qfs-15px fw-lighter pb-4">공공시설, 건물, 상업시설을 활용한 매체 개발/운영 경험</div>
          <div className="qfs-15px fw-lighter">
            <a
              className="text-white link pointer"
              // href="/image/site"
              // target="_blank"
              onClick={() => {
                modalOpen('회사소개서');
              }}>
              회사소개서
            </a>
          </div>
          {!isMobile && <img src={`${process.env.PUBLIC_URL}image/icon/double_arrow.svg`} className="home-arrow" />}
        </div>
      ),
    },
    footer: {
      text: (
        <div className="footer w-100 fs-10px">
          <div className={`footer-content mx-auto ${isTablet ? 'py-1' : 'pt-3 pb-5'}`}>
            <div className="d-flex align-items-end gap-5 fs-12px">
              <img
                src={`${process.env.PUBLIC_URL}/image/icon/logo_white.svg`}
                style={{ width: '105px', height: '30px' }}
                alt=""
              />
              <div className="fw-800 text-white lh-1">{footerInfo?.company_phone}</div>
              <div className="fw-800 text-white lh-1">{footerInfo?.email}</div>
            </div>
            <div className={`${isTablet ? 'pt-2' : 'pt-4'} d-flex`}>
              <div className="bd-right-white pe-2 position-relative pointer" onClick={() => modalOpen('이용약관')}>
                이용약관
              </div>
              <div className="pointer ps-2" onClick={() => modalOpen('개인정보 처리방침')}>
                개인정보 처리방침
              </div>
            </div>
            <div className="d-flex flex-wrap">
              <div className="bd-right-white pe-2 position-relative">{footerInfo?.company_name}</div>
              <div className="bd-right-white pe-2 ps-2 position-relative">{footerInfo?.ceo_name}</div>
              <div className="bd-right-white pe-2 ps-2 position-relative">{footerInfo?.register_number}</div>
              <div className="bd-right-white pe-2 ps-2 position-relative">{footerInfo?.address}</div>
              <div className="ps-2">Copyright ⓒ Rialto All Rights Reserved.</div>
            </div>
            <div className="d-flex"></div>
          </div>
        </div>
      ),
    },
  };

  return (
    <>
      {isDesktopOrLaptop && <PC modal={modal} homeData={home_data} />}
      {isMobile && <Mobile homeData={home_data} modalOpen={modalOpen} footerInfo={footerInfo} />}
      <Modal show={modal} onHide={modalReset} centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton className="border-bottom-0 pb-0">
          <div className="fs-18px fw-800 main">{modalTitle}</div>
        </Modal.Header>
        <Modal.Body>
          <div
            className="scroll"
            style={{ maxHeight: '60vh', overflowY: 'auto' }}
            dangerouslySetInnerHTML={{ __html: modalContent }}></div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const PC = ({ ...props }) => {
  const { homeData } = props;
  return (
    <div className="outer">
      <div className="inner bg_home1">{homeData.home1.text}</div>
      <div className="inner">
        <div className="bg_video">{homeData.home2.video}</div>
        {homeData.home2.text}
      </div>
      <div className="inner">
        <div className="bg_video">{homeData.home3.video}</div>
        {homeData.home3.text}
      </div>
      <div className="inner">
        <div className="bg_video">{homeData.home4.video}</div>
        {homeData.home4.text}
      </div>
      <div className="inner bg_home2">{homeData.home5.text}</div>
      <div className="footer">
        <div>{homeData.footer.text}</div>
      </div>
    </div>
  );
};

const Mobile = ({ ...props }) => {
  const { homeData, modalOpen, footerInfo } = props;
  return (
    <div className="home-wrapper">
      <div className="mobile-home-first position-relative mobile-home-first-height">
        <div className="home-text mobile-home-first-text">{homeData.home1.text}</div>
      </div>
      <div className="mobile-home-video">
        {homeData.home2.video}
        {homeData.home2.text}
      </div>
      <div className="mobile-home-video">
        {homeData.home3.video}
        {homeData.home3.text}
      </div>
      <div className="mobile-home-video ">
        {homeData.home4.video}
        {homeData.home4.text}
      </div>

      <div className="mobile-home-last" style={{ marginBottom: '92px' }}>
        <div className="home-text" style={{ width: '90%' }}>
          {homeData.home5.text}
        </div>
        <div className="footer position-absolute w-100 text-nowrap" style={{ bottom: '-92px' }}>
          <div className="footer-content mx-auto py-3">
            <div className={`gap-2 d-flex align-items-end fs-14px`}>
              <img
                src={`${process.env.PUBLIC_URL}/image/icon/logo_white.svg`}
                style={{ width: '66px', height: '19px' }}
                alt=""
              />
              <div className="fw-800 text-white lh-1">{footerInfo?.company_phone}</div>
              <div className="fw-800 text-white lh-1">{footerInfo?.email}</div>
            </div>
            <div className="fs-9px">
              <div className="pt-4 d-flex">
                <div className="bd-right-white pe-2 position-relative pointer" onClick={() => modalOpen('이용약관')}>
                  이용약관
                </div>
                <div className="pointer ps-2" onClick={() => modalOpen('개인정보 처리방침')}>
                  개인정보 처리방침
                </div>
              </div>
              <div className="d-flex">
                <div className="bd-right-white pe-2 position-relative">{footerInfo?.company_name}</div>
                <div className="bd-right-white pe-2 ps-2 position-relative">{footerInfo?.ceo_name}</div>
                <div className="ps-2 position-relative">{footerInfo?.register_number}</div>
              </div>
              <div className="d-flex flex-wrap">
                <div className="bd-right-white pe-2 position-relative">{footerInfo?.address}</div>
                <div className="ps-2">Copyright ⓒ Rialto All Rights Reserved.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
