// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-fs-17px {
  font-size: 17px;
}

.login-fs-15px {
  font-size: 15px;
}

.login-fs-12px {
  font-size: 12px;
}

.login-fs-11px {
  font-size: 11px;
}

.login-fs-10px {
  font-size: 10px;
  margin-top: 2px;
}

@media (max-width: 768px) {
  .login-fs-17px {
    font-size: 13px;
  }

  .login-fs-15px {
    font-size: 11px;
  }

  .login-fs-12px {
    font-size: 10px;
  }

  .login-fs-11px {
    font-size: 9px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/login/font.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,cAAc;EAChB;AACF","sourcesContent":[".login-fs-17px {\n  font-size: 17px;\n}\n\n.login-fs-15px {\n  font-size: 15px;\n}\n\n.login-fs-12px {\n  font-size: 12px;\n}\n\n.login-fs-11px {\n  font-size: 11px;\n}\n\n.login-fs-10px {\n  font-size: 10px;\n  margin-top: 2px;\n}\n\n@media (max-width: 768px) {\n  .login-fs-17px {\n    font-size: 13px;\n  }\n\n  .login-fs-15px {\n    font-size: 11px;\n  }\n\n  .login-fs-12px {\n    font-size: 10px;\n  }\n\n  .login-fs-11px {\n    font-size: 9px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
