// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .home-container {
  height: calc(100vh - 75.25px);
} */
.mobile-home-first-text {
  transform: translate(-50%, 58%);
  width: 90%;
}

@media (min-height:700px) {
  .mobile-home-first-text {
    transform: translate(-50%, 80%);
  }

}`, "",{"version":3,"sources":["webpack://./src/css/home.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;EACE,+BAA+B;EAC/B,UAAU;AACZ;;AAEA;EACE;IACE,+BAA+B;EACjC;;AAEF","sourcesContent":["/* .home-container {\n  height: calc(100vh - 75.25px);\n} */\n.mobile-home-first-text {\n  transform: translate(-50%, 58%);\n  width: 90%;\n}\n\n@media (min-height:700px) {\n  .mobile-home-first-text {\n    transform: translate(-50%, 80%);\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
